import React, { useState,useEffect } from "react";
import { Modal } from 'react-responsive-modal';
import Switch from "react-switch";
import ReactSelect from 'react-select'
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { toast } from 'react-toastify';

//Actions
import { openCloseModalAction, updateAppsAction } from '../../../../../actions/projects/clientsProjectAction'


export default () => {

    const dispatch = useDispatch();
    const { openedModal, row } = useSelector(state => state.clientsProject);

    const { register, control, handleSubmit, getValues } = useForm({
        defaultValues: row.settings
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "manual"
    });

    const notify = () => toast.success("Заявката е запазена!");


    const keyDown = (e) => {
        if(e.key == "Enter") {
            e.currentTarget.blur();
        }
    };

    const saveData = (e) => {
        onSubmit(getValues());
    }

    const onSubmit = async (data,e) => {
        if(row && row.id && data) {
            await dispatch(updateAppsAction({row,data}));

            await dispatch(updateAppsAction({
                row,
                "column":"settings",
                "value":data
            }));

            if(e) {
                closeModal(row);
            }
            else {
                notify();
            }
        }
    };

    const closeModal = (row) => {
        dispatch(openCloseModalAction({ "openClose":false, row }));
    }

    return (
        <>
        <Modal
            open={openedModal}
            onClose={() => closeModal(row)}
            center
            classNames={{
                modal: 'text-center inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl bg-gray-900 sm:1/3 lg:w-2/3',
            }}
            >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4 w-full">
                    <h3
                        className="text-xl leading-6 font-medium text-gray-900 w-full"
                        id="modal-headline">
                        Редакция на приложение &nbsp;
                        <small className="text-sm text-gray-600">
                            (ID: {row.id})
                        </small>
                    </h3>
                </div>

                <div className="bg-gray-100 px-4 py-3 sm:px-6 pt-1">
                    <button type="button" className="text-base flex flex-inline leading-6 font-medium text-gray-700 py-3 focus:outline-none">
                        Настройки
                    </button>

                    <div className="w-full">
                        <div className="flex items-center pb-2">
                            <div className="flex-1 mr-5">
                                <label className="framed w-full">
                                    <input placeholder=" " {...register(`title`)} name="title" onBlur={(e) => saveData(e)} onKeyDown={(e) => keyDown(e)} />
                                    <span>Заглавие</span>
                                </label>
                            </div>
                            <div className="flex-1">
                                <label className="framed w-full">
                                    <input placeholder=" " {...register(`subtitle`)} name="subtitle" onBlur={(e) => saveData(e)} onKeyDown={(e) => keyDown(e)} />
                                    <span>Подзаглавие</span>
                                </label>
                            </div>
                        </div>
                        <div className="flex items-center pb-2">
                            <div className="flex-1 mr-5">
                                <label className="framed w-full">
                                    <input placeholder=" " {...register(`addButton`)} name="addButton" onBlur={(e) => saveData(e)} onKeyDown={(e) => keyDown(e)} />
                                    <span>Заглавие на бутон при добавяне</span>
                                </label>
                            </div>
                            <div className="flex-1 mr-5">
                                <label className="framed w-full">
                                    <input placeholder=" " {...register(`addPath`)} name="addPath" onBlur={(e) => saveData(e)} onKeyDown={(e) => keyDown(e)} />
                                    <span>Пътечка при добавяне</span>
                                </label>
                            </div>
                            <div className="flex-1">
                                <label className="framed w-full">
                                    <input placeholder=" " {...register(`editPath`)} name="editPath" onBlur={(e) => saveData(e)} onKeyDown={(e) => keyDown(e)} />
                                    <span>Пътечка при редакция</span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>


                <div className="px-4 bg-gray-50 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                        Запази
                    </button>
                    <button
                        type="button"
                        onClick={() => closeModal(row)}
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                        Отказ
                    </button>
                </div>

            </form>

        </Modal>
        </>
);
};
