import React, { useState, useEffect, useRef } from "react";
import Switch from "react-switch";
import ReactSelect from 'react-select'
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';
import history from '../../../../../history';
import moment from 'moment';
import _max from 'lodash.max';
import _find from 'lodash.find';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import bg from 'date-fns/locale/bg';

//Actions
import { updateClientAction } from '../../../../../actions/projects/clientsListAction'

registerLocale('bg-bg', bg);


const Wallet = (row) => {

    const dispatch = useDispatch();
    const { clientInfo } = useSelector(state => state.clients);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(moment().format("MM-YYYY"));
    const [dateStart, setDateStart] = useState(moment().format("MM-YYYY"));
    const [dateEnd, setDateEnd] = useState(moment().format("MM-YYYY"));

    const [isActive, setIsActive] = useState(false);

    const handleActivate = nextChecked => {
        setIsActive(nextChecked);
    };

    const timeValues = [];

    if(isActive) {
        const dateStartCheck = moment(dateStart,"MM-YYYY");
        const dateEndCheck = moment(dateEnd,"MM-YYYY");

        while (dateEndCheck > dateStartCheck || dateStartCheck.format('M') === dateEndCheck.format('M')) {
           timeValues.push(dateStartCheck.format('MM-YYYY'));
           dateStartCheck.add(1,'month');
        }
    }

    const editorRefResourse = useRef();

    const { register, control, reset, getValues, handleSubmit } = useForm({
        defaultValues: clientInfo
    });

    const handleEditorChange = (value) => {
    };

    const saveData = (e) => {
        let allowSubmit = e._reactName == "onBlur" ? true : false;
        if(e.key == "Enter") {
            //onSubmit(getValues());
        }
        else if(allowSubmit) {
            onSubmit(getValues());
        }
    }

    const toastMe = () => {
        toast.success("Данните са запазени!")
    };

    const onSubmit = async (data,dontNotify) => {
        if(clientInfo && clientInfo.id && data) {
            const walletsLength = data.wallet && data.wallet.length > 0 ? true : false;

            data.wallet = Object.assign({}, data.wallet);
            data.wallet_description = editorRefResourse.current.currentContent;

            const newState = {
                ...clientInfo,
                wallet : data.wallet,
                wallet_description : data.wallet_description
            };

            dispatch(updateClientAction({newState:newState,data:data,row:clientInfo}));
        }
    };

    let accountedH = 0;
    let workedH = 0;
    let inProfit = 0;
    let monthlyEarning = 0;
    let expectedEarning = 0;
    let dealHours = 0;
    let endingH = 0;


    const projects = [];

    const monthly = [];

    if(isActive) {
        clientInfo.projects.map((item)=> {
            let monthSum = item;
            let hasFound = false;
            monthSum.accountedH = 0;
            monthSum.deal = 0;
            monthSum.deal_agreed = 0;
            monthSum.ending = 0;
            monthSum.inProfit = 0;
            monthSum.income = 0;
            monthSum.monthlyEarning = 0;
            monthSum.workedH = 0;

            timeValues.map((month)=> {
                if(item && item.monthly && _find(item.monthly,{"date":month})) {
                    hasFound = true;
                    let found = _find(item.monthly,{"date":month});
                    monthSum.accountedH += parseInt(found.accountedH);
                    monthSum.deal += parseInt(found.deal);
                    monthSum.deal_agreed += parseInt(found.deal_agreed);
                    monthSum.ending += parseInt(found.ending);
                    monthSum.inProfit += parseInt(found.inProfit);
                    monthSum.income = parseInt(found.income);
                    monthSum.monthlyEarning += parseInt(found.monthlyEarning);
                    monthSum.workedH += parseInt(found.workedH);
                }
            });

            if(hasFound) {
                monthly.push({...item,currentMonth:monthSum})
            }
        });
    }
    else {
        clientInfo.projects.map((item)=> {
            if(item && item.monthly && _find(item.monthly,{"date":date})) {
                monthly.push({...item,currentMonth:_find(item.monthly,{"date":date})})
            }
        });
    }

    monthly.map((item)=> {
        dealHours  = dealHours + parseInt(item.currentMonth.deal);
        accountedH  = accountedH + parseInt(item.currentMonth.accountedH);
        workedH  = workedH + parseInt(item.currentMonth.workedH);
        inProfit  = inProfit + item.currentMonth.inProfit;
        endingH  = endingH + item.currentMonth.ending;
        monthlyEarning  = monthlyEarning + item.currentMonth.monthlyEarning;
        expectedEarning  = expectedEarning + item.currentMonth.deal_agreed;
        item.currentMonth.title = item.title;
        item.currentMonth._id = item.proid;
        projects.push(item.currentMonth);
    });

    const changeDate = async (date,e) => {
        if(date) {
            setLoading(true);
            setDate(moment(date).format("MM-YYYY"));
            setLoading(false);
        }

    }

    const changeDateStart = async (dateStart) => {
        if(dateStart) {
            setLoading(true);
            setDateStart(moment(dateStart).format("MM-YYYY"));
            setLoading(false);
        }

    }

    const changeDateEnd = async (dateEnd) => {
        if(dateEnd) {
            setLoading(true);
            setDateEnd(moment(dateEnd).format("MM-YYYY"));
            setLoading(false);
        }
    }

    return (
        <>
            <div className="flex mt-5 flex-inline items-center bg-gray-50 px-4 pъ-5 sm:p-6 sm:pb-4">
                <div className="text-xl leading-6 font-medium text-gray-900">
                    Месечни Отчети&nbsp;
                    <small className="text-sm text-gray-600">
                        (клиент: {clientInfo.name})
                    </small>
                </div>
                <div className="flex flex-grow flex-row-reverse z-50">

                    {isActive ?
                    <div className="flex flex-row">
                        <div className="-mt-2 mr-5">
                            <div className="framed flex-grow">
                                <ReactDatePicker
                                    onChange={dateStart => changeDateStart(dateStart)}
                                    selected={new Date(moment(dateStart,"MM-YYYY").format())}
                                    placeholderText="От"
                                    popperPlacement="top-bottom"
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    className="normal-input w-full"
                                    locale="bg-bg"
                                    placeholder=" "
                                    />
                                <span>От</span>
                            </div>
                        </div>

                        <div className="-mt-2">
                            <div className="framed flex-grow">
                                <ReactDatePicker
                                    onChange={dateEnd => changeDateEnd(dateEnd)}
                                    selected={new Date(moment(dateEnd,"MM-YYYY").format())}
                                    placeholderText="До"
                                    popperPlacement="top-bottom"
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    className="normal-input w-full"
                                    locale="bg-bg"
                                    placeholder=" "
                                    />
                                <span>До</span>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="-mt-2">
                        <div className="framed flex-grow">
                            <ReactDatePicker
                                onChange={(date,e) => changeDate(date,e)}
                                selected={new Date(moment(date,"MM-YYYY").format())}
                                placeholderText="Месец"
                                popperPlacement="top-bottom"
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                className="normal-input w-full"
                                locale="bg-bg"
                                placeholder=" "
                                />
                            <span>Месец</span>
                        </div>
                    </div>
                    }

                    <div className="text-gray-600 flex items-center mr-5">
                        <div className="font-medium text-gray-700 text-base flex-2 mr-1">
                            От-до:
                        </div>
                        <div className="flex-1 mt-1">
                            <Switch onChange={handleActivate} checked={isActive} />
                        </div>
                    </div>

                </div>
            </div>


            <div className="bg-gray-100 py-3 pt-1 ">

                <div className="bg-gray-100 py-3 px-5 pt-1 ">
                    <div className="w-full mt-5 z-10 relative">

                        <ul className="w-full">
                            {projects.map((item, index) => {
                                return (
                                    <li key={`w${index}${item.date}${item._id}${isActive}${dateStart}${dateEnd}${date}`} className="flex items-center pb-2">
                                        <div className="flex mr-3 w-32">
                                            <label className="framed flex-grow">
                                                <input placeholder=" " disabled defaultValue={item.title} />
                                                <span>Проект</span>
                                            </label>
                                        </div>

                                        <div className="flex mr-3 w-36">
                                            <label className="framed flex-grow">
                                                <input placeholder=" "  defaultValue={`${item.accountedH} ч.`} />
                                                <span>Отчетени часове</span>
                                            </label>
                                        </div>

                                        <div className="flex mr-3 w-32">
                                            <label className="framed flex-grow">
                                                <input placeholder=" "  defaultValue={`${item.deal} ч.`} />
                                                <span>Очаквани час.</span>
                                            </label>
                                        </div>

                                        <div className="flex mr-3 w-36">
                                            <label className="framed flex-grow">
                                                <input placeholder=" "  defaultValue={`${item.workedH} ч.`} />
                                                <span>Изработени час.</span>
                                            </label>
                                        </div>

                                        <div className="flex mr-3 w-28">
                                            {item.ending < 1 ?
                                                <label className="framed flex-grow">
                                                    <input placeholder=" "  defaultValue={`${item.ending*-1} ч.`} />
                                                    <span>Отгоре с</span>
                                                </label> :
                                                <label className="framed flex-grow">
                                                    <input placeholder=" "  defaultValue={`${item.ending} ч.`} />
                                                    <span>Още</span>
                                                </label>
                                            }
                                        </div>

                                        <div className="flex mr-3 w-32">
                                            <label className="framed flex-grow">
                                                <input placeholder=" "  defaultValue={`${item.income} лв.`} />
                                                <span>Цена на час</span>
                                            </label>
                                        </div>

                                        <div className="flex mr-3">
                                            <label className="framed flex-grow">
                                                <input placeholder=" "  defaultValue={`${item.deal_agreed} лв.`} />
                                                <span>Отчетена печалба</span>
                                            </label>
                                        </div>



                                        <div className="flex mr-3 w-28">
                                            <label className="framed flex-grow">
                                                <input placeholder=" "  defaultValue={`${item.inProfit} лв.`} />
                                                <span>{item.inProfit < 1 ? "Минус" : "Плюс"}</span>
                                            </label>
                                        </div>

                                        <div className="flex mr-3 w-36">
                                            <label className="framed flex-grow">
                                                <input placeholder=" "  defaultValue={`${item.monthlyEarning} лв.`} />
                                                <span>Месечнa печалба</span>
                                            </label>
                                        </div>

                                    </li>
                                );
                            })}
                        </ul>

                        <div className="flex items-center pb-2 mt-3">
                            <div className="flex flex-grow items-center text-sm flex-row-reverse">
                                <div className="bold font-light mr-7">
                                    Месечна печалба: <br /> {monthlyEarning} лев{monthlyEarning == 1 ? "" : "а"}
                                </div>
                                <div className={inProfit > 0 ? "bold text-green-500 font-light mr-7" : "bold text-red-500 font-light mr-7"}>
                                    {inProfit < 1 ? "Минус" : "Плюс"}: <br /> {inProfit} лев{inProfit == 1 ? "" : "а"}
                                </div>
                                <div className="bold font-light mr-7">
                                    Очаквана печалба: <br /> {expectedEarning} лев{expectedEarning == 1 ? "" : "а"}
                                </div>
                                <div className={endingH < 1 ? "bold text-green-500 font-light mr-7" : "bold text-red-500 font-light mr-7"}>
                                    {endingH < 1 ? "Отгоре с" : "Още"}:<br /> {endingH < 0 ? endingH*-1 : endingH} час{endingH == 1 || endingH == -1 ? "" : "а"}
                                </div>
                                <div className="bold font-light mr-7">
                                    Отчел:<br /> {accountedH} час{accountedH == 1 ? "" : "а"}
                                </div>
                                <div className="bold font-light mr-7">
                                    Работил:<br /> {workedH} час{workedH == 1 ? "" : "а"}
                                </div>
                                <div className="bold font-light mr-7">
                                    Очаквани часове:<br /> {dealHours} час{dealHours == 1 ? "" : "а"}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>

            <div className="flex flex-col bg-gray-50 px-4 sm:p-6 sm:pb-4">
                <div className="flex items-center pb-2 font-medium text-base">
                    Записки
                </div>

                <div className="w-full z-40">
                    <Editor
                      initialValue={clientInfo.wallet_description}
                      ref={editorRefResourse}
                      init={{
                        selector: '#myeditable-h1',
                        height: 350,
                        menubar: false,
                        relative_urls : false,
                        mode: "textareas",
                        paste_as_text: true,
                        browser_spellcheck: true,
                        contextmenu: false,
                        object_resizing : true,
                        valid_elements: "*[*]",
                        fontsize_formats: "16px 18px 20px 22px 24px 30px 36px 48px",
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor textcolor pagebreak tabfocus directionality autosave',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code wordcount'
                        ],
                        toolbar:
                          'undo redo | bold italic forecolor backcolor fontsizeselect | \
                          alignleft aligncenter alignright alignjustify blockquote | \
                          bullist numlist removeformat outdent indent | link insert media imageButton | code'
                      }}
                      onEditorChange={(value) => handleEditorChange(value)}
                    />
                </div>

            </div>

            <div className="px-4 bg-gray-50 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                    type="submit"
                    onClick={() => toastMe()}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-200 text-green-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
                    Запази
                </button>
            </div>

        </form>

        </>
);
};

export default Wallet;
