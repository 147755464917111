import { v4 as uuidv4 } from 'uuid';

//Apis
import appsApi from '../../env/apis/projects/appsApi';

//Types
import {
    CLIENTS_ADD_ROW,
    CLIENTS_UPDATE_ROW,
    CLIENTS_UPDATE_FIELD,
    CLIENTS_DELETE_ROW,
    CLIENTS_GET_ROW,
    CLIENTS_GET_ROWS,
    CLIENTS_CLIENTINFO
} from './types';

import generateUUID from '../../hooks/generateUUID';

export const addBlock = () => async (dispatch,getState) => {
    try {
        const rowDefault = {
            "id"        : generateUUID(),
            "weight"    : 0,
            "name"      : uuidv4(),
        };
        dispatch({ type: CLIENTS_ADD_ROW, payload: rowDefault });
        const response = await appsApi.post('/clients',rowDefault);
    }
    catch {
        //dispatch({ type: WRONG_LOGIN });
    }
};

export const deleteBlock = (payload) => async (dispatch,getState) => {
    dispatch({ type: CLIENTS_DELETE_ROW, payload: payload.id });
    await appsApi.delete('/clients/'+payload.id);
};

export const getBlocks = (payload) => async (dispatch,getState) => {
    if(payload && payload.q) {
        const response = await appsApi.post('/clients/search',payload);
        return dispatch({ type: CLIENTS_GET_ROWS, payload: response.data.data });
    }

    const response = await appsApi.get('/clients?sort=-isImportant,-weight');
    dispatch({ type: CLIENTS_GET_ROWS, payload: response.data });
};

export const searchBlocks = (payload) => async (dispatch,getState) => {
    const response = await appsApi.post('/clients/search',payload);
    dispatch({ type: CLIENTS_GET_ROWS, payload: response.data.data });
};

export const updateBlocksAction = (payload) => async (dispatch,getState) => {
    payload.row.settings = payload.data;
    dispatch({ type: CLIENTS_UPDATE_ROW, payload: payload.row });
    appsApi.patch('/clients/'+payload.row.id, {
        "settings" : payload.data
    });
};

export const updateBlocksFieldAction = (payload) => async (dispatch,getState) => {
    if(payload.multi) {
        appsApi.patch('/clients/'+payload.row.id,payload.multi);
        return;
    }

    let update = [];
    update[payload.column] = payload.value;
    update = Object.assign({}, update);

    if(!payload.stopRedraw) {
        dispatch({ type: CLIENTS_UPDATE_FIELD, payload: payload });
    }

    appsApi.patch('/clients/'+payload.row.id,update);
};

export const reorderBlocks = (payload) => async (dispatch,getState) => {
    payload.forEach((v,i) => {
        payload[i]["weight"] = i*-1;
    });
    appsApi.post('/clients/weights',payload);
    dispatch({ type: CLIENTS_GET_ROWS, payload: payload });

};


export const updateClientAction = (payload) => async (dispatch,getState) => {
    dispatch({ type: CLIENTS_CLIENTINFO, payload: payload.newState });
    appsApi.patch('/clients/'+payload.row.id, payload.data);
};

export const updateClientInfo = (payload) => async (dispatch,getState) => {

    dispatch({ type: CLIENTS_CLIENTINFO, payload: payload.clientInfo });

    if(payload.multi) {
        appsApi.patch('/clients/'+payload.clientInfo.id,payload.multi);
        return;
    }

    let update = [];
    update[payload.column] = payload.value;
    update = Object.assign({}, update);
    appsApi.patch('/clients/'+payload.clientInfo.id,update);
};

export const getClient = (payload) => async (dispatch,getState) => {
    if(payload && payload.search) {
        const response = await appsApi.post('/clients/project/search/'+payload.client,payload);
        return dispatch({ type: CLIENTS_CLIENTINFO, payload: response.data.data });
    }

    const response = await appsApi.get('/clients/'+payload.client);
    dispatch({ type: CLIENTS_CLIENTINFO, payload: response.data.data});
};
