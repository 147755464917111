const columns = [
    {
        "key": "actions",
        "title": "Действия",
        "dataKey": "actions",
        "align": "center",
        "width": 50,
    },
    {
        "key": "app_name_input",
        "title": "Име на модула",
        "dataKey": "app_name",
        "width": 350,
    },
    {
        "key": "app_database_input",
        "title": "Име на базата данни",
        "dataKey": "app_database",
        "width": 350,
    },
    {
        "key": "app_filename_input",
        "title": "Име на файловете",
        "dataKey": "app_filename",
        "width": 350,
    },
    {
        "key": "id",
        "title": "ID",
        "dataKey": "id",
        "align": "center",
        "width": 200,
    },
    {
        "key": "action",
        "title": "",
        "align": "center",
        "width": 50
    }
];

export default columns;
