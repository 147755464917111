import React from "react";

const Stats = (row) => {

    return (
        <>
            <div className="flex-grow mt-5">
                <div className="bg-white py-5 text-center w-full font-semibold"> Няма статистики </div>
            </div>
        </>
    );
};

export default Stats;
