
export default function LeftImage() {
  return (
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover lazyload"
          data-src="/img/photo-1632817674996-3b3c501faada_2.jpeg"
          alt=""
        />
      </div>
);
}
