import {
    CLIENTS_TASKS_START_EDIT,
    CLIENTS_TASKS_ACCOUNTED
} from '../types';

const INTIAL_STATE = {
  "task": [],
  "accounted": []
};

const clientsTasksReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case CLIENTS_TASKS_START_EDIT:
            return { ...state, task: action.payload};
        case CLIENTS_TASKS_ACCOUNTED:
            return { ...state, accounted: action.payload};
        default:
            return state;
    }
}

export default clientsTasksReducer;
