import {
    CLIENTS_ADD_ROW,
    CLIENTS_UPDATE_ROW,
    CLIENTS_UPDATE_FIELD,
    CLIENTS_DELETE_ROW,
    CLIENTS_GET_ROWS,
    CLIENTS_CLIENTINFO,
    CLIENTS_LATEST_TASKS
} from '../types';

const INTIAL_STATE = {
  "isLoaded": false,
  "openedModal": false,
  "openedClient": false,
  "rows": [],
  "columns": [],
  "clientInfo": [],
  "row": [],
  "latestTasks": []
};

const appsListReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case CLIENTS_CLIENTINFO:
            return {...state,clientInfo: action.payload};
        case CLIENTS_GET_ROWS:
            return { ...state, rows: action.payload, isLoaded: true};
        case CLIENTS_ADD_ROW:
            return { ...state, rows: [action.payload,...state.rows]};
        case CLIENTS_UPDATE_ROW:
            return {...state,rows: state.rows.map(row => row.id === action.payload.id ? action.payload : row)};
        case CLIENTS_UPDATE_FIELD:
            action.payload.row[action.payload.column] = action.payload.value;
            return { ...state, rows: state.rows.map(row => row.id === action.id ? action.payload.row : row)};
        case CLIENTS_DELETE_ROW:
            return { ...state, rows: state.rows.filter((item) => item.id !== action.payload)};
        case CLIENTS_LATEST_TASKS:
            return {...state,latestTasks: action.payload};            
        default:
            return state;
    }
}

/*
UPDATE SINGLE COLUMNG

*/

export default appsListReducer;
