import React, { useState, useRef, useEffect } from "react";
import { Modal } from 'react-responsive-modal';
import Switch from "react-switch";
import ReactSelect from 'react-select'
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';

//Actions
import { openCloseModalPrAction, updatePrAction } from '../../../../../actions/projects/clientsProjectAction'

const options = [
  { value: 'link', label: 'Линк'},
  { value: 'courses', label: 'Курс'},
  { value: 'npmjs', label: 'Npmjs'},
  { value: 'packagist', label: 'Packagist'}
]

export default () => {

    const dispatch = useDispatch();
    const { openedModalPr, rowPr } = useSelector(state => state.clientsProject);

    const resources = [];
    for(var i in rowPr.resources)  resources.push({label:rowPr.resources[i].label,"description":rowPr.resources[i].description,"kind":rowPr.resources[i].kind});
    rowPr.resources = resources;

    const editorRefResourse = useRef();

    const { register, control, reset, getValues, handleSubmit } = useForm({
        defaultValues: rowPr.settings
    });

    if(!rowPr.settings) {
        rowPr.settings = {};
    }

    if(!rowPr.settings || !rowPr.settings.resources_description) {
        rowPr.settings.resources_description = "";
    }

    const {
      fields: bookmarkFields,
      append: bookmarkAppend,
      remove: bookmarkRemove,
      move  : bookmarkMove
    } = useFieldArray({ control, name: "resources" });

    const handleEditorChange = (value) => {
    };

    const toastMe = () => {
        toast.success("Данните са запазени!")
    };

    const keyDown = (e) => {
        if(e.key == "Enter") {
            e.currentTarget.blur();
        }
    };

    const saveData = (e) => {
        onSubmit(getValues());
    }

    const onSubmit = async (data) => {
        if(rowPr && rowPr.id && data) {
            const bookmarksLength = data.resources && data.resources.length > 0 ? true : false;

            data.resources = data.resources;

            data.resources_description = "";
            if(editorRefResourse && editorRefResourse.current && editorRefResourse.current.currentContent) {
                data.resources_description = editorRefResourse.current.currentContent;
            }



            await dispatch(updatePrAction({
                "row" : rowPr,
                "column":"settings",
                "value":data
            }));

            if(data.resources && bookmarksLength > 0) {
                await bookmarkMove(0,0);
            }

            toastMe();
        }
    };

    const closeModal = (rowPr) => {
        dispatch(openCloseModalPrAction({ "openClose":false, rowPr }));
    }

    return (
        <>
        <Modal
            open={openedModalPr}
            onClose={() => closeModal(rowPr)}
            center
            classNames={{
                modal: 'text-center inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl bg-gray-900 sm:1/3 lg:w-2/3',
            }}
            >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4 w-full">
                    <h3
                        className="text-xl leading-6 font-medium text-gray-900 w-full"
                        id="modal-headline">
                        Редакция на канал за популяризиране &nbsp;
                        <small className="text-sm text-gray-600">
                            ({rowPr.title})
                        </small>
                    </h3>
                </div>

                <div className="bg-gray-100 py-3 px-6 pt-1 ">
                    <div className="w-full mt-5 z-50 relative">
                        <div className="flex items-center pb-2 font-medium text-base">
                            Bookmarks
                        </div>

                        <ul className="w-full">
                            {bookmarkFields.map((item, index) => {
                                return (
                                    <li key={item.id} className="flex items-center pb-2">
                                        <div className="mr-5 w-48 pt-0.5">
                                            <Controller
                                              render={({ field }) => (<ReactSelect {...field} options={options} className="w-full text-sm" placeholder="Тип ресурс..." />)}
                                              name={`resources[${index}].kind`}
                                              control={control}
                                            />
                                        </div>

                                        <div className="flex-1 mr-5">
                                            <label className="framed w-full mt-1.5">
                                                <input placeholder=" " {...register(`resources[${index}].description`)} name={`resources[${index}].description`} defaultValue={item.description} onBlur={(e) => saveData(e)} onKeyDown={(e) => keyDown(e)} />
                                                <span>Описание</span>
                                            </label>
                                        </div>

                                        <div className="flex-grow flex mr-5">
                                            {item.label &&
                                                <a href={item.label} className="flex bg-indigo-200 w-10 rounded-l mt-1.5 items-center justify-center " target="_blank">
                                                    <svg className="w-5 h-5 text-gray-500 font-extrabold" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                                                    </svg>
                                                </a>
                                            }
                                            <label className="framed flex-grow">
                                                <input placeholder=" " {...register(`resources[${index}].label`)} name={`resources[${index}].label`} defaultValue={item.label} onBlur={(e) => saveData(e)} onKeyDown={(e) => keyDown(e)} />
                                                <span>Url</span>
                                            </label>
                                        </div>



                                        <div className="flex-2">
                                            <button type="button" className="flex items-center justify-center h-9 w-9 rounded bg-red-200 focus:outline-none"
                                                onClick={async() => {
                                                    await bookmarkRemove(index);
                                                    await onSubmit(getValues());
                                                }}
                                                >
                                                <svg className="w-5 h-5 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
                                                </svg>
                                            </button>
                                        </div>

                                    </li>
                                );
                            })}
                        </ul>

                        <div className="flex items-center pb-2 mb-5">
                            <button type="button" className="flex items-center text-green-600 justify-center py-2 px-6 shadow rounded bg-green-200 focus:outline-none"
                                onClick={async() => {
                                    await bookmarkAppend({ label: "", value: "" });
                                    await onSubmit(getValues());
                                }}
                                >
                                Добави bookmark
                                <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div className="flex items-center pb-2 font-medium text-base">
                        Записки
                    </div>

                    <div className="w-full z-40">
                        <Editor
                          initialValue={rowPr.settings.resources_description}
                          ref={editorRefResourse}
                          id="dghweighweiugh"
                          init={{
                            selector: '#myeditable-h1',
                            height: 550,
                            menubar: false,
                            relative_urls : false,
                            mode: "textareas",
                            paste_as_text: true,
                            browser_spellcheck: true,
                            contextmenu: false,
                            object_resizing : true,
                            valid_elements: "*[*]",
                            fontsize_formats: "16px 18px 20px 22px 24px 30px 36px 48px",
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor textcolor pagebreak tabfocus directionality autosave',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code wordcount'
                            ],
                            toolbar:
                              'undo redo | bold italic forecolor backcolor fontsizeselect | \
                              alignleft aligncenter alignright alignjustify blockquote | \
                              bullist numlist removeformat outdent indent | link insert media imageButton | code'
                          }}
                          onEditorChange={(value) => handleEditorChange(value)}
                        />
                    </div>
                </div>


                <div className="px-4 bg-gray-50 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                        type="button"
                        onClick={() => saveData()}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                        Запази
                    </button>
                    <button
                        type="button"
                        onClick={() => closeModal(rowPr)}
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                        Отказ
                    </button>
                </div>

            </form>

        </Modal>
        </>
);
};
