import { SIGN_IN, SIGN_OUT, PASSWORD, LOGGIN, WRONG_LOGIN } from './../types';

const INTIAL_STATE = {
  isSignedIn: null,
  userId: null
};

const authReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case LOGGIN:
      return { ...state, isSignedIn: true, user: action.payload, wrongLoginData: false};
    case SIGN_OUT:
      return { ...state, isSignedIn: false, user: null, wrongLoginData: false };
    case PASSWORD:
      return { ...state, isSignedIn: false, user: null, wrongLoginData: false };
    case WRONG_LOGIN:
      return { ...state, wrongLoginData: true };
    default:
      return state;
  }
}

export default authReducer;
