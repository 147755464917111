import { OPEN_SIDEABAR } from './../types';

import { ArchiveIcon, CalendarIcon, HomeIcon, UserCircleIcon as UserCircleIconOutline } from '@heroicons/react/outline';

const INTIAL_STATE = {
  sidebarOpen: false,
  layout: "WithSidebar", //TopSidebar
  menu: [
    // { name: 'Начало', href: '/', icon: HomeIcon, current: true },
    { name: 'Приложения', href: '/apps', icon: UserCircleIconOutline, current: false },
    { name: 'Клиенти', href: '/clients', icon: CalendarIcon, current: false },
    { name: 'Демо', href: '/demo', icon: ArchiveIcon, current: false }
  ],
  crum: [
    { title: 'Projects', link: '#', current: false },
    { title: 'Project Nero', link: '#', current: true },
  ]
};

const dashboardReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_SIDEABAR:
      return { ...state, sidebarOpen: action.payload };
    default:
      return state;
  }
};

export default dashboardReducer;
