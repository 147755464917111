import React, { useState, useRef, useEffect } from "react";
import Switch from "react-switch";
import ReactSelect from 'react-select'
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';
import history from '../../../../../history';


//Actions
import { updateProject } from '../../../../../actions/projects/clientsProjectAction'

const options = [
  { value: 'link', label: 'Линк'},
  { value: 'courses', label: 'Курс'},
  { value: 'npmjs', label: 'Npmjs'},
  { value: 'packagist', label: 'Packagist'}
]

const Resourses = () => {

    const dispatch = useDispatch();
    const { clientInfo } = useSelector(state => state.clients);
    const { project } = useSelector(state => state.clientsProject);


    const resources = [];
    for(var i in project.resources)  resources.push({label:project.resources[i].label,"description":project.resources[i].description,"kind":project.resources[i].kind});
    project.resources = resources;

    const competition = [];
    for(var i in project.competition)  competition.push({url4:project.competition[i].url4,url3:project.competition[i].url3,url2:project.competition[i].url2,url:project.competition[i].url,"name":project.competition[i].name});
    project.competition = competition;

    const editorRefResourse = useRef();

    const { register, control, reset, getValues, handleSubmit } = useForm({
        defaultValues: project
    });

    const {
      fields: bookmarkFields,
      append: bookmarkAppend,
      remove: bookmarkRemove,
      move  : bookmarkMove
    } = useFieldArray({ control, name: "resources" });

    const {
      fields: competitionFields,
      append: competitionAppend,
      remove: competitionRemove,
      move  : competitionMove

    } = useFieldArray({ control, name: "competition" });

    const handleEditorChange = (value) => {
    };

    const toastMe = () => {
        toast.success("Данните са запазени!")
    };

    const saveData = (e) => {

        let allowSubmit = e._reactName == "onBlur" ? true : false;
        if(e.key == "Enter") {
            //onSubmit(getValues());
        }
        else if(allowSubmit) {
            onSubmit(getValues());
        }
    }

    const onSubmit = async (data) => {
        if(clientInfo && clientInfo.id && data) {
            const bookmarksLength = data.resources && data.resources.length > 0 ? true : false;
            const competitionLength = data.competition && data.competition.length > 0 ? true : false;

            data.resources = Object.assign({}, data.resources);
            data.competition = Object.assign({}, data.competition);
            data.resources_description = editorRefResourse.current.currentContent;

            dispatch(updateProject({update:data,project,clientInfo}));

            if(data.resources && bookmarksLength > 0) {
                await bookmarkMove(0,0);
            }
            if(data.competition && competitionLength>0) {
                await competitionMove(0,0);
            }
        }
    };

    return (
        <>
        <form onSubmit={handleSubmit(onSubmit)}>

            <div className="flex flex-inline items-center bg-gray-50 px-4 pъ-5 sm:p-6 sm:pb-4">
                <div className="text-xl leading-6 font-medium text-gray-900">
                    Ресурси&nbsp;
                    <small className="text-sm text-gray-600">
                        (клиент: {clientInfo.name})
                    </small>
                </div>
                <div className="flex flex-grow flex-row-reverse">
                    <button onClick={() => toastMe()} type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent ml-10 px-4 py-2 bg-green-200  shadow text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:w-auto sm:text-sm">
                        <svg className="w-6 h-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </button>
                </div>
            </div>


            <div className="bg-gray-100 py-3 px-6 pt-1 ">
                <div className="w-full mt-5 z-50 relative">
                    <div className="flex items-center pb-2 font-medium text-base">
                        Bookmarks
                    </div>

                    <ul className="w-full">
                        {bookmarkFields.map((item, index) => {
                            return (
                                <li key={item.id} className="flex items-center pb-2">
                                    <div className="mr-5 w-48 pt-0.5">
                                        <Controller
                                          as={ReactSelect}
                                          options={options}
                                          name={`resources[${index}].kind`}
                                          className="w-full text-sm"
                                          placeholder="Тип ресурс..."
                                          control={control}
                                        />
                                    </div>

                                    <div className="flex-1 mr-5">
                                        <label className="framed w-full mt-1.5">
                                            <input placeholder=" " ref={register()} name={`resources[${index}].description`} defaultValue={item.description} onBlur={(e) => saveData(e)} onKeyDown={(e) => saveData(e)} />
                                            <span>Описание</span>
                                        </label>
                                    </div>

                                    <div className="flex-grow flex mr-5">
                                        {item.label &&
                                            <a href={item.label} className="flex bg-indigo-200 w-10 rounded-l mt-1.5 items-center justify-center " target="_blank">
                                                <svg className="w-5 h-5 text-gray-500 font-extrabold" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                                                </svg>
                                            </a>
                                        }
                                        <label className="framed flex-grow">
                                            <input placeholder=" " ref={register()} name={`resources[${index}].label`} defaultValue={item.label} onBlur={(e) => saveData(e)} onKeyDown={(e) => saveData(e)} />
                                            <span>Url</span>
                                        </label>
                                    </div>



                                    <div className="flex-2">
                                        <button type="button" className="flex items-center justify-center h-9 w-9 rounded bg-red-200 focus:outline-none"
                                            onClick={async() => {
                                                await bookmarkRemove(index);
                                                await onSubmit(getValues());
                                            }}
                                            >
                                            <svg className="w-5 h-5 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
                                            </svg>
                                        </button>
                                    </div>

                                </li>
                            );
                        })}
                    </ul>

                    <div className="flex items-center pb-2 mb-5">
                        <button type="button" className="flex items-center text-green-600 justify-center py-2 px-6 shadow rounded bg-green-200 focus:outline-none"
                            onClick={async() => {
                                await bookmarkAppend({ label: "", value: "" });
                                await onSubmit(getValues());
                            }}
                            >
                            Добави bookmark
                            <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                            </svg>
                        </button>
                    </div>
                </div>


                <div className="flex items-center pb-2 font-medium text-base">
                    Записки
                </div>

                <div className="w-full z-40">
                    <Editor
                      initialValue={project.resources_description}
                      ref={editorRefResourse}
                      id="dghweighweiugh"
                      init={{
                        selector: '#myeditable-h1',
                        height: 550,
                        menubar: false,
                        relative_urls : false,
                        mode: "textareas",
                        paste_as_text: true,
                        browser_spellcheck: true,
                        contextmenu: false,
                        object_resizing : true,
                        valid_elements: "*[*]",
                        fontsize_formats: "16px 18px 20px 22px 24px 30px 36px 48px",
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor textcolor pagebreak tabfocus directionality autosave',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code wordcount'
                        ],
                        toolbar:
                          'undo redo | bold italic forecolor backcolor fontsizeselect | \
                          alignleft aligncenter alignright alignjustify blockquote | \
                          bullist numlist removeformat outdent indent | link insert media imageButton | code'
                      }}
                      onEditorChange={(value) => handleEditorChange(value)}
                    />
                </div>



                <div className="w-full mt-5 z-40 relative">
                    <div className="flex items-center pb-2 font-medium text-base">
                        Конкуренти
                    </div>

                    <ul className="w-full">
                        {competitionFields.map((item, index) => {
                            return (
                                <li key={item.id} className="flex items-center pb-2">
                                    <div className="mr-5 w-48 pt-0.5">
                                        <label className="framed flex-grow">
                                            <input placeholder=" " ref={register()} name={`competition[${index}].name`} defaultValue={item.name} onBlur={(e) => saveData(e)} onKeyDown={(e) => saveData(e)} />
                                            <span>Име</span>
                                        </label>
                                    </div>
                                    <div className="flex-grow flex mr-5">
                                        {item.url &&
                                            <a href={item.url} className="flex bg-indigo-200 w-10 rounded-l mt-1.5 items-center justify-center " target="_blank">
                                                <svg className="w-5 h-5 text-gray-500 font-extrabold" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                                                </svg>
                                            </a>
                                        }
                                        <label className="framed flex-grow">
                                            <input placeholder=" " ref={register()} name={`competition[${index}].url`} defaultValue={item.url} onBlur={(e) => saveData(e)} onKeyDown={(e) => saveData(e)} />
                                            <span>Url</span>
                                        </label>
                                    </div>

                                    <div className="flex-grow flex mr-5">
                                        {item.url2 &&
                                            <a href={item.url2} className="flex bg-indigo-200 w-10 rounded-l mt-1.5 items-center justify-center " target="_blank">
                                                <svg className="w-5 h-5 text-gray-500 font-extrabold" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                                                </svg>
                                            </a>
                                        }
                                        <label className="framed flex-grow">
                                            <input placeholder=" " ref={register()} name={`competition[${index}].url2`} defaultValue={item.url2} onBlur={(e) => saveData(e)} onKeyDown={(e) => saveData(e)} />
                                            <span>Url</span>
                                        </label>
                                    </div>

                                    <div className="flex-grow flex mr-5">
                                        {item.url3 &&
                                            <a href={item.url3} className="flex bg-indigo-200 w-10 rounded-l mt-1.5 items-center justify-center " target="_blank">
                                                <svg className="w-5 h-5 text-gray-500 font-extrabold" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                                                </svg>
                                            </a>
                                        }
                                        <label className="framed flex-grow">
                                            <input placeholder=" " ref={register()} name={`competition[${index}].url3`} defaultValue={item.url3} onBlur={(e) => saveData(e)} onKeyDown={(e) => saveData(e)} />
                                            <span>Url</span>
                                        </label>
                                    </div>

                                    <div className="flex-grow flex mr-5">
                                        {item.url4 &&
                                            <a href={item.url4} className="flex bg-indigo-200 w-10 rounded-l mt-1.5 items-center justify-center " target="_blank">
                                                <svg className="w-5 h-5 text-gray-500 font-extrabold" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                                                </svg>
                                            </a>
                                        }
                                        <label className="framed flex-grow">
                                            <input placeholder=" " ref={register()} name={`competition[${index}].url4`} defaultValue={item.url4} onBlur={(e) => saveData(e)} onKeyDown={(e) => saveData(e)} />
                                            <span>Url</span>
                                        </label>
                                    </div>


                                    <div className="flex-2">
                                        <button type="button" className="flex items-center justify-center h-9 w-9 rounded bg-red-200 focus:outline-none"
                                            onClick={async () => {
                                                await competitionRemove(index);
                                                await onSubmit(getValues());
                                            }}
                                            >
                                            <svg className="w-5 h-5 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
                                            </svg>
                                        </button>
                                    </div>

                                </li>
                            );
                        })}
                    </ul>

                    <div className="flex items-center pb-2 mb-5">
                        <button type="button" className="flex items-center text-green-600 justify-center py-2 px-6 shadow rounded bg-green-200 focus:outline-none"
                            onClick={async() => {
                                await competitionAppend({ label: "", value: "" });
                                await onSubmit(getValues());
                            }}
                            >
                            Добави конкурент
                            <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                            </svg>
                        </button>
                    </div>
                </div>

            </div>


            <div className="px-4 bg-gray-50 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                    type="submit"
                    onClick={() => toastMe()}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-200 text-green-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
                    Запази
                </button>
            </div>

        </form>

        </>
);
};

export default Resourses;
