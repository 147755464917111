const options = [
  { value: 'text', label: 'Текст'},
  { value: 'number', label: 'Число'},
  { value: 'text_date', label: 'Дата'},
  { value: 'text_time', label: 'Час'},
  { value: 'text_datetime', label: 'Дата & Час'},
  { value: 'textarea', label: 'Textarea'},
  { value: 'textarea_editor', label: 'Textarea With Editor'},
  { value: 'select', label: 'Select Option'},
  { value: 'radio', label: 'Radio'},
  { value: 'checkbox', label: 'Checkbox'},
  { value: 'file', label: 'Файл'},
  { value: 'hidden', label: 'Скрито'},
  { value: 'recursion', label: 'Дървовидно'},
  { value: 'recursion_path', label: 'Пътечка на дървото'},
  { value: 'password', label: 'Парола'},
  { value: 'autocomplete', label: 'Autocomplete'},
  { value: 'maps', label: 'Карта'},
  { value: 'tags', label: 'Тагове'},
  { value: 'quick_subform', label: 'Бърза Подформа'},
  { value: 'subform', label: 'Подформа'},
  { value: 'id', label: 'Първичен ключ (auto increment)'},
  { value: 'created', label: 'Създаден от user (admin)'},
  { value: 'last_updated', label: 'Последно обновен от user (admin)'},
  { value: 'created_at', label: 'Timestamp създаване'},
  { value: 'updated_at', label: 'Timestamp обновяване'},
];

export default options;
