import {
    ADD_ROW_LIST,
    UPDATE_ROW_LIST,
    UPDATE_FIELD_LIST,
    DELETE_ROW_LIST,
    GET_ROW_LIST,
    GET_ROWS_LIST
} from '../types';

const INTIAL_STATE = {
  "isLoaded": false,
  "openedModal": false,
  "rows": [],
  "row": []
};

const appsListReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case GET_ROWS_LIST:
            return { ...state, rows: action.payload, isLoaded: true};
        case ADD_ROW_LIST:
            return { ...state, rows: [action.payload,...state.rows]};
        case UPDATE_ROW_LIST:
            return {...state,rows: state.rows.map(row => row.id === action.payload.id ? action.payload : row)};
        case UPDATE_FIELD_LIST:
            action.payload.row[action.payload.column] = action.payload.value;
            return { ...state, rows: state.rows.map(row => row.id === action.id ? action.payload.row : row)};
        case DELETE_ROW_LIST:
            return { ...state, rows: state.rows.filter((item, index) => item.id !== action.payload)};
        default:
            return state;
    }
}

/*
UPDATE SINGLE COLUMNG

*/

export default appsListReducer;
