const columns = [
    {
        "key": "sort",
        "title": "Сорт",
        "dataKey": "sort",
        "align": "center",
        "width": 50,
    },
    {
        "key": "actions",
        "title": "Действия",
        "dataKey": "actions",
        "sortable": false,
        "width": 100,
        "className": "text-sm"
    },
    {
        "key": "id",
        "title": "ID",
        "dataKey": "id",
        "sortable": false,
        "width": 100,
        "className": "text-sm"
    },
    {
        "key": "name_input",
        "title": "Име",
        "dataKey": "name",
        "sortable": false,
        "width": 310,
        "className": "text-sm"
    },
    {
        "key": "email_input",
        "title": "Имейл",
        "dataKey": "email",
        "sortable": false,
        "width": 310,
        "className": "text-sm"
    },
    {
        "key": "phone_input",
        "title": "Телефон",
        "dataKey": "phone",
        "sortable": false,
        "width": 310,
        "className": "text-sm"
    },
    {
        "key": "action",
        "title": "",
        "align": "center",
        "className": "text-sm",
        "width": 50
    }
];

export default columns;
