import React, { useState,useEffect,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { toast } from 'react-toastify';
import Switch from "react-switch";
import { Editor } from '@tinymce/tinymce-react';
import Collapsible from 'react-collapsible';
import { SvgKey,SvgDatabase,SvgUrl,SvgInbox,SvgGridAdd,SvgSettings } from '../../../parts/blocks/Svg';

//Actions
import { updateProject } from '../../../../../actions/projects/clientsProjectAction'

const options = [
  { value: '0', label: 'Тип доход'},
  { value: 'monthly', label: 'Месечен'},
  { value: 'weekly', label: 'Седмичен'},
  { value:'onetime', label: 'Еднократен'}
]

const MainInfo = () => {

    const dispatch = useDispatch();
    const { clientInfo } = useSelector(state => state.clients);
    const { project  } = useSelector(state => state.clientsProject);

    const [isActive, setIsActive] = useState(project.isActive ? true : false);
    const editorRef = useRef();

    const handleActivate = nextChecked => {
        setIsActive(nextChecked);
    };

    const handleEditorChange = (value) => {
    };

    const { register, control, handleSubmit } = useForm({
        defaultValues: project
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "manual"
    });

    const notify = () => toast.success("Данните са запазени!");


    const onSubmit = async (data) => {
        if(project && project._id && data) {
            data.isActive = isActive;
            data.description = editorRef.current.currentContent;
            const newState = {...project};
            for (var x in data) {
                newState[x] = data[x] ? data[x] : "";
            }

            dispatch(updateProject({newState:newState,update:data,project,clientInfo}));

            notify();
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="flex mt-4 flex-inline items-center bg-gray-50 px-4 pъ-5 sm:p-6 sm:pb-4">
                    <div className="text-xl leading-6 font-medium text-gray-900">
                        Основна информация&nbsp;
                        <small className="text-sm text-gray-600">
                            (проект: {project.title})
                        </small>
                    </div>
                    <div className="flex flex-grow flex-row-reverse">
                        {project.admin_domain &&
                            <a href={`http://gen.giganot.com/seeder/config/${project._id}`} className="flex ml-7 justify-center items-center text-red-600 rounded-md border border-transparent ml-3 px-4 py-2 bg-red-200 shadow text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm" target="_blank">
                                <SvgSettings classes="w-4 h-4 mr-1 animate-spin" />
                                Конфигурирай
                            </a>
                        }
                        <button type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent ml-9 px-4 py-2 bg-green-200 shadow text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:w-auto sm:text-sm">
                            <svg className="w-6 h-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </button>

                        <div className="text-gray-600 flex items-center">
                            <div className="font-medium text-gray-700 text-base flex-2 mr-4">
                                Активен:
                            </div>
                            <div className="flex-1 mt-1">
                                <Switch onChange={handleActivate} checked={isActive} />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="bg-gray-100 px-4 py-3 sm:px-6 pt-1">
                    <div className="w-full">
                        <div className="w-full z-40 relative">
                            <div className="flex items-center pb-2">
                                <div className="flex-1 mr-10">
                                    <label className="framed w-full">
                                        <input placeholder=" " {...register(`title`)}  name="title" />
                                        <span>Име на проекта  (вътрешно за управление)</span>
                                    </label>
                                </div>
                                <div className="flex">
                                    <label className="framed w-full">
                                        <input placeholder=" " {...register(`weight`)}  name="weight" />
                                        <span>Тегло на проекта</span>
                                    </label>
                                </div>
                            </div>
                            <div className="flex items-center pb-2">
                                <div className="flex-1">
                                    <label className="framed w-full">
                                        <input placeholder=" " {...register(`env.APPNAME`)}  name="env[APPNAME]" />
                                        <span>Име на приложението  (използва се за вътрешни конфигурации)</span>
                                    </label>
                                </div>
                            </div>

                            <div className="flex items-center pb-1">
                                <div className="flex-1 mr-5">
                                    <label className="framed w-full">
                                        <textarea placeholder=" " {...register(`env.META_TITLE`)}  name="env[META_TITLE]"></textarea>
                                        <span>Мета заглавие </span>
                                    </label>
                                </div>
                                <div className="flex-1">
                                    <label className="framed w-full">
                                        <textarea placeholder=" " {...register(`env.META_DESCRIPTION`)}  name="env[META_DESCRIPTION]"></textarea>
                                        <span>Мета описание </span>
                                    </label>
                                </div>
                            </div>

                            <div className="flex items-center pb-3">
                                <div className="flex flex-1 mr-5">
                                    {project.domain &&
                                        <a href={`http://${project.domain}`}  className="flex bg-blue-200 w-10 rounded-l mt-1.5 items-center justify-center " target="_blank">
                                            <SvgUrl classes="w-5 h-5 text-gray-500 font-extrabold" />
                                        </a>
                                    }
                                    <label className="framed w-full">
                                        <input placeholder=" " {...register(`domain`)}  name="domain" />
                                        <span>Домейн </span>
                                    </label>
                                </div>
                                <div className="flex flex-1">
                                    {project.admin_domain &&
                                        <a href={`http://${project.admin_domain}`} className="flex bg-blue-200 w-10 rounded-l mt-1.5 items-center justify-center " target="_blank">
                                            <SvgUrl classes="w-5 h-5 text-gray-500 font-extrabold" />
                                        </a>
                                    }
                                    <label className="framed w-full">
                                        <input placeholder=" " {...register(`admin_domain`)}  name="admin_domain" />
                                        <span>Админ домейн </span>
                                    </label>
                                </div>
                            </div>

                            <div className="flex items-center pb-2">
                                <div className="flex flex-1 mr-5">
                                    <div className="flex bg-red-200 w-10 rounded-l mt-1.5 items-center justify-center ">
                                        <SvgDatabase classes="w-5 h-5 text-gray-500 font-extrabold" />
                                    </div>
                                    <label className="framed w-full">
                                        <input placeholder=" " {...register(`env.DB_HOST`)}  name="env[DB_HOST]" />
                                        <span>База данни хост  (mariadb, localhost ...)</span>
                                    </label>
                                </div>

                                <div className="flex flex-1">
                                    <div className="flex bg-red-200 w-10 rounded-l mt-1.5 items-center justify-center ">
                                        <SvgDatabase classes="w-5 h-5 text-gray-500 font-extrabold" />
                                    </div>
                                    <label className="framed w-full">
                                        <input placeholder=" " {...register(`env.DB_DATABASE`)}  name="env[DB_DATABASE]" />
                                        <span>База данни </span>
                                    </label>
                                </div>
                            </div>

                            <div className="flex items-center pb-2">
                                <div className="flex flex-1 mr-5">
                                    <div className="flex bg-red-200 w-10 rounded-l mt-1.5 items-center justify-center ">
                                        <SvgDatabase classes="w-5 h-5 text-gray-500 font-extrabold" />
                                    </div>
                                    <label className="framed w-full">
                                        <input placeholder=" " {...register(`env.DB_USERNAME`)}  name="env[DB_USERNAME]" />
                                        <span>База данни потребител </span>
                                    </label>
                                </div>
                                <div className="flex flex-1">
                                    <div className="flex bg-red-200 w-10 rounded-l mt-1.5 items-center justify-center ">
                                        <SvgDatabase classes="w-5 h-5 text-gray-500 font-extrabold" />
                                    </div>
                                    <label className="framed w-full">
                                        <input placeholder=" " {...register(`env.DB_PASSWORD`)}  name="env[DB_PASSWORD]" />
                                        <span>База данни парола </span>
                                    </label>
                                </div>
                            </div>

                            <div className="flex items-center pb-2">
                                <div className="flex-1 mr-5 flex">
                                    <div className="flex bg-green-200 w-10 rounded-l mt-1.5 items-center justify-center ">
                                        <SvgKey classes="w-5 h-5 text-gray-500 font-extrabold" />
                                    </div>
                                    <label className="framed w-full">
                                        <input placeholder=" " {...register(`env.APP_KEY`)}  name="env[APP_KEY]" />
                                        <span>Ключ за апа (32 chars)</span>
                                    </label>
                                </div>
                                <div className="flex-1 flex">
                                    <div className="flex bg-green-200 w-10 rounded-l mt-1.5 items-center justify-center ">
                                        <SvgKey classes="w-5 h-5 text-gray-500 font-extrabold" />
                                    </div>
                                    <label className="framed w-full">
                                        <input placeholder=" " {...register(`env.UPLOAD_KEY`)}  name="env[UPLOAD_KEY]" />
                                        <span>Ключ за качване на файлове (32 chars)</span>
                                    </label>
                                </div>
                            </div>
                            <div className="flex items-center pb-2">
                                <div className="flex-1 mr-5 flex">
                                    <div className="flex bg-green-200 w-10 rounded-l mt-1.5 items-center justify-center ">
                                        <SvgKey classes="w-5 h-5 text-gray-500 font-extrabold" />
                                    </div>
                                    <label className="framed w-full">
                                        <input placeholder=" " {...register(`env.COOKIE_NAME`)}  name="env[COOKIE_NAME]" />
                                        <span>Име на cookie (15 chars max)</span>
                                    </label>
                                </div>
                                <div className="flex-1 flex">
                                    <div className="flex bg-green-200 w-10 rounded-l mt-1.5 items-center justify-center ">
                                        <SvgKey classes="w-5 h-5 text-gray-500 font-extrabold" />
                                    </div>
                                    <label className="framed w-full">
                                        <input placeholder=" " {...register(`env.GUARD_KEY`)}  name="env[GUARD_KEY]" />
                                        <span>Ключ логин (32 chars)</span>
                                    </label>
                                </div>
                            </div>
                            <div className="flex items-center pb-10">
                                <div className="flex flex-1 mr-5">
                                    <div className="flex bg-yellow-200 w-10 rounded-l mt-1.5 items-center justify-center ">
                                        <SvgInbox classes="w-5 h-5 text-gray-500 font-extrabold" />
                                    </div>
                                    <label className="framed w-full">
                                        <input placeholder=" " {...register(`env.MAIL.address`)}  name="env[MAIL][address]" />
                                        <span>Имейли се изпращат от имейл: (info@giganot.com)</span>
                                    </label>
                                </div>
                                <div className="flex flex-1">
                                    <div className="flex bg-yellow-200 w-10 rounded-l mt-1.5 items-center justify-center ">
                                        <SvgInbox classes="w-5 h-5 text-gray-500 font-extrabold" />
                                    </div>
                                    <label className="framed w-full">
                                        <input placeholder=" " {...register(`env.MAIL.name`)}  name="env[MAIL][name]" />
                                        <span>Имейли се изпращат от името на: (Георги Иванов)</span>
                                    </label>
                                </div>
                            </div>

                            <Collapsible trigger="Формат дата, мултиезичност, база (порт), база (други)">
                                <div className="flex items-center pb-2">
                                    <div className="flex-1 mr-5">
                                        <label className="framed w-full">
                                            <input placeholder=" " {...register(`env.LANGS`)}  name="env[LANGS]" />
                                            <span>Езици на апа (en,bg,tr) изброени</span>
                                        </label>
                                    </div>
                                    <div className="flex-1">
                                        <label className="framed w-full">
                                            <input placeholder=" " {...register(`env.DATE_FORMAT`)}  name="env[DATE_FORMAT]" />
                                            <span>Дата формат (ДД.ММ.ГГГГ)</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="flex items-center pb-2">
                                    <div className="flex-1 mr-5">
                                        <label className="framed w-full">
                                            <input placeholder=" " {...register(`env.DB_PORT`)}  name="env[DB_PORT]" />
                                            <span>База данни порт (3306)</span>
                                        </label>
                                    </div>
                                    <div className="flex-1">
                                        <label className="framed w-full">
                                            <input placeholder=" " {...register(`env.DB_CHARSET`)}  name="env[DB_CHARSET]" />
                                            <span>База данни чарсет (не е задължително)</span>
                                        </label>
                                    </div>
                                </div>
                            </Collapsible>

                            <Collapsible trigger="Имейли">
                                <div className="flex items-center pb-2">
                                    <div className="flex-1 mr-5">
                                        <label className="framed w-full">
                                            <input placeholder=" " {...register(`env.MAIL.host`)}  name="env[MAIL][host]" />
                                            <span>Имейли хост (smtp.mailgun.org)</span>
                                        </label>
                                    </div>
                                    <div className="flex-1">
                                        <label className="framed w-full">
                                            <input placeholder=" " {...register(`env.MAIL.port`)}  name="env[MAIL][port]" />
                                            <span>Имейли порт (587)</span>
                                        </label>
                                    </div>
                                </div>

                                <div className="flex items-center pb-2">
                                    <div className="flex-1 mr-5">
                                        <label className="framed w-full">
                                            <input placeholder=" " {...register(`env.MAIL.encryption`)}  name="env[MAIL][encryption]" />
                                            <span>Имейли криптиране (tls): </span>
                                        </label>
                                    </div>
                                    <div className="flex-1 mr-5">
                                        <label className="framed w-full">
                                            <input placeholder=" " {...register(`env.MAIL.username`)}  name="env[MAIL][username]" />
                                            <span>Имейли логин потрбител (giganot):</span>
                                        </label>
                                    </div>
                                    <div className="flex-1">
                                        <label className="framed w-full">
                                            <input placeholder=" " {...register(`env.MAIL.password`)}  name="env[MAIL][password]" />
                                            <span>Имейли логин парола (yd7dhVBO0h1L):</span>
                                        </label>
                                    </div>
                                </div>
                            </Collapsible>

                            <div className="w-full">
                                <Collapsible trigger="Данни за фирмата">
                                    <div className="flex items-center pb-2">
                                        <div className="flex-1 mr-5">
                                            <label className="framed w-full">
                                                <input placeholder=" " {...register(`company_title`)}  name="company_title" />
                                                <span>Име на фирмата</span>
                                            </label>
                                        </div>
                                        <div className="flex-1 mr-5">
                                            <label className="framed w-full">
                                                <input placeholder=" " {...register(`email`)}  name="email" />
                                                <span>Имейл</span>
                                            </label>
                                        </div>
                                        <div className="flex-1">
                                            <label className="framed w-full">
                                                <input placeholder=" " {...register(`phone`)}  name="phone" />
                                                <span>Телефон</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="flex items-center pb-2">
                                        <div className="flex-1 mr-5">
                                            <label className="framed w-full">
                                                <input placeholder=" " {...register(`city`)}  name="city" />
                                                <span>Град</span>
                                            </label>
                                        </div>
                                        <div className="flex-1 mr-5">
                                            <label className="framed w-full">
                                                <input placeholder=" " {...register(`address`)}  name="address" />
                                                <span>Адрес</span>
                                            </label>
                                        </div>
                                        <div className="flex-1">
                                            <label className="framed w-full">
                                                <input placeholder=" " {...register(`mol`)}  name="mol" />
                                                <span>МОЛ</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="flex items-center pb-2">
                                        <div className="flex-1 mr-5">
                                            <label className="framed w-full">
                                                <input placeholder=" " {...register(`bulstat`)}  name="bulstat" />
                                                <span>Булстат</span>
                                            </label>
                                        </div>
                                        <div className="flex-1">
                                            <label className="framed w-full">
                                                <input placeholder=" " {...register(`zdds`)}  name="zdds" />
                                                <span>ЗДДС</span>
                                            </label>
                                        </div>
                                    </div>
                                </Collapsible>
                            </div>


                        </div>

                        <Collapsible trigger="Описание">

                            <div className="flex items-center pb-2 font-medium text-base">
                                Описание
                            </div>

                            <div className="w-full">
                                <Editor
                                  initialValue={project.description}
                                  ref={editorRef}
                                  init={{
                                    height: 550,
                                    menubar: false,
                                    relative_urls : false,
                                    mode: "textareas",
                                    paste_as_text: true,
                                    browser_spellcheck: true,
                                    contextmenu: false,
                                    object_resizing : true,
                                    valid_elements: "*[*]",
                                    fontsize_formats: "16px 18px 20px 22px 24px 30px 36px 48px",
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor textcolor pagebreak tabfocus directionality autosave',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code wordcount'
                                    ],
                                    toolbar:
                                      'undo redo | bold italic forecolor backcolor fontsizeselect | \
                                      alignleft aligncenter alignright alignjustify blockquote | \
                                      bullist numlist removeformat outdent indent | link insert media imageButton | code'
                                  }}
                                  onEditorChange={(value) => handleEditorChange(value)}
                                />
                            </div>
                        </Collapsible>
                    </div>

                </div>


                <div className="px-4 bg-gray-50 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-200 text-green-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
                        Запази
                    </button>
                </div>

            </form>
        </>
);
};

export default MainInfo;
