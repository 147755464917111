import React from 'react'
import { useDispatch, useSelector } from "react-redux";

//Components
import Layouts from './panels/layouts/Layouts';

export default function Dashboard({children,layoutRender,background,hideTopBar}) {

    const dashboard = useSelector(state => state.dashboard);

    if(layoutRender) {
        dashboard.layout = layoutRender;
    }
    else {
        dashboard.layout = "WithSidebar";
    }

    const Layout = Layouts[dashboard.layout];

    return (
        <>
            <Layout rendering={children} background={background} hideTopBar={hideTopBar} />
        </>
    );
}
