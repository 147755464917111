import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from 'react-select'
import Switch from "react-switch";

//Actions
import { getProject } from '../../../../../../actions/projects/clientsProjectAction'
import { getTask,editTask,getLatestTasks } from '../../../../../../actions/projects/clientsTasksAction'
import appsApi from '../../../../../../env/apis/projects/appsApi';

import filtersOptions from '../../../../../../env/apis/projects/data/filtersOptions';
const filtersChoose = [...filtersOptions];

const ModalTaskSettings = ( props ) => {

    const dispatch = useDispatch();

    const { clientInfo } = useSelector(state => state.clients);
    const { project  } = useSelector(state => state.clientsProject);
    const { task } = useSelector(state => state.clientsTasks);
    const [fromLatest, setFromLatest] = useState(task.fromLatest ? true : false);

    const [isImportant, setIsImportant] = useState(task.important ? true : false);
    const [isEmergency, setIsEmergency] = useState(task.emergency ? true : false);
    const [kind, setKind] = useState(task.kind);

    const handleImportant = (nextChecked) => {
        setIsImportant(nextChecked);
    };

    const handleEmergency = (nextChecked) => {
        setIsEmergency(nextChecked);
    };

    const handleKind = (value) => {
        setKind(value);
    };

    const onSubmit = async () => {
        await dispatch(editTask({
            "task" : task,
            "update" : {
                "important":isImportant ? 1 : 0,
                "emergency":isEmergency ? 1 : 0,
                "kind":kind
            }
        }));

        await dispatch(getTask({task}));
        await dispatch(getProject({"project":project._id}));
        if(fromLatest) {
            dispatch(getLatestTasks({"client":clientInfo._id}));
        }
    };


    useEffect(() => {
        if(filtersChoose[0].value == "") {
            filtersChoose.shift();
        }
    },[]);

    useEffect(() => {
        if(!kind) {
            setKind(filtersChoose[1]);
        }
    });

    useEffect(() => {
        onSubmit();
    }, [isImportant,isEmergency,kind]);

    return (
        <>
            <div className="antialiased w-full mt-10 px-4 py-3 rounded bg-gray-50">
                <div className="flex">
                    <div className="text-gray-600 flex flex-col mr-7">
                        <div className="font-light text-gray-700 text-sm flex-2">
                            Важна:
                        </div>
                        <div className="flex-1 mt-1">
                            <Switch onChange={handleImportant} checked={isImportant} height={20} width={45} />
                        </div>
                    </div>
                    <div className="text-gray-600 flex flex-col mr-7">
                        <div className="font-light text-gray-700 text-sm flex-2">
                            Спешна:
                        </div>
                        <div className="flex-1 mt-1">
                            <Switch onChange={handleEmergency} checked={isEmergency} height={20} width={45} />
                        </div>
                    </div>
                    <div className="text-gray-600 w-44 flex flex-col mr-7">
                        <div className="w-full mt-3">
                            <ReactSelect
                              className="w-full text-sm"
                              defaultValue={kind}
                              name="kind"
                              options={filtersChoose}
                              isSearchable={false}
                              onChange={handleKind}
                            />
                        </div>
                    </div>
                    {props.children}
                </div>


            </div>
        </>
    );
}

export default ModalTaskSettings;
