import React, { useState,useEffect,useRef } from "react";
import { usePopper } from 'react-popper';
import { Menu, Transition } from "@headlessui/react";

//Styles
import { SvgAdd,SvgPlus,SvgDots } from '../../../parts/blocks/Svg';

export const TaskDropDown = ({add,remove}) => {

    return (
        <div className="flex items-center justify-center z-10">
           <div className="relative inline-block text-left">
             <Menu>
               {({ open }) => (
                 <>
                   <span className="rounded-md">
                     <Menu.Button className="justify-center w-full pr-1 pl-4 text-sm text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800">
                       <SvgDots />
                     </Menu.Button>
                   </span>

                   <Transition
                     show={open}
                     enter="transition ease-out duration-100"
                     enterFrom="transform opacity-0 scale-95"
                     enterTo="transform opacity-100 scale-100"
                     leave="transition ease-in duration-75"
                     leaveFrom="transform opacity-100 scale-100"
                     leaveTo="transform opacity-0 scale-95"
                   >
                     <Menu.Items static
                       className="absolute right-0 w-56 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                     >
                       <div className="px-4 py-3">
                         <p className="text-sm font-light leading-5 text-gray-900 truncate text-center">
                           действия
                         </p>
                       </div>

                       <div className="py-1">
                         <Menu.Item>
                           {({ active }) => (
                             <button
                               onClick={add}
                               className={`${
                                 active
                                   ? "bg-gray-100 text-gray-900"
                                   : "text-gray-700"
                               } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                             >
                               Нова карта
                             </button>
                           )}
                         </Menu.Item>
                       </div>

                       <div className="py-1">
                         <Menu.Item>
                           {({ active }) => (
                             <button
                               onClick={remove}
                               className={`${
                                 active
                                   ? "bg-gray-100 text-gray-900"
                                   : "text-gray-700"
                               } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                             >
                               Архивирай
                             </button>
                           )}
                         </Menu.Item>
                       </div>
                     </Menu.Items>
                   </Transition>
                 </>
               )}
             </Menu>
           </div>
         </div>
    );
}
