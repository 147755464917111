import React, { useEffect } from "react";
import { loginApi } from './../../actions/authActions'
import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';

import urls from './../../env/urls';
import LeftImage from './panels/LeftImage';

export default function Login() {

  const { register, handleSubmit, formState: { errors } } = useForm({shouldFocusError: true});
  const onSubmit = (data) => {
      dispatch(loginApi(data));
  };

  const dispatch = useDispatch();

  return (
    <div className="min-h-screen bg-white flex">
      <LeftImage />
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>

        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Вход в <span className="logo-color">Giganot</span></h2>
            <p className="mt-2 text-sm text-gray-600">
               Или{' '}
               <Link to={urls.register} className="font-medium text-indigo-600 hover:text-indigo-500">
                 се регистрирай тук
               </Link>
             </p>
          </div>

          <div className="mt-8">

            <div className="mt-6">
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-6" noValidate>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                     Имейл адрес
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      type="email"
                      name="email"
                      {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    {errors.email?.type === 'required' &&
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      Имейл е задължително.
                    </p>
                    }
                    {errors.email?.type === 'pattern' &&
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      Невалиден имейл.
                    </p>
                    }
                  </div>
                </div>

                <div className="space-y-1">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Парола
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      {...register('password', { required: true })}
                      type="password"
                      name="password"
                      autoComplete="current-password"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    {errors.password?.type === 'required' &&
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      Задължително поле.
                    </p>
                    }
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="rememberme"
                      name="rememberme"
                      type="checkbox"
                       {...register('rememberme')}
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <label htmlFor="rememberme" className="ml-2 block text-sm text-gray-900">
                      Запомни ме
                    </label>
                  </div>

                  <div className="text-sm">
                    <Link to={urls.password} className="font-medium text-indigo-600 hover:text-indigo-500">
                      Забравена парола?
                    </Link>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Вход
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
);
}
