import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from 'react-select'
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import ModalTaskTimersTooltip from './ModalTaskTimersTooltip';
import ModalTaskDueDate from './ModalTaskDueDate';
import _max from 'lodash.max';
import _find from 'lodash.find';

//Actions
import { getTask,timer,deleteTimer,updateTimer } from '../../../../../../actions/projects/clientsTasksAction'
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { getProject } from '../../../../../../actions/projects/clientsProjectAction'

//Styles
import { SvgPlus,SvgSpin,SvgMinus,SvgImage,SvgClock } from '../../../../parts/blocks/Svg';

const ModalTaskTimers = ( ) => {

    const dispatch = useDispatch();

    const { task } = useSelector(state => state.clientsTasks);

    const { register, control, reset, getValues, handleSubmit } = useForm({
        defaultValues: task
    });

    const [startDate, setStartDate] = useState(new Date());
    const [saveAnimationAdd, setSaveAnimationAdd] = useState(false);
    const [saveAnimationRemove, setSaveAnimationRemove] = useState(false);


    const {
      fields: timerFields,
      append: timerAppend,
      prepend: timerPrepend,
      remove: timerRemove,
      move  : timerMove
    } = useFieldArray({ control, name: "timers" });

    const onEnter = (e) => {
        if(e.key == "Enter") {
            e.currentTarget.blur();
        }
    }


    const saveData = () => {
        onSubmit(getValues());
    }

    const removeTimer = async (data) => {
        if(!saveAnimationAdd) {
            setSaveAnimationRemove(true);
            await dispatch(deleteTimer(data));
            const newTask = await dispatch(getTask({task}));
            await reset(newTask);
            dispatch(getProject({"project":task.projectId}));
            setSaveAnimationRemove(false);
        }

    };

    const addTimer = async (data) => {
        if(!saveAnimationAdd) {
            setSaveAnimationAdd(true);
            await dispatch(updateTimer({
                "update":[{seconds: 1800}],
                task
            }));
            const newTask = await dispatch(getTask({task}));
            await reset(newTask);
            dispatch(getProject({"project":task.projectId}));

        }

        setSaveAnimationAdd(false);
    };

    const onSubmit = async (data) => {
        if(data) {
            const timersLength = data.timers && data.timers.length > 0 ? true : false;
            if(data.timers && timersLength > 0) {
                let seconds = 0;

                const newTimers = data.timers.map((timer) => {
                    seconds = 0;
                    if(timer.time) {
                        seconds = moment.duration(timer.time).asSeconds();
                    }

                    return {...timer,"seconds":seconds};
                });

                await dispatch(updateTimer({
                    "update":newTimers,
                    task
                }));

                const newTask = await dispatch(getTask({task}));

                await reset(newTask);
                dispatch(getProject({"project":task.projectId}));

            }


        }
    };

    useEffect(() => {
        if(task.timers.length != timerFields.length) {
            reset(task);
        }
        else {
            task.timers.map((item,index) => {
                if(!_find(timerFields,item)) {
                    reset(task);
                }
            });
        }
    });

    return (
        <div>
            <div className="antialiased w-full pb-2">

                <div className="flex flex-inline mb-2 items-center">
                    <h3 className="mb-1 text-lg font-semibold text-gray-900">Времена</h3>
                    <div className="flex flex-grow flex-row-reverse">
                        <ModalTaskTimersTooltip label="С-3" longLabel="3-та седмица назад:" time={moment.utc(task.timerLastLastLastWeek*1000).format("HH:mm")} />
                        <ModalTaskTimersTooltip label="С-2" longLabel="2-ра седмица назад:" time={moment.utc(task.timerLastLastWeek*1000).format("HH:mm")} />
                        <ModalTaskTimersTooltip label="С-1" longLabel="Миналата седмица:" time={moment.utc(task.timerLastWeek*1000).format("HH:mm")} />
                        <ModalTaskTimersTooltip label="Общо" longLabel="Общо:" time={moment.utc(task.timerTotal*1000).format("HH:mm")} />
                        <ModalTaskTimersTooltip label="Месец" longLabel="За месеца:" time={moment.utc(task.timerMonthly*1000).format("HH:mm")} />
                        <ModalTaskTimersTooltip label="Седмица" longLabel="Тази седмицата:" time={moment.utc(task.timerWeekly*1000).format("HH:mm")} />
                        <ModalTaskTimersTooltip className="text-xs" label="Днес" longLabel="За деня:" time={moment.utc(task.timerDay*1000).format("HH:mm")} />
                        {task.timerTotal > 0 && <SvgClock classes="w-4 h-4 mr-2 text-gray-500" />}
                    </div>

                </div>

                <div className="flex flex-row">

                    <div className="flex w-4/12 bg-gray-50 border border-gray-200 p-4 mr-4">
                        <ModalTaskDueDate />
                    </div>

                    <div className="flex flex-grow flex-col w-8/12">
                        <ul className="flex flex-wrap">
                            {timerFields.map((item, index) => {
                                if(item.time) {
                                    return (
                                        <li key={item.id} className="items-center py-2 px-2 mr-3 mb-3 rounded w-62 flex flex-col bg-gray-200 border border-gray-300">

                                            <input type="hidden" {...register(`timers[${index}]._id`)} defaultValue={item._id} name={`timers[${index}]._id`} />
                                            <div className="flex w-full">
                                                <Controller
                                                    name={`timers[${index}].time`}
                                                    control={control}
                                                    render={({ field }) => (

                                                        <TimePicker
                                                            showSecond={false}
                                                            placeholder="време"
                                                            onChange={(e) => {field.onChange(e);}}
                                                            minuteStep={15}
                                                            allowEmpty={false}
                                                            defaultValue={moment.utc(item.time*1000)}
                                                            onClose={(e) => saveData(e)}
                                                          />

                                                    )}
                                                />
                                            </div>

                                            <div className="flex w-full px-1 mt-1">
                                                <div className="flex text-xs text-center pt-1 text-gray-500 font-medium">
                                                    {moment(item.end).format("DD/MM/YYYY, HH:mm")}
                                                </div>
                                                <div className="flex flex-grow flex-row-reverse text-xs text-center text-gray-500 font-medium">
                                                    <button type="button" className="flex mt-0.5 pt-1 pb-1 items-center justify-center h-5 w-5 rounded bg-gray-300 focus:outline-none"
                                                        onClick={() => removeTimer(item)}
                                                        >
                                                        {!saveAnimationRemove && <SvgMinus classes="w-4 h-4 text-gray-600" />}
                                                        {saveAnimationRemove && <SvgSpin classes="h-4 w-4 text-gray-600" />}
                                                    </button>
                                                </div>

                                            </div>

                                        </li>
                                    );
                                }
                            })}
                        </ul>

                        <div className="flex">
                            <button type="button" className="flex text-sm font-normal items-center bg-gray-300 text-gray-700 justify-center py-2 px-6  rounded focus:outline-none"
                                onClick={() => addTimer()}
                                >
                                    Добави timer
                                    {!saveAnimationAdd && <SvgPlus classes="w-5 h-5" />}
                                    {saveAnimationAdd && <SvgSpin classes="h-5 w-5 ml-2 text-gray-600" />}
                            </button>
                        </div>
                    </div>

                </div>


            </div>

        </div>
    );
}

export default ModalTaskTimers;
