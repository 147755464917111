import { useRef, useState, useEffect } from "react";
import ResizeObserver from "resize-observer-polyfill";

const initialState = { width: 0, height: 0 };

const useDimension = (ref) => {
  const [dimensions, setdDimensions] = useState(initialState);
  const resizeObserverRef = useRef(null);

  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver((entries = []) => {
      entries.forEach((entry) => {
        const { width, height } = entry.contentRect;
        const widthScreen = window.innerWidth;
        const heightScreen = window.innerHeight;
        const widthNoSidebar = widthScreen - 270;
        const heightTopBar = heightScreen - height - 62;


        setdDimensions({ width, height, widthScreen, heightScreen, widthNoSidebar, heightTopBar });
      });
    });
    if (ref.current) resizeObserverRef.current.observe(ref.current);
    return () => {
      if (resizeObserverRef.current) resizeObserverRef.current.disconnect();
    };
  }, [ref]);

  const resizer = () => {
      if(ref && ref.current) {
          resizeObserverRef.current.observe(ref.current);
      }
  }

  useEffect(() => {
      window.addEventListener('resize', resizer);
  }, []);

  return dimensions;
};

export default useDimension;
