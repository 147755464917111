import React, { useState, useCallback } from 'react';
import { useDispatch } from "react-redux";
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'

import SelectCell from '../../parts/table/Select'
import InputCell from '../../parts/table/Input'
import CheckCell from '../../parts/table/Checkbox'
import SettingsCell from '../../parts/table/Settings'
import DeleteButton from '../../parts/table/DeleteButton'
import Actions from '../../parts/table/Actions'
import SelectAjaxCell from '../../parts/table/SelectAjax'
import SortHandle from '../../parts/table/SortHandle'
import arrayMove from '../../../../hooks/arrayMove.js'

const SortableCont = SortableContainer(({ children }) => {
  return <tbody>{children}</tbody>;
});

const SortableItem = SortableElement(props => <Trs {...props} />);

const Tds = ( { row, column, ic, defaultOptions, deleteAction, updateAction, openCloseModalAction,actionUrl  } ) => {
  const key = column.key;
  let classes = "p-3 text-sm w-full text-gray-800 text-center";
  classes += column.align ? " text-"+column.align+" " : " text-left ";

  return (
      <>
          <td className={classes} style={{ width: column.width + "px" }}>
              {(() => {
                  if(key == "actions") {
                      return <Actions row={row} mainUrl={actionUrl} />
                  }
                  else if(key == "action") {
                      return <DeleteButton row={row} deleteBlock={deleteAction} />
                  }
                  else if(key == "sort") {
                      return <SortHandle />
                  }
                  else if(key.includes("_check")) {
                      return <CheckCell row={row} column={column.dataKey} updateBlocksFieldAction={updateAction} />
                  }
                  else if(key.includes("_input")) {
                      return <InputCell row={row} column={column.dataKey} updateBlocksFieldAction={updateAction} />
                  }
                  else if(key.includes("_selectajax")) {
                      return <SelectAjaxCell row={row} column={column.dataKey} defaultOptions={defaultOptions} updateBlocksFieldAction={updateAction} />
                  }
                  else if(key.includes("_select")) {
                      return <SelectCell row={row} column={column.dataKey} updateBlocksFieldAction={updateAction} />
                  }
                  else if(key.includes("settings")) {
                      return <SettingsCell row={row} openCloseModalAction={openCloseModalAction} />
                  }
                  else if(!(row[column] instanceof Object)) {
                      return row[key];
                  }
                })()}

          </td>

      </>
  );
};

const Trs = ( { columns, row, i, defaultOptions, deleteAction, updateAction, openCloseModalAction, actionUrl  } ) => {
  return (
      <tr className={i % 2 === 0 ? 'bg-white lg:hover:bg-gray-100 mb-10' : 'bg-gray-50 lg:hover:bg-gray-200 mb-10'}>
          {columns.map((column,ic) => {
            return (
               <Tds key={'thrd'+ic+row.id} row={row} column={column} ic={ic} defaultOptions={defaultOptions} deleteAction={deleteAction} updateAction={updateAction} actionUrl={actionUrl} openCloseModalAction={openCloseModalAction} />
            )
          })}
      </tr>
  );
};



const Table = ( { columns, data, defaultOptions, deleteAction, updateAction, openCloseModalAction, actionUrl, reorderBlocks } ) => {

  const dispatch = useDispatch();

  const onSortEnd = useCallback(({newIndex,oldIndex},data) => {
      const reordered = arrayMove(data, oldIndex, newIndex);
      dispatch(reorderBlocks(reordered));
  }, []);

  return (
      <>
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8" style={{paddingBottom:'250px'}}>
                <div className="shadow  border-b border-gray-200 sm:rounded-lg">
                  <table className="border-collapse min-w-full divide-y divide-gray-200">
                     <thead className="shadow bg-gray-100">
                        <tr>
                           {columns.map((column,i) => {
                             let classes = "px-6 py-3 text-left text-xs font-medium text-gray-900  font-bold ";
                             classes += column.align ? " text-"+column.align+" " : " text-left ";

                             return (
                                 <th key={'thk'+i} className={classes}>
                                     {column.title}
                                 </th>
                             )
                           })}
                       </tr>
                     </thead>
                     <SortableCont
                       onSortEnd={(sortInfo) => onSortEnd(sortInfo,data)}
                       axis="y"
                       lockAxis="y"
                       lockToContainerEdges={true}
                       lockOffset={["30%", "50%"]}
                       useDragHandle={true}
                     >
                             {data.map((row, i) => {
                                 return (
                                     <SortableItem
                                       key={'thr'+i+row.id}
                                       index={i}
                                       columns={columns}
                                       row={row}
                                       i={i}
                                       defaultOptions={defaultOptions}
                                       deleteAction={deleteAction}
                                       updateAction={updateAction}
                                       openCloseModalAction={openCloseModalAction}
                                       actionUrl={actionUrl}
                                     />
                                 )
                             })}
                     </SortableCont>
                   </table>
               </div>
              </div>
            </div>
          </div>
      </>
  );
};

export default Table;
