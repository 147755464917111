import React, { useState,useEffect,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { toast } from 'react-toastify';
import Switch from "react-switch";
import ReactSelect from 'react-select'
import { Editor } from '@tinymce/tinymce-react';
import { SearchIcon } from '@heroicons/react/solid'
import { SvgSpin } from '../../../parts/blocks/Svg';
import axios from 'axios';

//Actions
import { updateClientAction } from '../../../../../actions/projects/clientsListAction'

const options = [
  { value: '0', label: 'Тип доход'},
  { value: 'monthly', label: 'Месечен'},
  { value: 'weekly', label: 'Седмичен'},
  { value:'onetime', label: 'Еднократен'}
]

const MainInfo = () => {

    const dispatch = useDispatch();
    const { clientInfo } = useSelector(state => state.clients);

    const [isActive, setIsActive] = useState(clientInfo.isActive ? true : false);
    const [isImportant, setIsImportant] = useState(clientInfo.isImportant ? true : false);
    const [files, setFiles] = useState([]);
    const [searchingFiles, setSearchingFiles] = useState(false);

    const editorRef = useRef();

    const handleActivate = nextChecked => {
        setIsActive(nextChecked);
    };

    const handleImportant = nextChecked => {
        setIsImportant(nextChecked);
    };

    const handleEditorChange = (value) => {
    };

    let timeOutId = null;
    const searchImages = (value) => {
        setSearchingFiles(true);

        clearTimeout(timeOutId)
        timeOutId = setTimeout(() => findImage(), 600);
    };

    const findImage = async (e) => {
        const search = getValues('search');
        const results = await axios.get('https://api.unsplash.com/search/photos?client_id=G2Wvtyly-BYq288sj8a_cIlLtdIddRdJtOKvb3S6RJg', {
            params: { query: search, per_page: 30, content_filter: "high" }
        });

        setFiles(results.data.results);
        setSearchingFiles(false);
    }

    const { register, control, getValues, handleSubmit } = useForm({
        defaultValues: clientInfo
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "manual"
    });

    let background = clientInfo.background ? clientInfo.background : {};

    const setBackgroundImage = (image) => {
        background = image.urls;
        onSubmit(getValues());
    };

    const notify = () => toast.success("Данните са запазени!");


    const onSubmit = async (data) => {
        if(clientInfo && clientInfo.id && data) {
            data.isActive = isActive;
            data.isImportant = isImportant;
            data.description = editorRef.current.currentContent;
            data.background = background;

            const newState = {...clientInfo};
            for (var x in data) {
                newState[x] = data[x] ? data[x] : "";
            }

            dispatch(updateClientAction({newState:newState,data:data,row:clientInfo}));


            notify();
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="flex mt-5 flex-inline items-center bg-gray-50 px-4 pъ-5 sm:p-6 sm:pb-4">
                    <div className="text-xl leading-6 font-medium text-gray-900">
                        Основна информация&nbsp;
                        <small className="text-sm text-gray-600">
                            (клиент: {clientInfo.name})
                        </small>
                    </div>
                    <div className="flex flex-grow flex-row-reverse">

                        <button type="submit" className="w-full inline-flex flex items-center justify-center rounded-md border border-transparent ml-10 px-4 py-2 bg-green-200 shadow text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:w-auto sm:text-sm">
                            <svg className="w-6 h-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </button>

                        <div className="text-gray-600 flex items-center">
                            <div className="font-medium text-gray-700 text-base flex-2 mr-4">
                                Активен:
                            </div>
                            <div className="flex-1 mt-1">
                                <Switch onChange={handleActivate} checked={isActive} />
                            </div>
                        </div>

                        <div className="text-gray-600 flex items-center mr-10">
                            <div className="font-medium text-gray-700 text-base flex-2 mr-4">
                                Важен:
                            </div>
                            <div className="flex-1 mt-1">
                                <Switch onChange={handleImportant} checked={isImportant} />
                            </div>
                        </div>

                        <div className="text-gray-600 flex items-center mr-10">
                            <div className="flex-1 mt-1">
                                <label className="framed w-full">
                                    <input placeholder=" " {...register('weight')} name="weight" />
                                    <span>Тегло</span>
                                </label>
                            </div>
                        </div>

                    </div>
                </div>


                <div className="bg-gray-100 px-4 py-3 sm:px-6 pt-1">
                    <div className="w-full">
                        <div className="w-full z-40 relative">
                            <div className="flex items-center pb-2">
                                <div className="flex-1 mr-5">
                                    <label className="framed w-full">
                                        <input placeholder=" " {...register('name')} name="name" />
                                        <span>Име на фирма</span>
                                    </label>
                                </div>
                                <div className="flex-1">
                                    <label className="framed w-full">
                                        <input placeholder=" " {...register('phone')} name="phone" />
                                        <span>Телефон</span>
                                    </label>
                                </div>
                            </div>
                            <div className="flex items-center pb-2">
                                <div className="flex-1 mr-5">
                                    <label className="framed w-full">
                                        <input placeholder=" " {...register('email')} name="email" />
                                        <span>Имейл</span>
                                    </label>
                                </div>
                                <div className="flex-1">
                                    <label className="framed w-full">
                                        <input placeholder=" " {...register('city')} name="city" />
                                        <span>Град</span>
                                    </label>
                                </div>
                            </div>
                            <div className="flex items-center pb-2">
                                <div className="flex-1 mr-5">
                                    <label className="framed w-full">
                                        <input placeholder=" " {...register('address')} name="address" />
                                        <span>Адрес</span>
                                    </label>
                                </div>
                                <div className="flex-1">
                                    <label className="framed w-full">
                                        <input placeholder=" " {...register('bulstat')} name="bulstat" />
                                        <span>Булстат</span>
                                    </label>
                                </div>
                            </div>
                            <div className="flex items-center pb-2">
                                <div className="flex-1 mr-5">
                                    <label className="framed w-full">
                                        <input placeholder=" " {...register('mol')} name="mol" />
                                        <span>МОЛ</span>
                                    </label>
                                </div>
                                <div className="flex-1">
                                    <label className="framed w-full">
                                        <input placeholder=" " {...register('zdds')} name="zdds" />
                                        <span>ЗДДС</span>
                                    </label>
                                </div>
                            </div>
                            <div className="flex items-center pb-2">
                                <div className="flex-1 mr-5">

                                    <Controller
                                      render={({ field }) => (<ReactSelect {...field} options={options} placeholder="Тип доход..." className="w-full text-sm" />)}
                                      name={`income_type`}
                                      control={control}
                                    />

                                </div>
                                <div className="flex-1">
                                    <label className="framed w-full">
                                        <input placeholder=" " {...register('income')} name="income" />
                                        <span>Очакван доход</span>
                                    </label>
                                </div>
                            </div>
                        </div>



                        <div className="flex items-center pb-2 font-medium text-base">
                            Описание
                        </div>

                        <div className="w-full">
                            <Editor
                              initialValue={clientInfo.description}
                              ref={editorRef}
                              init={{
                                height: 550,
                                menubar: false,
                                relative_urls : false,
                                mode: "textareas",
                                paste_as_text: true,
                                browser_spellcheck: true,
                                contextmenu: false,
                                object_resizing : true,
                                valid_elements: "*[*]",
                                fontsize_formats: "16px 18px 20px 22px 24px 30px 36px 48px",
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor textcolor pagebreak tabfocus directionality autosave',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code wordcount'
                                ],
                                toolbar:
                                  'undo redo | bold italic forecolor backcolor fontsizeselect | \
                                  alignleft aligncenter alignright alignjustify blockquote | \
                                  bullist numlist removeformat outdent indent | link insert media imageButton | code'
                              }}
                              onEditorChange={(value) => handleEditorChange(value)}
                            />
                        </div>


                    </div>

                </div>


                <div className="px-4 bg-gray-50 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-200 text-green-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
                        Запази
                    </button>
                </div>

            </form>

            <div class="bg-white overflow-hidden sm:rounded-lg mt-10 mb-10 sm:shadow">

              <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Снимка за фон на проекта
                </h3>
                <div class="mt-3 text-sm text-gray-500">
                    <div className="relative">
                      <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                        <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                      <input
                        id="search"
                        className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Търси"
                        autoComplete="off"
                        {...register('search')}
                        onKeyDown={(e) => searchImages(e)}
                        type="search"
                      />
                    </div>
                </div>
              </div>
                  {searchingFiles &&
                      <div className="px-10 py-3 bg-gray-100 text-base font-semibold flex flex-row items-center">
                          <SvgSpin classes="text-gray-700 w-6 h-6 mr-3" />
                          <span>търся снимки</span>
                      </div>
                  }
                {files && files.length > 0 ?
                    <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 p-10">
                        {files.map((file) => (
                          <li key={file.id} className="relative flex flex-col overflow-hidden">
                            <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                              <img src={file.urls.small} alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
                            </div>
                            <div className="flex flex-row items-center">
                                <div className="flex mr-5">
                                    <button
                                      type="button"
                                      onClick={(e) => setBackgroundImage(file)}
                                      className="inline-flex items-center px-3 py-2 mt-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                      Избери
                                    </button>
                                </div>
                                <div className="flex-col">
                                    <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">{file.alt_description}</p>
                                    <p className="block text-xs font-medium text-gray-500 pointer-events-none">Размер оригинал - {file.width}x{file.height}px</p>
                                </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                :
                    <>
                        {searchingFiles ?
                            <div></div>
                            :
                            <div className="px-10 py-3 bg-gray-100 text-base font-semibold">Не бяха намерени резултати</div>
                        }
                    </>

                }


             </div>
        </>
);
};

export default MainInfo;
