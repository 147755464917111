//Components
import WithSidebar from './WithSidebar';
import TopSidebar from './TopSidebar';

const Components = {};

Components['WithSidebar'] = WithSidebar;
Components['TopSidebar'] = TopSidebar;

export default Components;
