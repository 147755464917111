import React from 'react'

//Components
import TopBar from './../header/TopBar';
import ActionBar from './../content/ActionBar';
import DemoContent from './../content/DemoContent';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Dasboard({ rendering, background, hideTopBar }) {
  const fullWidth = true;

  return (
    <>
        <div className="relative h-screen flex overflow-hidden  bg-gray-200" style={{background: (background && background.full) ? `url(${background.full}) center center` : 'none'}}>
          <div className="flex flex-col w-0 flex-1">

            {!hideTopBar && <TopBar />}

            <div
                className={classNames(
                  fullWidth ? 'overflow-y-auto focus:outline-none' : 'max-w-7xl mx-auto px-2 sm:px-4 lg:px-8 overflow-y-auto focus:outline-none',
                  ''
                )}
            >
                <main className="flex-1 relative focus:outline-none">
                  <div className={!hideTopBar ? "pt-2 pb-7" : "pt-2 pb-7"}>
                    <div className="mx-auto px-4 sm:px-12 lg:px-12">
                      <div className="xl:col-span-2 xl:pr-8">
                        {rendering}
                      </div>
                    </div>
                  </div>
                </main>
            </div>
          </div>
        </div>
    </>
  );
}
