import React, { useState, useEffect, useRef } from "react";
import { Modal } from 'react-responsive-modal';
import Switch from "react-switch";
import ReactSelect from 'react-select'
import AsyncSelect, { makeAsyncSelect } from 'react-select/async';
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { SvgPlus,SvgSpin,SvgMinus,SvgImage,SvgOk } from '../../parts/blocks/Svg';

//Actions
import { openCloseModalAction, updateBlocksAction, searchApps, drawFieldsApps, getAppsOptions } from '../../../../actions/projects/appsSettingsAction'

const options = [
  { value: 'search', label: 'В търсачката'},
  { value: 'filter', label: 'Филтрира данни'},
  { value:'edit', label: 'Редакция'},
  { value:'sortable', label: 'Сортиране'}
]

const optionsValidation = [
  { value: 'required', label: 'Задължително'}
]

const optionsImageCut = [
  { value: '1', label: 'Точен кроп'},
  { value: '2', label: 'Пропорционално оразмеряване'},
  { value: '3', label: 'Кропване без премахване'}
]

const ModalExp = () => {

    const dispatch = useDispatch();
    const { openedModal, row, appOptions, appsList, clearNext } = useSelector(state => state.appsSettings);

    const [usesDatabase, setUsesDatabase] = useState(row.settings.usesDatabase);
    const [showView, setShowView] = useState(row.settings.showView);
    const [showList, setShowList] = useState(row.settings.showList);
    const [isImageField, setIsImageField] = useState(null);

    const [asyncSelectValue, setAsyncSelectValue] = useState(row.settings.database && row.settings.database.app ? row.settings.database.app : {});

    const refSelect1 = useRef(null);
    const refSelect2 = useRef(null);
    const refSelect3 = useRef(null);
    const refSelect4 = useRef(null);
    const refSelect5 = useRef(null);

    var defaultSubFields = [];
    if(asyncSelectValue && asyncSelectValue.fields && appOptions.length === 0) {
        defaultSubFields = asyncSelectValue.fields;
    }
    else {
        defaultSubFields = appOptions;
    }

    const isFile = row.type.value == "file" ? true : false;
    const isTags = row.type.value == "tags" ? true : false;
    const isMaps = row.type.value == "maps" ? true : false;
    const isSubForm = row.type.value == "subform" ? true : false;

    const isImage = ((row.type.value == "file" && row.settings && row.settings.file  && row.settings.file.type == "image") || isImageField) ? true : false;

    const handleChange = nextChecked => {
        setUsesDatabase(nextChecked);
    };

    const handleShowView = () => {
        setShowView(value => !value);
    };

    const handleShowList = () => {
        setShowList(value => !value);
    };

    // if(clearNext) {
    //     row.settings.database.connection = {};
    //     row.settings.database.options = {};
    // }

    const { register, control, reset, handleSubmit } = useForm({
        defaultValues: row.settings
    });

    const { fields, append, prepend, remove } = useFieldArray({
        control,
        name: "manual"
    });

    const {
      fields: fileFields,
      append: fileAppend,
      prepend: filePrepend,
      remove: fileRemove
   } = useFieldArray({ control, name: "file[images]" });

   const addRow = (fileFields) => {

       const characters       = 'acdefgijklnopqrtuvwxyz';
       const charactersLength = characters.length;
       const chr = characters.charAt(Math.floor(Math.random() * charactersLength));

      if(fileFields.length == 0) {
          fileAppend({ width: "200", height: "200", folder: "s", quality: "90",cut: 2 });
      }
      else if(fileFields.length == 1) {
          fileAppend({ width: "400", height: "400", folder: "m", quality: "90",cut: 2 });
      }
      else if(fileFields.length == 2) {
          fileAppend({ width: "900", height: "900", folder: "b", quality: "90",cut: 2 });
      }
      else if(fileFields.length == 3) {
          fileAppend({ width: "1920", height: "1080", folder: "h", quality: "90",cut: 2 });
      }
      else {
          fileAppend({ width: "600", height: "600", folder: chr, quality: "90",cut: 2 });
      }
   }

   const removeRow = (index) => {
       fileRemove(index);
   };

    const onSubmit = async (data) => {
        if(row && row.id && data) {
            data.usesDatabase = usesDatabase;
            data.showView = showView;
            data.showList = showList;

            if(!data.database) {
                data.database = {};
            }

            data.database.app = asyncSelectValue;

            if(data.database.app) {
                data.database.app = {
                 "label" : data.database.app.label,
                 "table" : data.database.app.table
                };
            }

            if(data.file && data.file.module) {
                data.file.module = {"label":data.file.module.label,"value":data.file.module.value}
            }

            if(data.subform && data.subform.module) {
                data.subform.module = {"label":data.subform.module.label,"value":data.subform.module.value,"table":data.subform.module.table,"filename":data.subform.module.filename}
            }

            await dispatch(updateBlocksAction({row,data}));
            closeModal(row);
        }
    };

    const closeModal = (row) => {
        dispatch(openCloseModalAction({ "openClose":false, row }));
    }

    const handleAppsSearch = (e) => {
        const fields = [];
        if(e.fields) {
            e.fields.forEach((v) => {
                fields.push({"label":v.label,"value":v.title_in_db});
            });
        }
        setAsyncSelectValue({value:e.value,"label":e.label,"table":e.table,"fields":fields});
        e.clearNext = true;
        reset({"database[connection]":"","database[options]":"","subform[connection]":""});

        dispatch(drawFieldsApps(e));
    }

    useEffect(async () => {
        dispatch(getAppsOptions({"clearNext":false}));
    }, []);

    return (
        <>
        <Modal
            open={openedModal}
            onClose={() => closeModal(row)}
            center
            classNames={{
                modal: 'text-center inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl bg-gray-900 sm:1/3 lg:w-2/3',
            }}
            >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4 w-full">
                    <h3
                        className="text-xl leading-6 font-medium text-gray-900 w-full"
                        id="modal-headline">
                        Редакция на поле &nbsp;
                        <small className="text-sm text-gray-600">
                            (ID: {row.id}, TITLE: {row.title_in_db})
                        </small>
                    </h3>
                </div>

                {isFile &&
                    <div className="bg-gray-100 px-4 py-3 sm:px-6 pt-1">
                        <h3 className="text-base leading-6 font-medium text-gray-700 w-full py-3" >
                            Настройки на файловете
                        </h3>

                        <div className="flex items-center pb-2">
                            <div className="flex-1 mr-5">
                                <small className="text-gray-500 mt-1 mb-1 float-left px-2 text-xs">
                                    стандартно (8000000 байта -> 8MB)
                                </small>
                                <label className="framed w-full">
                                    <input placeholder=" " {...register('file.maxsize')} name="file[maxsize]" />
                                    <span>Максимален размер </span>
                                </label>
                            </div>
                            <div className="flex-1">
                                <small className="text-gray-500 mt-1 mb-1 float-left px-2 text-xs">
                                    стандартно (2000000 байта -> 2MB)
                                </small>
                                <label className="framed w-full">
                                    <input placeholder=" " {...register('file.chunks')} name="file[chunks]" />
                                    <span>Размер парчета</span>
                                </label>
                            </div>
                        </div>
                        <div className="flex items-center pb-2">
                            <div className="flex-1 mr-5">
                                <span className="text-sm">Модул за редакция на информация</span>
                                <Controller
                                    name={`file[module]`}
                                    control={control}
                                    render={({ field }) => (
                                        <AsyncSelect  defaultOptions={appsList} defaultValue={field.value} {...field} isClearable loadOptions={searchApps} cacheOptions onChange={(e) => { field.onChange(e); }} className="w-full text-sm" />
                                    )}
                                />
                            </div>
                            <div className="flex-1">
                                <small className="text-gray-500 mt-1 float-left px-2 text-xs">
                                    .jpeg, .jpg, .png, .txt, .xls, .xlsx, .doc, .pdf, .docx, .ppt, .pptx, .mp4
                                </small>
                                <label className="framed w-full">
                                    <input placeholder=" " {...register('file.extensions')} name="file[extensions]" />
                                    <span>Позволени разширения</span>
                                </label>
                            </div>
                        </div>
                        <div className="flex items-center pb-2">
                            <div className="flex-1">
                                <div className="flex flex-row items-center text-base leading-6 font-bold text-gray-700 w-full py-3" >
                                    Тип файлове:

                                    <div className="flex flex-row ml-4 items-center">
                                        <div>
                                            <input id="fileMany" name="file[allow_many]" {...register('file.allow_many')} type="checkbox" value="1"   />
                                        </div>
                                        <label for="fileMany" className="ml-1">
                                            Позволи много
                                        </label>
                                    </div>
                                </div>

                                <div className="flex flex-row items-center">
                                    <div className="flex flex-row items-center">
                                        <div>
                                            <input id="fileTypeImage" name="file[type]" {...register('file.type')} type="radio" onChange={() => setIsImageField(true)} value="image"   />
                                        </div>
                                        <label for="fileTypeImage" className="ml-1.5 font-semibold">
                                            Снимки
                                        </label>
                                    </div>
                                    <div className="flex flex-row items-center ml-7">
                                        <div>
                                            <input id="fileTypeFile" name="file[type]" {...register('file.type')} type="radio" onChange={() => setIsImageField(false)} value="files"   />
                                        </div>
                                        <label for="fileTypeFile" className="ml-1.5 font-semibold">
                                            Файлове
                                        </label>
                                    </div>
                                    <div className="flex flex-row items-center ml-7">
                                        <div>
                                            <input id="fileTypeVideo" name="file[type]" {...register('file.type')} type="radio" onChange={() => setIsImageField(false)} value="video"   />
                                        </div>
                                        <label for="fileTypeVideo" className="ml-1.5 font-semibold">
                                            Видео
                                        </label>
                                    </div>
                                    <div className="flex flex-row items-center ml-7">
                                        <div>
                                            <input id="fileTypeVideo" name="file[type]" {...register('file.type')} type="radio" onChange={() => setIsImageField(false)} value="audio"   />
                                        </div>
                                        <label for="fileTypeVideo" className="ml-1.5 font-semibold">
                                            Аудио
                                        </label>
                                    </div>
                                </div>

                                {isImage && <div className="bg-gray-50 py-3 px-5 mt-2">
                                    <h3 className="flex items-center text-base leading-6 font-bold text-gray-700 w-full py-3" >
                                        <span>Размери на снимки:</span>

                                        <button type="button" className="flex items-center text-green-600 ml-3 justify-center py-1 px-3 shadow text-sm rounded bg-green-200 focus:outline-none"
                                            onClick={() => addRow(fileFields)}
                                            >
                                            Добави размер <SvgPlus classes="w-4 h-4 text-green-600" />
                                        </button>
                                    </h3>
                                    <ul className="w-full">
                                        {fileFields.map((item, index) => {
                                            return (
                                                <li key={`fileimg${index}`} className="flex flex-row flex-1 items-center py-5 px-5 mt-2 border border-gray-300 border-dotted bg-gray-200">
                                                    <div className="flex flex-1 flex-col">
                                                        <div className="flex flex-1 flex-row">
                                                            <label className="flex-1 framed">
                                                                <input placeholder=" " {...register(`file[images][${index}].width`)} name={`file[images][${index}].width`} defaultValue={item.width} />
                                                                <span>Width</span>
                                                            </label>
                                                            <label className="flex-1 framed ml-5">
                                                                <input placeholder=" " {...register(`file[images][${index}].height`)} name={`file[images][${index}].height`} defaultValue={item.height} />
                                                                <span>Height</span>
                                                            </label>
                                                            <label className="flex-1 framed ml-5">
                                                                <input placeholder=" " {...register(`file[images][${index}].folder`)} name={`file[images][${index}].folder`} defaultValue={item.folder} />
                                                                <span>Папка</span>
                                                            </label>
                                                            <label className="flex-1 framed ml-5">
                                                                <input placeholder=" " {...register(`file[images][${index}].quality`)} name={`file[images][${index}].quality`} defaultValue={item.quality} />
                                                                <span>Качество 90: </span>
                                                            </label>
                                                        </div>
                                                        <div className="flex flex-1 flex-row mt-3">
                                                            <Controller
                                                              render={({ field }) => (<ReactSelect {...field} options={optionsImageCut} className="w-full text-sm" placeholder="Функция за оразмеряване..." />)}
                                                              name={`file[images][${index}].cut`}
                                                              control={control}
                                                            />
                                                        </div>

                                                    </div>

                                                    <div className="flex ml-3">
                                                        <button type="button" className="flex items-center justify-center h-9 w-9 rounded bg-red-200 focus:outline-none"
                                                            onClick={() => removeRow(index)}
                                                            >
                                                            <SvgMinus classes="w-4 h-4 text-red-600" />
                                                        </button>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>}


                            </div>
                        </div>
                    </div>
                }

                {isTags &&
                    <div className="bg-gray-100 px-4 py-3 sm:px-6 pt-1">
                        <h3 className="text-base leading-6 font-medium text-gray-700 w-full py-3" >
                            Таблица за тагове
                        </h3>

                        <div className="flex items-center pb-2">
                            <div className="flex-1 mr-5">
                                <label className="framed w-full">
                                    <input placeholder=" " {...register('tags.table')} name="tags[table]" />
                                    <span>Име на таблицата за тагове </span>
                                </label>
                            </div>
                        </div>
                    </div>
                }

                {isMaps &&
                    <div className="bg-gray-100 px-4 py-3 sm:px-6 pt-1">
                        <h3 className="text-base leading-6 font-bold text-gray-700 w-full py-3" >
                            Полета за карта
                        </h3>

                        <div className="flex items-center pb-2">
                            <div className="flex-1 mr-5">
                                <label className="framed w-full">
                                    <input placeholder=" " {...register('map.latitude')} name="map[latitude]" />
                                    <span>Поле в което да запазя latitude </span>
                                </label>
                                <small className="text-gray-600"> Пожелание за по-голяма точност - float(10,8) </small>
                            </div>
                            <div className="flex-1 mr-5">
                                <label className="framed w-full">
                                    <input placeholder=" " {...register('map.longtitude')} name="map[longtitude]" />
                                    <span>Поле в което да запазя longtitude </span>
                                </label>
                                <small className="text-gray-600"> Пожелание за по-голяма точност - float(10,8) </small>
                            </div>
                        </div>
                        <div className="flex items-center pb-2">
                            <div className="flex-1 mr-5">
                                <label className="framed w-full">
                                    <input placeholder=" " {...register('map.city')} name="map[city]" />
                                    <span>Град за отваряне при зареждане </span>
                                </label>
                                <small className="text-gray-600"> Пожелание кое място да отворя при зареждане (default: София, България)) </small>
                            </div>
                            <div className="flex-1 mr-5">
                                <label className="framed w-full">
                                    <input placeholder=" " {...register('map.zoom')} name="map[zoom]" />
                                    <span>Zoom </span>
                                </label>
                                <small className="text-gray-600"> Пожелание кое място да отворя при зареждане (default: 15) </small>
                            </div>
                        </div>
                        <div className="flex items-center pb-2 mt-5">
                            <div className="mr-5 -mt-2 font-semibold">
                                Транслитерация на резултатите:
                            </div>
                            <div className="flex-1 mr-5">
                                <Controller
                                    name={`map[translate]`}
                                    control={control}
                                    render={({ field }) => (
                                        <Switch onChange={(e) => {
                                            field.onChange(e);
                                          }}
                                          checked={field.value} />
                                    )}
                                />
                            </div>
                        </div>


                    </div>
                }

                {isSubForm &&
                    <div className="bg-gray-100 px-4 py-3 sm:px-6 pt-1">
                        <h3 className="text-base leading-6 font-medium text-gray-700 w-full py-3" >
                            Настройки на подформата:
                        </h3>

                        <div className="flex items-center pb-2">
                            <div className="flex-1">
                                <span className="text-sm">Вземи настройки за подформата от модул:</span>
                                <Controller
                                    name={`subform[module]`}
                                    control={control}
                                    render={({ field }) => (
                                        <AsyncSelect  defaultOptions={appsList} defaultValue={field.value} {...field} isClearable loadOptions={searchApps} cacheOptions onChange={(e) => { field.onChange(e);handleAppsSearch(e); }} className="w-full text-sm" />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="flex items-center pb-2">
                            <Controller
                              render={({ field }) => (<ReactSelect {...field} options={defaultSubFields} className="w-full text-sm" placeholder="Връзка по поле..." isClearable />)}
                              name="subform[connection]"
                              control={control}
                            />
                        </div>

                        <div className="flex items-center pb-2 mt-5">
                            <div className="mr-5 -mt-2 font-semibold">
                                Позиционирай формата <small>под всички input-и независимо от подребата в модула</small>:
                            </div>
                            <div className="flex-1 mr-5">
                                <Controller
                                    name={`subform[place_bottom]`}
                                    control={control}
                                    render={({ field }) => (
                                        <Switch onChange={(e) => {
                                            field.onChange(e);
                                          }}
                                          checked={field.value} />
                                    )}
                                />
                            </div>
                        </div>

                    </div>
                }

                {!isFile && !isTags && !isMaps && !isSubForm &&
                    <div>
                        <div className="bg-gray-100 px-4 py-3 sm:px-6">
                            <div className="text-gray-600 flex items-center">
                                <div className="font-medium text-gray-700 text-base flex-2 mr-4">
                                    Свързва се с база данни:
                                </div>
                                <div className="flex-1">
                                    <div className="mt-2 float-left">
                                        <Switch onChange={handleChange} checked={usesDatabase} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="bg-gray-100 px-4 py-3 sm:px-6 pt-1">
                            <h3 className="text-base leading-6 font-medium text-gray-700 w-full py-3" >
                                Настройки
                            </h3>

                            {!usesDatabase &&
                                <div className="w-full">

                                    <ul className="w-full">
                                        {fields.map((item, index) => {
                                            return (
                                                <li key={item.id} className="flex items-center pb-2">
                                                    <div className="flex-1 mr-5">
                                                        <label className="framed w-full">
                                                            <input placeholder=" " {...register(`manual[${index}].value`)} name={`manual[${index}].value`} defaultValue={`${item.value}`} />
                                                            <span>Стойност</span>
                                                        </label>
                                                    </div>
                                                    <div className="flex-1 mr-5">
                                                        <label className="framed w-full">
                                                            <input placeholder=" " {...register(`manual[${index}].label`)} name={`manual[${index}].label`} defaultValue={`${item.label}`} />
                                                            <span>Етикет</span>
                                                        </label>
                                                    </div>

                                                    <div className="flex-2">
                                                        <button className="flex items-center justify-center h-9 w-9 rounded bg-red-200 focus:outline-none"
                                                            onClick={() => remove(index)}
                                                            >
                                                            <svg className="w-5 h-5 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
                                                            </svg>
                                                        </button>
                                                    </div>

                                                </li>
                                            );
                                        })}
                                    </ul>

                                    <div className="flex items-center pb-2">
                                        <div className="flex-1 mr-5"></div>
                                        <div className="flex-1 mr-5"></div>
                                        <div className="flex-2">
                                            <button type="button" className="flex items-center justify-center h-9 w-9 rounded bg-green-200 focus:outline-none"
                                                onClick={() => {
                                                    append({ label: "", value: "" });
                                                }}
                                                >
                                                <svg className="w-5 h-5 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }

                            {usesDatabase &&
                                <div className="w-full">

                                    <ul className="w-full">
                                        <li className="flex items-center pb-2">
                                           <AsyncSelect  defaultOptions={appsList} defaultValue={asyncSelectValue} loadOptions={searchApps} cacheOptions onChange={(e) => handleAppsSearch(e)} className="w-full text-sm" />
                                        </li>
                                        <li className="flex items-center pb-2">
                                            <Controller
                                              render={({ field }) => (<ReactSelect {...field} options={defaultSubFields} isClearable className="w-full text-sm" placeholder="Връзка по поле (типично ID)..." />)}
                                              name="database[connection]"
                                              ref={refSelect1}
                                              control={control}
                                            />
                                        </li>
                                        <li className="flex items-center pb-2">
                                            <Controller
                                              render={({ field }) => (<ReactSelect {...field} options={defaultSubFields} isMulti isClearable className="w-full text-sm" placeholder="Покажи..." />)}
                                              name="database[options]"
                                              ref={refSelect2}
                                              control={control}
                                            />
                                        </li>
                                        <li className="flex items-center pb-2">
                                            <Controller
                                              render={({ field }) => (<ReactSelect {...field} options={defaultSubFields} isClearable className="w-full text-sm" placeholder="Рекурсия от поле (типично parent_id)..." />)}
                                              name="database[recursion]"
                                              ref={refSelect3}
                                              control={control}
                                            />
                                        </li>
                                        <li className="flex items-center pb-2">
                                            <Controller
                                              render={({ field }) => (<ReactSelect {...field} options={defaultSubFields} isClearable className="w-full text-sm" placeholder="Сортиране по поле..." />)}
                                              name="database[sortorder]"
                                              ref={refSelect5}
                                              control={control}
                                            />
                                        </li>
                                        <div className="flex items-center pb-2">
                                            <label className="framed w-full">
                                                <input placeholder=" " {...register('database.whereRaw')} name="database[whereRaw]" />
                                                <span>is_active = 1 AND id > 1 (допълнително raw where)</span>
                                            </label>
                                        </div>

                                        <div className="text-gray-600 flex items-center">
                                            <div className="font-medium text-gray-700 text-base flex-2 mr-4">
                                                Позволява многократен избор:
                                            </div>
                                            <div className="flex-1">
                                                <div className="mt-2 float-left">

                                                    <Controller
                                                        name={`database[multiple]`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Switch onChange={(e) => {
                                                                field.onChange(e);
                                                              }}
                                                              checked={field.value} />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </ul>

                                </div>
                            }



                        </div>

                    </div>
                }

                <div className="bg-gray-100 px-4 py-3 sm:px-6 pt-1">
                    <button type="button" className="text-base flex flex-inline leading-6 font-medium text-gray-700 pt-3 focus:outline-none" onClick={() => handleShowList()}>
                        Листинг
                        {!showList &&
                            <svg className="w-5 h-5 mt-0.5 ml-1 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        }
                        {showList &&
                            <svg className="w-5 h-5 mt-0.5 ml-1 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        }
                    </button>

                    <div className={!showList && 'hidden w-full'}>
                        <div className="flex items-center pb-2">
                            <div className="flex-1 mr-5">
                                <span  className="w-full text-sm text-gray-700">&nbsp;</span>

                                <label className="framed w-full">
                                    <input placeholder=" " {...register('list.label')} name="list[label]" />
                                    <span>Етикет</span>
                                </label>
                            </div>
                            <div className="flex-1">
                                <span  className="w-full text-sm text-gray-700">Опции</span>
                                <Controller
                                  render={({ field }) => (<ReactSelect {...field} options={options} placeholder="Избери..." isClearable isMulti className="w-full text-sm" />)}
                                  name="list[options]"
                                  control={control}
                                />
                            </div>
                        </div>
                        <div className="flex hidden items-center pb-2">
                            <div className="flex-1">
                                <label className="framed w-full">
                                    <input placeholder=" " {...register('list.format')} name="list[format]" />
                                    <span>Формат</span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>


                <div className="bg-gray-100 px-4 py-3 sm:px-6 pt-1">
                    <button type="button" className="text-base flex flex-inline leading-6 font-medium text-gray-700 py-3 focus:outline-none" onClick={() => handleShowView()}>
                        Настройки
                        {!showView &&
                            <svg className="w-5 h-5 mt-0.5 ml-1 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        }
                        {showView &&
                            <svg className="w-5 h-5 mt-0.5 ml-1 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        }
                    </button>

                    <div className={!showView && 'hidden w-full'}>
                        <div className="flex items-center pb-2">
                            <div className="flex-1 mr-5">
                                <span  className="w-full text-sm text-gray-700">Валидация</span>
                                <Controller
                                  render={({ field }) => (<ReactSelect {...field} options={optionsValidation} isMulti className="w-full text-sm" placeholder="избери..." isClearable />)}
                                  name="view[validation]"
                                  control={control}
                                />
                            </div>
                            <div className="flex-1">
                                <span  className="w-full text-sm text-gray-700">&nbsp;</span>

                                <label className="framed w-full">
                                    <input placeholder=" " {...register('view.default')} name="view[default]" />
                                    <span>Стойност по подразбиране</span>
                                </label>
                            </div>
                        </div>
                        <div className="flex items-center pb-2">
                            <div className="flex-1 mr-5">
                                <label className="framed w-full">
                                    <input placeholder=" " {...register('view.tooltip')} name="view[tooltip]" />
                                    <span>Tooltip</span>
                                </label>
                            </div>
                            <div className="flex-1">
                                <label className="framed w-full">
                                    <input placeholder=" " {...register('view.classes')} name="view[classes]" />
                                    <span>Класове</span>
                                </label>
                            </div>
                        </div>
                        <div className="flex items-center pb-2">
                            <div className="flex-1 mr-5">
                                <label className="framed w-full">
                                    <input placeholder=" " {...register('view.sufix')} name="view[sufix]" />
                                    <span>Suffix</span>
                                </label>
                            </div>
                            <div className="flex-1">
                                <label className="framed w-full">
                                    <input placeholder=" " {...register('view.prefix')} name="view[prefix]" />
                                    <span>Prefix</span>
                                </label>
                            </div>
                        </div>

                        <div className="flex items-center pb-5">
                            <div className="flex-1 mr-5">
                                <label className="framed w-full">
                                    <input placeholder=" " {...register('view.placeholder')} name="view[placeholder]" />
                                    <span>Placeholder</span>
                                </label>
                                <small className="text-gray-500 -my-0.5 float-left px-2 text-xs	">
                                    &nbsp;<br />&nbsp;
                                </small>
                            </div>
                            <div className="flex-1">
                                <label className="framed w-full">
                                    <input placeholder=" " {...register('view.attributes')} name="view[attributes]" />
                                    <span>Html атрибути</span>
                                </label>
                                <small className="text-gray-500 -my-0.5 float-left px-2 text-xs	">
                                    Това са атрибути които ще се добавят за самия таг: style="asd" И/ЛИ data-id="1"
                                </small>
                            </div>
                        </div>
                    </div>

                </div>


                <div className="px-4 bg-gray-50 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                        Запази
                    </button>
                    <button
                        type="button"
                        onClick={() => closeModal(row)}
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                        Отказ
                    </button>
                </div>

            </form>

        </Modal>
        </>
);
};

export default ModalExp;
