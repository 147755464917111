import { combineReducers } from 'redux';
import authReducer from './authReducer';
import dashboardReducer from './dashboardReducer';

//PROJECTS
import appsSettingsReducer from './../projects/reducers/appsSettingsReducer';
import appsListReducer from './../projects/reducers/appsListReducer';
import clientsListReducer from './../projects/reducers/clientsListReducer';
import clientsProjectReducer from './../projects/reducers/clientsProjectReducer';
import clientsTasksReducer from './../projects/reducers/clientsTasksReducer';

export default combineReducers({
    auth           : authReducer,
    dashboard      : dashboardReducer,

    //PROJECTS
    appsSettings   : appsSettingsReducer,
    appsList       : appsListReducer,
    clients        : clientsListReducer,
    clientsProject : clientsProjectReducer,
    clientsTasks   : clientsTasksReducer
});
