import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  XIcon
} from '@heroicons/react/outline'

//Components
import Profile from './Profile'
import CurrentDate from './CurrentDate'
import Search from './Search'
import MenuLinks from './Menu'
import Logo from './Logo'

//Actions
import { openSideBar } from './../../../../actions/dashboardActions'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Main() {

    const dashboard = useSelector(state => state.dashboard);
    const sidebarOpen = dashboard.sidebarOpen;
    const dispatch = useDispatch();

    const setSidebarOpen = (action) => {
        dispatch(openSideBar(action));
    };

    return (
        <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
              <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setSidebarOpen}>
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                </Transition.Child>
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 bg-gray-800">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          type="button"
                          className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 flex items-center px-4">
                      <Logo />
                    </div>
                    <div className="mt-5 flex-1 h-0 overflow-y-auto">

                      <nav className="px-2 pb-8">
                        <Search />
                        <div className="space-y-1">
                            <MenuLinks />
                        </div>
                      </nav>
                    </div>
                    <div className="flex-shrink-0 flex bg-gray-800 py-1">
                        <CurrentDate />
                    </div>
                    <div className="flex-shrink-0 flex bg-gray-700 p-4">
                        <Profile />
                    </div>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 w-14" aria-hidden="true">
                  {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
              </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:flex lg:flex-shrink-0">
              <div className="flex flex-col w-56">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex-1 flex flex-col min-h-0">
                  <div className="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900">
                      <Logo />
                  </div>
                  <div className="flex-1 flex flex-col overflow-y-auto">
                    <nav className="flex-1 px-2 py-4 bg-gray-800">
                      <div className="space-y-1 mt-5">
                        <MenuLinks />
                      </div>
                    </nav>
                  </div>
                  <div className="flex-shrink-0 flex bg-gray-800 py-1">
                      <CurrentDate />
                  </div>
                  <div className="flex-shrink-0 flex bg-gray-700 p-4">
                      <Profile />
                  </div>
                </div>
              </div>
            </div>
        </>
    );
}
