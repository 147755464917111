import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { useStopwatch } from 'react-timer-hook';

//Styles
import { SvgAdd,SvgPlus,SvgDots,SvgClock } from '../../../parts/blocks/Svg';
import { timer,getTask } from '../../../../../actions/projects/clientsTasksAction'
import { getProject,changeProjectState } from '../../../../../actions/projects/clientsProjectAction'
import { getClient } from '../../../../../actions/projects/clientsListAction'


const pad = (n) => {
    return (n < 10) ? ("0" + n) : n;
}

export const Timer = ({ task }) => {

    const dispatch = useDispatch();

    const { clientInfo } = useSelector(state => state.clients);
    const { project } = useSelector(state => state.clientsProject);

    const [fromLatest, setFromLatest] = useState(task.fromLatest ? true : false);

    const [startTimer, setStartTimer] = useState(project.timerTask);
    const t1 = new Date();
    const t2 = moment(project.timerStart);
    const dif = t1.getTime() - t2.valueOf();
    const secondsBetween = Math.round(Math.abs(dif / 1000));
    const stopwatchOffset = t1;
    stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + secondsBetween);

    const {
        seconds,
        minutes,
        hours,
        isRunning,
        start,
        reset,
        restart
    } = useStopwatch({ autoStart: project.timerTask ? true : false, offsetTimestamp: stopwatchOffset });

    const startCountDown = async (e,task) => {

        e.stopPropagation();

        if(isRunning && project.timerTask == task._id) {
            dispatch(changeProjectState({
                "state":{...project,"timerTask":"","timerStart":null}
            }));

            reset();
        }
        else if(isRunning) {
            reset();
            start();

            dispatch(changeProjectState({
                "state":{...project,"timerTask":task._id,"timerStart":moment()}
            }));
        }
        else {

            dispatch(changeProjectState({
                "state":{...project,"timerTask":task._id,"timerStart":moment()}
            }));

            start();
        }

        await dispatch(timer({
            clientInfo,
            project,
            "card":task,
        }));

        await dispatch(getTask({task}));
        await dispatch(getProject({"project":project._id}));

        if(fromLatest) {
            await dispatch(getClient({"client":clientInfo.id}));
        }
    }

    useEffect(() => {
        if(!project.timerTask && isRunning) {
            reset();
        }
        else if(project.timerTask && project.timerTask == task._id ) {
            reset(stopwatchOffset);
            start();
        }
    });

    return (
        <div>
            {project.timerTask && project.timerTask == task._id ?
                <div onClick={(e) => startCountDown(e,task)} className="ml-5 pl-3 pr-3 cursor-pointer items-center flex flex-inline font-bold rounded-full text-red-500 text-xs border border-red-500">
                    <SvgClock classes="w-4 h-4 mr-1" />
                    {pad(hours)}:{pad(minutes)}:{pad(seconds)}
                </div>
            :
                <div onClick={(e) => startCountDown(e,task)} className="pl-5 -mr-0.5 cursor-pointer">
                    <SvgClock classes="w-5 h-5 text-green-500 hover:text-red-500" />
                </div>
            }
        </div>
    );
}
