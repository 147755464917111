const columns = [
    {
        "key": "sort",
        "title": "Сорт",
        "dataKey": "sort",
        "align": "center",
        "width": 50,
    },
    {
        "key": "id",
        "title": "ID",
        "dataKey": "id",
        "sortable": false,
        "width": 100,
        "className": "text-sm"
    },
    {
        "key": "pr_name_input",
        "title": "Заглавие",
        "dataKey": "title",
        "sortable": false,
        "width": 880,
        "className": "text-sm"
    },
    {
      "key": "settings",
      "title": "Канал",
      "dataKey": "settings",
      "sortable": false,
      "align": "center",
      "width": 100,
      "className": "text-sm"
    },
    {
        "key": "action",
        "title": "",
        "align": "center",
        "className": "text-sm",
        "width": 50
    }
];

export default columns;
