import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select'
import options from '../../../../env/apis/projects/data/appsOptionsFields';

export default ({ column,row,updateBlocksFieldAction }) => {

    const dispatch = useDispatch();
    const defaultValue = row[column];
    const handleChange = (column,row,updateBlocksFieldAction,value) => {
        dispatch(updateBlocksFieldAction({ column, row, value }));
    }

    return (
        <>
            <Select key={row.id+column} isSearchable options={options} defaultValue={defaultValue} onChange={(e) => handleChange(column,row,updateBlocksFieldAction,e)} className="w-full text-left" />
        </>
    )
}
