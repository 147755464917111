import React from 'react';
import { SvgSettings } from '../../parts/blocks/Svg';
import { useDispatch, useSelector } from "react-redux";

export default ({ data,row,openCloseModalAction }) => {

    const dispatch = useDispatch();
    const openModal = (data,row,openCloseModalAction) => {
        dispatch(openCloseModalAction({ "openClose":true, row }));
    }

    return (
        <>
            <button onClick={() => openModal(data,row,openCloseModalAction)} >
                <SvgSettings />
            </button>
        </>
    )
}
