import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import history from '../../../../history';

export default ({ column,row,mainUrl,action }) => {

    const dispatch = useDispatch();

    const handleClick = (url,row,action) => {
        if(action) {
            dispatch(action({"openClose":true,url,"row":row}));
        }
        else {
            history.push(url);
        }
    }

    return (
        <>
            <button key={row.id+column} onClick={(e) => handleClick(`${mainUrl}${row.id}`,row,action)} className="w-full focus:outline-none">
                <svg className="w-6 h-6 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                </svg>
            </button>
        </>
    )
}
