import { v4 as uuidv4 } from 'uuid';

//Apis
import appsApi from '../../env/apis/projects/appsApi';

//Types
import {
    CLIENTS_TASKS_ADD_ROW,
    CLIENTS_TASKS_FINISH_EDIT,
    CLIENTS_TASKS_START_EDIT,
    CLIENTS_TASKS_ACCOUNTED,
    CLIENTS_LATEST_TASKS
} from './types';

export const addTask = (payload) => async (dispatch,getState) => {
    try {
        const defaultLabel = payload.project.currentFilter.label ? payload.project.currentFilter.label : "Съпорт";
        const defaultValue = payload.project.currentFilter.value ? payload.project.currentFilter.value : "support";

        const row = {
            "weight"      : 0,
            "title"       : "",
            "description" : "",
            "deal"        : "",
            "accounted"   : [],
            "kind"        : payload.project.currentFilter && payload.project.currentFilter.length > 0 ? payload.project.currentFilter : {value: defaultValue, label: defaultLabel},
            "grid"        : "1"
        };

        row.clientId = payload.clientInfo.id;
        row.clientNumber = payload.clientInfo._id;
        row.projectId = payload.project._id;
        row.grid = payload.tab.id;

        const response = await appsApi.post('/tasks',row);
        response.data.data.added = 1;
        dispatch({ type: CLIENTS_TASKS_START_EDIT, payload: response.data.data });
    }
    catch {
        //dispatch({ type: WRONG_LOGIN });
    }
};

export const editTask = (payload) => async (dispatch,getState) => {
    if(payload.task._id) {
        await appsApi.patch('/tasks/'+payload.task._id,payload.update);
    }
};

export const deleteTask = (payload) => async (dispatch,getState) => {
    await appsApi.delete('/tasks/'+payload.task.id);
};

export const startEditTask = (payload) => async (dispatch,getState) => {
    dispatch({ type: CLIENTS_TASKS_START_EDIT, payload: payload.task });
};


export const getTask = (payload) => async (dispatch,getState) => {
    const response = await appsApi.get('/tasks/'+payload.task._id);
    dispatch({ type: CLIENTS_TASKS_START_EDIT, payload: response.data.data });
    return response.data.data;
};

export const updateTaskState = (payload) => async (dispatch,getState) => {
    dispatch({ type: CLIENTS_TASKS_START_EDIT, payload: payload.task });
};

export const taskAddFile = (payload) => async (dispatch,getState) => {
    const formData = new FormData();

    formData.append("file", payload.files[0]);
    appsApi.post('/tasks/'+payload.task._id+"/upload", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    });
};

export const timer = (payload) => async (dispatch,getState) => {
    const response = await appsApi.post('/tasks/'+payload.card._id+'/timer',{"clientId":payload.clientInfo._id,"projectId":payload.project._id});
};

export const deleteTimer = (payload) => async (dispatch,getState) => {
    await appsApi.delete('/timers/'+payload._id);
};

export const updateTimer = (payload) => async (dispatch,getState) => {
    if(payload.task._id) {
        await appsApi.patch('/timers/'+payload.task._id,{"task":payload.task,"update":payload.update});
    }
};

export const addComment = (payload) => async (dispatch,getState) => {
    try {
        const row = payload.row;
        row.clientId = payload.clientInfo._id;
        row.projectId = payload.project._id;
        row.taskId = payload.task._id;
        row.weight = 0;

        const response = await appsApi.post('/comments',row);
    }
    catch {
        //dispatch({ type: WRONG_LOGIN });
    }
};

export const editComment = (payload) => async (dispatch,getState) => {
    if(payload.row._id) {
        await appsApi.patch('/comments/'+payload.row._id,payload.update);
    }
};

export const deleteComment = (payload) => async (dispatch,getState) => {
    await appsApi.delete('/comments/'+payload.row._id);
};

export const addSubTasks = (payload) => async (dispatch,getState) => {
    try {
        const row = payload.row;
        row.clientId = payload.clientInfo._id;
        row.projectId = payload.project._id;
        row.taskId = payload.task._id;
        row.weight = 0;

        const response = await appsApi.post('/subtasks',row);
    }
    catch {
        //dispatch({ type: WRONG_LOGIN });
    }
};

export const editSubTasks = (payload) => async (dispatch,getState) => {
    if(payload.row._id) {
        await appsApi.patch('/subtasks/'+payload.row._id,payload.update);
    }
};

export const deleteSubTasks = (payload) => async (dispatch,getState) => {
    await appsApi.delete('/subtasks/'+payload.row._id);
};

export const getAccountedByDate = (payload) => async (dispatch,getState) => {
    const response = await appsApi.post('/accounts/bydate/'+payload.project._id,payload.params);
    dispatch({ type: CLIENTS_TASKS_ACCOUNTED, payload: response.data.data });
    return response.data.data;
};

export const addAccount = (payload) => async (dispatch,getState) => {
    try {
        const row = payload.row;
        row.clientId = payload.clientInfo._id;
        row.projectId = payload.project._id;
        row.taskId = payload.task._id;
        row.weight = 0;

        const response = await appsApi.post('/account',row);
    }
    catch {
        //dispatch({ type: WRONG_LOGIN });
    }
};

export const editAccount = (payload) => async (dispatch,getState) => {
    if(payload.row._id) {
        await appsApi.patch('/account/'+payload.row._id,payload.update);
    }
};

export const editAccounts = (payload) => async (dispatch,getState) => {
    await appsApi.patch('/accounts/'+payload.project._id,payload.update);
};

export const deleteAccount = (payload) => async (dispatch,getState) => {
    await appsApi.delete('/account/'+payload.row._id);
};

export const getLatestTasks = (payload) => async (dispatch,getState) => {
    const response = await appsApi.get('/latesttasks/'+payload.client+'?limit=15');
    dispatch({ type: CLIENTS_LATEST_TASKS, payload: response.data.data });
};
