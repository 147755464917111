import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import _get from "query-string";
import _filter from "@mcabreradev/filter";
import history from '../../../history';
import moment from 'moment';
import { ChevronLeftIcon } from '@heroicons/react/solid'

//Styles
import { SvgAdd,SvgBack,SvgHuman,SvgFolder,SvgStats,SvgArrowRight,SvgHat,SvgSpin } from '../parts/blocks/Svg';

//Custom hooks
import useDocumentTitle from './../../../hooks/useDocumentTitle'
import workDays from './../../../hooks/workDays'

//Components
import Dashboard from '../../board/Dashboard';
import ActionBar from '../../board/panels/content/ActionBar';
import Projects from './parts/client/Projects'
import MainInfo from './parts/client/MainInfo'
import Wallet from './parts/client/Wallet'
import Stats from './parts/client/Stats'
import Modal from './parts/modals/ModalTask'

//Actions
import { getClient } from '../../../actions/projects/clientsListAction'
import { addProject,openCloseTasksModalAction,getProject } from '../../../actions/projects/clientsProjectAction'
import { getLatestTasks,startEditTask } from '../../../actions/projects/clientsTasksAction'

//Data
import columns from '../../../env/apis/projects/data/clientsAppsColumns';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ClientsProjects = (props) => {

  const id = props.match.params.id;

  const dispatch = useDispatch();
  const { clientInfo,latestTasks } = useSelector(state => state.clients);
  const { openedModalTasks } = useSelector(state => state.clientsProject);

  let pageTitle = "👻";
  if(clientInfo && clientInfo.name) {
      pageTitle += " # Проекти на: " + clientInfo.name;
  }
  useDocumentTitle(pageTitle);


  const search = _get.parse(window.location.search, {parseBooleans: true});
  const [selectedTab, setSelectedTab] = useState(history.location.hash ? parseInt(history.location.hash.substr(3)) : 1);
  if(search && search.q && selectedTab != 1) {
      setSelectedTab(1);
  }

  const changeTab = (tab) => {
      setSelectedTab(tab);
      history.push("#tb"+tab);
  }

  const createProject = async () => {
      await dispatch(addProject({clientInfo}));
  }

  useEffect(() => {
      dispatch(getClient({"client":id,search}));
      dispatch(getLatestTasks({"client":id}));
  }, []);

  let totalHours = 0;
  let totalMoney = 0;
  let timeMonthly = 0;

  if(clientInfo.projects) {
      clientInfo.projects.map(project => {
          totalHours += project.deal;
          totalMoney += project.deal*project.income;
          timeMonthly += project.deal*project.timerMonthly; //timerTotal
      });
  }

  const diffDays = workDays(moment(),moment().endOf('month'));
  const diffHours = diffDays*8;
  let expectedWork = 0;
  const activeTimers = [];
  if(clientInfo.projects) {
      clientInfo.projects.map(card => {
          if(card.timerTask) {
              activeTimers[card.timerTask] = 1;
          }
          if(card.deal) {
              expectedWork += parseInt(card.deal);
          }
      });
  }
  const leftWork = diffHours-expectedWork;
  const editCard = async (card) => {
      await dispatch(getProject({"project":card.projectId}));
      await dispatch(startEditTask({"task":{...card,"fromLatest":1},clientInfo}));
      await dispatch(openCloseTasksModalAction({ "openClose":true }));
  }

  if(!clientInfo || id != clientInfo.id) {
      return (
          <Dashboard layoutRender="TopSidebar" name="ClientsProjects">
              <ActionBar
                  title=<div className="px-10 py-3 bg-gray-100 text-base font-semibold flex flex-row items-center rounded shadow">
                        <SvgSpin classes="text-gray-700 w-6 h-6 mr-3" />
                        <span>Зареждам клиента</span>
                   </div>
               />
          </Dashboard>
      );
  }

  return (
      <Dashboard layoutRender="TopSidebar" name="ClientsProjects" background={clientInfo.background ? clientInfo.background  : "none"}>
          {openedModalTasks && <Modal />}

      <div className="flex-grow">
          <div className="flex flex-row items-center">
              <div className="flex flex-row items-center">
                  <div className="flex bg-gray-400 rounded px-2 py-1 flex-row items-center mr-4 justify-center items-center">
                      <ChevronLeftIcon className="h-5 w-5 -ml-1 text-black" aria-hidden="true" />
                      <Link to={`/clients`} className="text-sm text-gray-700 uppercase font-medium tracking-wider">
                          НАЗАД
                      </Link>
                  </div>
                  <div className="flex bg-gray-900 text-gray-50 rounded px-2 py-1 flex-row items-center text-2xl font-bold mr-5 justify-center items-center">
                        {clientInfo.name}
                  </div>
                  <div className="flex flex-row items-center text-2xl text-gray-900 font-bold mr-3 justify-center items-center">
                      <button onClick={createProject} className="bg-blue-700 h-9 text-gray-200 order-last font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg focus:outline-none ml-1 focus:ring-gray-900 focus:ring-2 focus:ring-offset-2"
                          type="button"
                          >
                          създай проект <SvgAdd classes="w-5 h-5 float-right ml-2" />
                    </button>
                  </div>
              </div>
              <div className="flex-1 ml-5">
                  <nav className="relative z-0 rounded-lg shadow flex divide-x divide-gray-500" aria-label="Tabs">
                      <button className={"rounded-l group relative min-w-0 flex-1 overflow-hidden px-4 text-sm font-medium text-center hover:bg-gray-300 focus:z-10 " + (selectedTab == 1 ? 'text-gray-900  bg-gray-300' : 'text-gray-800 hover:text-gray-900 bg-gray-400')} onClick={() => changeTab(1)}>
                          Проекти
                          <span aria-hidden="true" className={classNames( selectedTab == 1 ? 'bg-red-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5' )} />
                      </button>
                      <button className={"group relative min-w-0 flex-1 overflow-hidden px-4 text-sm font-medium text-center hover:bg-gray-300 focus:z-10 " + (selectedTab == 2 ? 'text-gray-900  bg-gray-300' : 'text-gray-800 hover:text-gray-900 bg-gray-400')} onClick={() => changeTab(2)}>
                          Месечни Отчети
                          <span aria-hidden="true" className={classNames( selectedTab == 2 ? 'bg-red-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5' )} />
                      </button>
                      <button className={"group relative min-w-0 flex-1 overflow-hidden py-3 px-4 text-sm font-medium text-center hover:bg-gray-300 focus:z-10 " + (selectedTab == 3 ? 'text-gray-900  bg-gray-300' : 'text-gray-800 hover:text-gray-900 bg-gray-400')} onClick={() => changeTab(3)}>
                          Статистики
                          <span aria-hidden="true" className={classNames( selectedTab == 3 ? 'bg-red-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5' )} />
                      </button>
                      <button className={"rounded-r group relative min-w-0 flex-1 overflow-hidden py-3 px-4 text-sm font-medium text-center hover:bg-gray-300 focus:z-10 " + (selectedTab == 4 ? 'text-gray-900  bg-gray-300' : 'text-gray-800 hover:text-gray-900 bg-gray-400')} onClick={() => changeTab(4)}>
                          Данни за клиента
                          <span aria-hidden="true" className={classNames( selectedTab == 4 ? 'bg-red-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5' )} />
                      </button>
                  </nav>
              </div>

          </div>
              <div className="flex-grow">



                  {!clientInfo && <div className="bg-white py-5 text-center w-full">Зареждам данните...</div>}

                  {clientInfo && clientInfo.id && selectedTab === 1 &&
                      <Projects />
                  }

                  {clientInfo && clientInfo.id && selectedTab === 2 &&
                      <Wallet />
                  }

                  {clientInfo && clientInfo.id && selectedTab === 3 &&
                      <Stats />
                  }

                  {clientInfo && clientInfo.id && selectedTab === 4 &&
                      <MainInfo />
                  }

              </div>

          </div>
     </Dashboard>
  );
};

export default ClientsProjects;
