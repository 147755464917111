import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerApi } from './../../actions/authActions'

import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';

import urls from './../../env/urls';
import LeftImage from './panels/LeftImage';

export default function Register() {

  let touched = false;
  let error = false;
  const [warning, setWarning] = useState(0);

  const dispatch = useDispatch();

  const passwordStrength = (password) => {

      let msg = "минималния брой символи е 6",
          passed = 0;

      if(!password) {
          return {
              "msg" : msg,
              "meter" : passed
          };
      }

      var regex = [];
      regex.push("[A-Z]"); //For Uppercase Alphabet
      regex.push("[a-z]"); //For Lowercase Alphabet
      regex.push("[0-9]"); //For Numeric Digits
      regex.push("[$@$!%*#?&~^%_]"); //For Special Characters

      if (password.length >= 6) {
        msg = "";
        passed++;
      }

      for (var i = 0; i < regex.length; i++) {
          if((new RegExp (regex[i])).test(password)){
              passed++;
          }
      }

      if(passed > 5) {
          passed = 5;
      }

      setWarning(passed);
  };

  const checkPassStrength = (e) => {
      passwordStrength(e.target.value);
  }

  const { register, handleSubmit, getValues, formState: { errors } } = useForm({shouldFocusError: true});
  const onSubmit = (data) => {
      dispatch(registerApi(data));
  };

  return (
    <div className="min-h-screen bg-white flex">
      <LeftImage />
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>

        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Регистрация в <span className="logo-color">Giganot</span></h2>

          </div>

          <div className="mt-8">

            <div className="mt-6">
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-6" noValidate>

                 <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                       Име
                    </label>
                    <div className="mt-1">
                      <input
                        id="name"
                        name="name"
                        type="text"
                        autoComplete="name"
                        {...register("name", { required: true })}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                      {errors.name?.type === 'required' &&
                      <p className="mt-2 text-sm text-red-600" id="name-error">
                        Името е задължително.
                      </p>
                      }
                    </div>
                </div>

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                     Имейл адрес
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    {errors.email?.type === 'required' &&
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      Имейл е задължително.
                    </p>
                    }
                    {errors.email?.type === 'pattern' &&
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      Невалиден имейл.
                    </p>
                    }
                  </div>
                </div>

                <div className="space-y-1">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Парола
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      {...register('password', { required: true, minLength: 6, onChange: (e) => checkPassStrength(e) })}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />

                    <div className="strength-meter mt-2 visible"><div className="strength-meter-fill" data-strength={warning  ? warning : 0}></div></div>
                    {warning > 0 &&
                        <div className="text-xs font-light mt-1 font-small flex text-gray-400">
                            Сила на паролата: {warning}
                        </div>
                    }
                    {warning == 0 &&
                    <div className="text-xs font-light mt-1 font-small flex text-gray-400">
                        паролата трябва да е минимум 6 символа
                    </div>
                    }
                    {(errors.password?.type === 'required' || errors.password?.type === 'minLength') &&
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      паролата трябва да е минимум 6 символа
                    </p>
                    }
                    {touched && error && <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">{error}</span>}
                  </div>
                </div>

                <div className="space-y-1">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Повтори Парола
                  </label>
                  <div className="mt-1">
                    <input
                      id="repassword"
                      name="repassword"
                      type="password"
                      {...register('repassword', { required: true, minLength: 6,validate: value => value === getValues('password') || <span>Потвърдената парола не съвпада</span> })}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    {(errors.repassword?.type === 'required' || errors.repassword?.type === 'minLength') &&
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      паролата трябва да е минимум 6 символа
                    </p>
                    }
                    {errors.repassword?.type === 'validate' &&
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      потвърдената парола не съвпада
                    </p>
                    }
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Регистрация
                  </button>
                </div>

                <p className="mt-7 text-sm text-gray-600">
                   Имаш регистрация?
                   <Link to={urls.login} className="font-medium ml-2 text-indigo-600 hover:text-indigo-500">
                     Вход
                   </Link>
                 </p>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
);
}
