import { v4 as uuidv4 } from 'uuid';
import { useParams } from "react-router-dom";

//Apis
import appsApi from '../../env/apis/projects/appsApi';

//Types
import {
    ADD_ROW_LIST,
    UPDATE_ROW_LIST,
    UPDATE_FIELD_LIST,
    DELETE_ROW_LIST,
    GET_ROW_LIST,
    GET_ROWS_LIST
} from './types';

import generateUUID from '../../hooks/generateUUID';

export const addBlock = () => async (dispatch,getState) => {
    try {
        const databaseName = generateUUID();
        const rowDefault = {
            "id"           : generateUUID(),
            "weight"       : 0,
            "app_name"     : uuidv4(),
            "app_filename" : generateUUID(),
            "app_database" : generateUUID(),
            "env"          :  {
                "query": 'SELECT * FROM '+databaseName,
                "query_where": 'WHERE 1',
                "title": '',
                "subtitle": 'листинг',
                "addButton": '',
                "addPath": '',
                "editPath": '',
                "bulk": false,
                "sort": {
                    label: 'ID',
                    value: 'id'
                },
                "order": {
                    value: 'DESC',
                    label: 'низходящ 10 -> 1'
                },
                "limit": {
                    value: '20',
                    label: '20'
                }
            },
            "grid"         : [],
            "tabs"         : [{"name":1,"title":"Основна информация"}],
            "fields"       : []
        };
        dispatch({ type: ADD_ROW_LIST, payload: rowDefault });
        const response = await appsApi.post('/blocks',rowDefault);
    }
    catch {
        //dispatch({ type: WRONG_LOGIN });
    }
};

export const deleteBlock = (payload) => async (dispatch,getState) => {
    dispatch({ type: DELETE_ROW_LIST, payload: payload.id });
    await appsApi.delete('/blocks/'+payload.id);
};

export const getBlocks = (payload) => async (dispatch,getState) => {
    if(payload && payload.q) {
        const response = await appsApi.post('/search',payload);
        return dispatch({ type: GET_ROWS_LIST, payload: response.data.data });
    }

    const response = await appsApi.get('/blocks');
    dispatch({ type: GET_ROWS_LIST, payload: response.data });
};

export const updateBlocksAction = (payload) => async (dispatch,getState) => {
    payload.row.settings = payload.data;
    dispatch({ type: UPDATE_ROW_LIST, payload: payload.row });
    appsApi.patch('/blocks/'+payload.row.id, {
        "settings" : payload.data
    });
};

export const updateBlocksFieldAction = (payload) => async (dispatch,getState) => {
    if(payload.multi) {
        appsApi.patch('/blocks/'+payload.row.id,payload.multi);
        return;
    }

    let update = [];
    update[payload.column] = payload.value;
    update = Object.assign({}, update);

    if(!payload.stopRedraw) {
        dispatch({ type: UPDATE_FIELD_LIST, payload: payload });
    }

    if(update && update.app_database) {
        update.mysql_query = "SELECT * FROM " + update.app_database;
    }

    appsApi.patch('/blocks/'+payload.row.id,update);
};

export const reorderBlocks = (payload) => async (dispatch,getState) => {
    payload.forEach((v,i) => {
        payload[i]["weight"] = i*-1;
    });
    appsApi.post('/blocks/weights',payload);
    dispatch({ type: GET_ROWS_LIST, payload: payload });

};
