import { v4 as uuidv4 } from 'uuid';

//Apis
import appsApi from '../../env/apis/projects/appsApi';

//Types
import {
    ADD_ROW,
    UPDATE_ROW,
    UPDATE_FIELD,
    DELETE_ROW,
    GET_ROW,
    GET_ROWS,
    MODAL,
    DRAW_FIELDS_SELECT,
    APPS_LIST,
    UPDATE_APPINFO
} from './types';

import generateUUID from '../../hooks/generateUUID';

export const addBlock = (payload) => async (dispatch,getState) => {
    try {
        const rowDefault = {
            "id"             : generateUUID(),
            "weight"         : 0,
            "title_in_db"    : generateUUID(),
            "app_id"         : payload.app,
            "type"           : {value: 'text', label: 'Текст'},
            "label"          : "-",
            "show_label"     : 1,
            "store_in_db"    : 1,
            "show_in_lsting" : 1,
            "can_edit"       : 1,
            "is_active"      : 1,
            "location"       : {
              "grid"         : "11t"
            },
            "settings"       : {
              "usesDatabase" : false,
              "showView"     : false,
              "showList"     : false,
              "manual"       : [
                  {"label"   : "Не", "value" : 0},
                  {"label"   : "Да", "value" : 1}
              ]
            }
        };
        dispatch({ type: ADD_ROW, payload: rowDefault });
        const response = await appsApi.patch('/blocks/'+payload.app,{ "add" : rowDefault });
    }
    catch {
        //dispatch({ type: WRONG_LOGIN });
    }
};

export const deleteBlock = (payload) => async (dispatch,getState) => {
    dispatch({ type: DELETE_ROW, payload: payload.id });
    const response = await appsApi.patch('/blocks/'+payload.row.app_id,{ "delete" : payload.id });
};


export const getBlocks = (payload) => async (dispatch,getState) => {
    const response = await appsApi.get('/blocks/'+payload.app);

    if(response.data.data.fields) {
        response.data.data.fields.sort(function(a,b){return a.weight - b.weight});
    }

    dispatch({ type: GET_ROWS, payload: {"fields":response.data.data.fields,"row":response.data.data} });
};

export const updateBlocksAction = (payload) => async (dispatch,getState) => {
    payload.row.settings = payload.data;
    dispatch({ type: UPDATE_ROW, payload: payload.row });
    appsApi.patch('/blocks/'+payload.row.app_id, {
        "id" : payload.row.id,
        "update" : {
            "settings" : payload.data
        }
    });
};

export const updateBlocksFieldAction = (payload) => async (dispatch,getState) => {
    let update = [];
    update[payload.column] = payload.value;
    update = Object.assign({}, update);
    dispatch({ type: UPDATE_FIELD, payload: payload });

    appsApi.patch('/blocks/'+payload.row.app_id, {
        "id" : payload.row.id,
        "update" : update
    });
};

export const openCloseModalAction = (payload) => async (dispatch,getState) => {
    dispatch({ type: MODAL, payload});
};

export const reorderBlocks = (payload) => async (dispatch,getState) => {
    payload.forEach((v,i) => {
        payload[i]["weight"] = i;
    });
    appsApi.post('/blocks/weights/'+payload[0].app_id,payload);
    dispatch({ type: GET_ROWS, payload: {"fields":payload} });

};

export const searchApps = async (payload) => {
    const data = [];
    const response = await appsApi.get('/blocks/search?q=' + payload);
    response.data.data.forEach((v) => {
        data.push({...v,"label":v.app_name,"value":v.id,"filename":v.app_filename});
    })
    return data;
};

export const getAppsOptions = (payload) => async (dispatch,getState) => {
    const response = await appsApi.get('/blocks?limit=10');

    const options = [];
    response.data.forEach((v) => {
        options.push({"label":v.app_name,"value":v.id,"fields":v.fields,"table":v.app_database,"filename":v.app_filename});
    });

    const clearNext = payload.clearNext ? payload.clearNext : false;

    dispatch({ type: APPS_LIST, payload: { options:options,"clearNext":clearNext } });
};

export const drawFieldsApps = (payload) => async (dispatch,getState) => {
    const options = [];
    payload.fields.forEach((v) => {
        options.push({"label":v.label,"value":v.title_in_db});
    });

    const clearNext = payload.clearNext ? payload.clearNext : false;

    dispatch({ type: DRAW_FIELDS_SELECT, payload: { options:options,"clearNext":clearNext } });

};

export const updateAppInfo = (payload) => async (dispatch,getState) => {
    dispatch({ type: UPDATE_APPINFO, payload: payload });
};
