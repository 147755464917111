import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import store from './env/store'

import './styles/app.css';
import './styles/appProjects.css';
import 'react-responsive-modal/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-popper-tooltip/dist/styles.css';
import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";
import App from './App';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
