import React, { useState } from 'react'
import moment from 'moment';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import bg from 'date-fns/locale/bg';
registerLocale('bg-bg', bg);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Profile() {

    const [date, setDate] = useState(new Date(moment()));


    const changeDate = async (date) => {
        if(date) {
            setDate(date);
        }
    }

    return (
        <>
        <div className="relative flex w-full">
            <div className="-mt-1 w-full relative">
                <ReactDatePicker
                    onChange={date => changeDate(date)}
                    selected={new Date(moment())}
                    placeholderText="Днес"
                    dateFormat="d MMMM, yyyy, EEEE"
                    locale="bg-bg"
                    className="w-full bg-gray-800 border-gray-800 text-center px-1 py-2 text-gray-100 text-sm focus:outline-none"
                    placeholder=" "
                    />
            </div>
        </div>
        </>
);
}
