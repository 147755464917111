import React from 'react';
import { Link } from 'react-router-dom';

export default function Logo() {
    return (
        <>
            <Link to="/">
                <img
                  className="h-8 w-auto"
                  src="/img/logo-rose.svg"
                  alt="Workflow"
                />
            </Link>
        </>
    );
}
