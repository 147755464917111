import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import _get from "query-string";

//Styles
import { SvgAdd } from '../parts/blocks/Svg';

//Custom hooks
import useDocumentTitle from './../../../hooks/useDocumentTitle'

//Components
import Dashboard from '../../board/Dashboard';
import Table from '../parts/table/Table'
import Modal from './parts/Modal'
import ActionBar from '../../board/panels/content/ActionBar';

//Actions
import { addBlock, deleteBlock, getBlocks, updateBlocksFieldAction, reorderBlocks } from '../../../actions/projects/appsListAction'

//Data
import columns from '../../../env/apis/projects/data/appsListColumns';

const ListRedux = () => {

  useDocumentTitle('👻');

  const dispatch = useDispatch();
  const { rows, isLoaded, openedModal } = useSelector(state => state.appsList);

  const addRow = async () => {
      await dispatch(addBlock());
  }

  const search = _get.parse(window.location.search, {parseBooleans: true});
  const [selectedTab, setSelectedTab] = useState(1);
  if(search && search.q && selectedTab != 1) {
      setSelectedTab(1);
  }

  useEffect(async () => {
      dispatch(getBlocks(search));
  }, []);


  return (
      <Dashboard name="AppsList">
          <ActionBar
              crums={[{"title":"Приложения"}]}
              title="Приложения"
              buttons={<div>
                  <button onClick={addRow} className="bg-blue-700 text-gray-200 order-last mt-1 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg focus:outline-none ml-1 mb-1 focus:ring-gray-900 focus:ring-2 focus:ring-offset-2" type="button">
                      Създай  <SvgAdd classes="w-5 h-5 float-right ml-2" />
                  </button>
              </div>}
              searchIn={{"title":"На тази страница","id":`apps`}}
              clearSearchAction={() => { dispatch(getBlocks()) }}
              searchAction={() => { dispatch(getBlocks(search)) }}
              />

          <div className="flex-grow mb-2">
              {!isLoaded && <div className="bg-white py-5 text-center w-full">Зареждам данните...</div>}
              {(!rows || rows.length == 0) && <div className="bg-white py-5 text-center w-full font-semibold"> Празен лист </div>}
              {isLoaded &&
                  <Table columns={columns} data={rows} deleteAction={deleteBlock} updateAction={updateBlocksFieldAction} reorderBlocks={reorderBlocks} actionUrl="/apps/" />
              }
          </div>
      </Dashboard>
  );
};

export default ListRedux;
