import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { toast } from 'react-toastify';
import ReactSelect from 'react-select'
import Switch from "react-switch";

//Actions
import { updateBlocksFieldAction } from '../../../../actions/projects/appsListAction'

const orderSelect = [
  { value: 'DESC', label: 'низходящ 10 -> 1'},
  { value: 'ASC', label: 'възходящ 1 -> 10'}
];

const orderLimit = [
  { value: '20', label: '20'},
  { value: '5', label: '5'},
  { value: '10', label: '10'},
  { value: '30', label: '30'},
  { value: '50', label: '50'},
  { value: '100', label: '100'},
  { value: '1000', label: '1000'},
  { value: '10000', label: '10000'},
];

export default () => {

    const dispatch = useDispatch();
    const { appsInfo } = useSelector(state => state.appsSettings);

    const { register, control, handleSubmit, getValues } = useForm({
        defaultValues: appsInfo
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "manual"
    });

    const notify = () => toast.success("Заявката е запазена!");

    const keyDown = (e) => {
        if(e.key == "Enter") {
            e.currentTarget.blur();
        }
    };

    const saveData = (e) => {
        onSubmit(getValues());
    }

    const onSubmit = async (data) => {
        if(appsInfo && appsInfo.id && data) {
            await dispatch(updateBlocksFieldAction({
                "row":appsInfo,
                "column":"env",
                "value":data.env,
                "stopRedraw" : 1
            }));

            notify();
        }
    };

    const options2 = [];
    if(appsInfo.fields) {
        appsInfo.fields.forEach((v) => {
            if(v.label && v.label != "-") {
                options2.push({"label":v.label,"value":v.title_in_db});
            }
        })
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4 w-full">
                    <h3 className="text-xl leading-6 font-medium text-gray-900 w-full">
                        Заявки за листинг&nbsp;
                        <small className="text-sm text-gray-600">
                            (ID: {appsInfo.id})
                        </small>
                    </h3>
                </div>

                <div className="bg-gray-100 px-4 py-3 sm:px-6 pt-1">
                    <div className="w-full">
                        <div className="flex items-center pb-2">
                            <div className="flex-1">
                                <label className="framed w-full">
                                    <textarea placeholder=" " {...register('env.query')} name="env[query]" onBlur={(e) => saveData(e)} onKeyDown={(e) => keyDown(e)} />
                                    <span>Заявка в mysql база данни</span>
                                </label>
                            </div>
                        </div>

                        <div className="flex items-center pb-2">
                            <div className="flex-1">
                                <label className="framed w-full">
                                    <textarea placeholder=" " {...register('env.query_where')} name="env[query_where]" onBlur={(e) => saveData(e)} onKeyDown={(e) => keyDown(e)} />
                                    <span>WHERE на заявката</span>
                                </label>
                            </div>
                        </div>

                        <div className="flex items-center pb-1">
                            <div className="flex-1 mr-5">
                                <label className="framed w-full">
                                    <textarea placeholder=" " {...register('env.title')} name="env[title]" onBlur={(e) => saveData(e)} onKeyDown={(e) => keyDown(e)} />
                                    <span>Заглавие </span>
                                </label>
                            </div>
                            <div className="flex-1">
                                <label className="framed w-full">
                                    <textarea placeholder=" " {...register('env.subtitle')} name="env[subtitle]" onBlur={(e) => saveData(e)} onKeyDown={(e) => keyDown(e)} />
                                    <span>Подзаглавие </span>
                                </label>
                            </div>
                        </div>

                        <div className="flex items-center pb-2">
                            <div className="flex-1 mr-5">
                                <label className="framed w-full">
                                    <input placeholder=" " {...register('env.addButton')} name="env[addButton]" onBlur={(e) => saveData(e)} onKeyDown={(e) => keyDown(e)} />
                                    <span>Заглавие на бутон при добавяне</span>
                                </label>
                            </div>
                            <div className="flex-1 mr-5">
                                <label className="framed w-full">
                                    <input placeholder=" " {...register('env.addPath')} name="env[addPath]" onBlur={(e) => saveData(e)} onKeyDown={(e) => keyDown(e)} />
                                    <span>Пътечка при добавяне</span>
                                </label>
                            </div>
                            <div className="flex-1 mr-5">
                                <label className="framed w-full">
                                    <input placeholder=" " {...register('env.editPath')} name="env[editPath]" onBlur={(e) => saveData(e)} onKeyDown={(e) => keyDown(e)} />
                                    <span>Пътечка при редакция</span>
                                </label>
                            </div>
                            <div className="flex flex-row-reverse items-center pt-1">
                                <Controller
                                    name={`env[bulk]`}
                                    control={control}
                                    render={({ field }) => (
                                        <Switch onChange={(e) => {
                                            field.onChange(e);
                                            saveData(e);
                                          }}
                                          checked={field.value} />
                                    )}
                                />
                                <div className="mr-3 text-sm">Позволи триене от листинг:</div>
                            </div>
                        </div>

                        <div className="flex items-center pb-2">
                            <div className="flex flex-col flex-1 mr-5 mt-4">
                                <label className="text-sm">Сортирай по подразбиране (поле):</label>
                                <div>
                                    <Controller
                                      render={({ field }) => (<ReactSelect isSearchable={false} {...field} options={options2} />)}
                                      name={`env[sort]`}
                                      className="w-full text-sm"
                                      placeholder="Сортирай по подразбиране (поле)..."
                                      control={control}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col flex-1 mr-5 mt-4">
                                <label className="text-sm">В ред:</label>
                                <Controller
                                  render={({ field }) => (<ReactSelect isSearchable={false} {...field} options={orderSelect} />)}
                                  name={`env[order]`}
                                  className="w-full text-sm"
                                  placeholder="В ред"
                                  control={control}
                                />
                            </div>
                            <div className="flex flex-col flex-1 mr-5 mt-4">
                                <label className="text-sm">Брой резултати:</label>
                                <Controller
                                  render={({ field }) => (<ReactSelect isSearchable={false} {...field} options={orderLimit} />)}
                                  name={`env[limit]`}
                                  className="w-full text-sm"
                                  placeholder="Брой резултати"
                                  control={control}
                                />
                            </div>
                            <div className="flex flex-row-reverse items-center pt-1 mt-8">
                                <Controller
                                    name={`env[sortable]`}
                                    control={control}
                                    render={({ field }) => (
                                        <Switch onChange={(e) => {
                                            field.onChange(e);
                                            saveData(e);
                                          }}
                                          checked={field.value} />
                                    )}
                                />
                            <div className="mr-3 text-sm">Позволи сортиране:</div>
                            </div>
                        </div>


                    </div>

                </div>


                <div className="px-4 bg-gray-50 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                        Запази
                    </button>
                </div>

            </form>
        </>
);
};
