import React, { useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { loggedIn } from './actions/authActions'
import { useDispatch,useSelector } from "react-redux";
import { ToastContainer } from 'react-toastify';

//BOARD
import Dashboard from './pages/board/Dashboard'

//LOGIN
import Login from './pages/auth/Login'
import Register from './pages/auth/Register'
import Password from './pages/auth/Password'
import Reset from './pages/auth/Reset'

//PROJECTS
import AppsList from './pages/projects/apps/AppsList';
import AppsSettings from './pages/projects/apps/AppsSettings';
import ClientsList from './pages/projects/clients/ClientsList';
import ClientsProjects from './pages/projects/clients/ClientsProjects';
import ClientsEditProject from './pages/projects/clients/ClientsEditProject';

//PROJECTS
import DemoContent from './pages/board/panels/content/DemoContent';

import history from './history';

const App = () => {

  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(loggedIn());
  }, []);

  if (auth.isSignedIn === null) {
      return (<div></div>);
  }

  if (auth.isSignedIn === true) {
      return (
          <div>
              <Router history={history}>
                  <Switch>
                    <Route path="/" exact render={(props) => ( <ClientsList {...props} /> )} />
                    <Route path="/logout" exact component={Login} />

                    <Route path="/apps" exact render={(props) => ( <AppsList {...props}  /> )} />
                    <Route path="/apps/:id" exact render={(props) => ( <AppsSettings {...props}  /> )}  />
                    <Route path="/clients" exact render={(props) => ( <ClientsList {...props} /> )} />
                    <Route path="/clients/:id" exact render={(props) => ( <ClientsProjects {...props}  /> )} />
                    <Route path="/clients/:id/projects/:project" exact render={(props) => ( <ClientsEditProject {...props} /> )} />
                    <Route path="/demo" exact render={(props) => ( <DemoContent {...props}  /> )} />

                    <Route path='*' component={Dashboard} />
                  </Switch>
              </Router>
              <ToastContainer position="bottom-left" />
          </div>
      );
  }

  return (
    <div>
        <Router history={history}>
            <Switch>
              <Route path="/" exact component={Login} />
              <Route path="/login" exact component={Login} />
              <Route path="/register" exact component={Register} />
              <Route path="/password" exact component={Password} />
              <Route path="/reset/:id/:email" exact component={Reset} />

              <Route path='*' component={Login} />
            </Switch>
        </Router>
        <ToastContainer position="bottom-left" />
    </div>
  );
};

export default App;
