import React from "react";
import { usePopperTooltip } from 'react-popper-tooltip';

const ModalTaskTimersTooltip = ({label,longLabel,time,className} ) => {

    const {
       getArrowProps,
       getTooltipProps,
       setTooltipRef,
       setTriggerRef,
       visible,
    } = usePopperTooltip({},
    {
      placement:"top"
    });

    const split = time.split(":");
    const hours = split[0] ? parseInt(split[0].replace(/^0+/, '')) : 0;
    const minutes = split[1] ? parseInt(split[1].replace(/^0+/, '')) : 0;

    return (
        <>
        {time && (hours > 0 || minutes > 0) &&
            <div className={className ? className : "text-xs ml-2"}>
                <span ref={setTriggerRef}>
                    <span className="font-bold">{label} - </span> {time} ч.
                    {visible && (
                       <div
                         ref={setTooltipRef}
                         {...getTooltipProps({ className: 'tooltip-container' })}
                       >
                       <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                        {longLabel}
                        <b>
                            {hours > 1 && ` ${hours} часа и `}
                            {hours == 1 && ` ${hours} час и `}

                            {minutes > 1 && ` ${minutes} минути`}
                            {minutes == 1 && ` ${minutes} минута`}

                            {!hours && !minutes && ` не е работено`}
                        </b>
                       </div>
                     )}
                </span>
            </div>
        }
        </>
    );
}

export default ModalTaskTimersTooltip;
