import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { passwordApi } from './../../actions/authActions'

import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';

import urls from './../../env/urls';
import LeftImage from './panels/LeftImage';

export default function Password() {

  const { register, handleSubmit, formState: { errors } } = useForm({shouldFocusError: true});
  const onSubmit = (data) => {
      dispatch(passwordApi(data));
  };

  const dispatch = useDispatch();

  return (
    <div className="min-h-screen bg-white flex">
      <LeftImage />
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>

        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Забравена парола в <span className="logo-color">Giganot</span></h2>

          </div>

          <div className="mt-8">

            <div className="mt-6">
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-6" noValidate>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                     Имейл адрес
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    {errors.email?.type === 'required' &&
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      Имейл е задължително.
                    </p>
                    }
                    {errors.email?.type === 'pattern' &&
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      Невалиден имейл.
                    </p>
                    }
                  </div>
                </div>


                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Изпрати
                  </button>
                </div>
              </form>

              <p className="mt-7 text-sm text-gray-600">
                 Имаш регистрация?
                 <Link to={urls.login} className="font-medium ml-2 text-indigo-600 hover:text-indigo-500">
                   Вход
                 </Link>
               </p>
               <p className="mt-2 text-sm text-gray-600">
                  Нямаш регистрация?
                  <Link to={urls.register} className="font-medium ml-2 text-indigo-600 hover:text-indigo-500">
                    Регистрация
                  </Link>
                </p>

            </div>
          </div>
        </div>
      </div>
    </div>
);
}
