import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect, { makeAsyncSelect } from 'react-select/async';
import appsApi from '../../../../env/apis/projects/appsApi';

export default ({ column,row,defaultOptions,updateBlocksFieldAction }) => {

    const dispatch = useDispatch();
    const defaultValue = row[column];
    const handleChange = (column,row,updateBlocksFieldAction,value) => {
        dispatch(updateBlocksFieldAction({ column, row, value }));
    }

    const loadOptions = async (inputValue) => {
        const data = [];
        const response = await appsApi.get('/blocks/search?q=' + inputValue);
        response.data.data.forEach((v) => {
            data.push({...v,"label":v.app_name,"value":v.id});
        })
        return data;
    }

    const options = [];

    if(defaultOptions) {
        defaultOptions.forEach((v) => {
            options.push({...v,"label":v.app_name,"value":v.id});
        })
    }


    return (
        <>
            <AsyncSelect key={row.id+column} defaultOptions={options} defaultValue={defaultValue} loadOptions={loadOptions} cacheOptions onChange={(e) => handleChange(column,row,updateBlocksFieldAction,e)} className="w-full text-left" />
        </>
    )
}
