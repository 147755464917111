import React from 'react';

import Path from './Path'
import Back from './Back'

export default function ActionBar({crums,back,title,buttons,searchIn,clearSearchAction,searchAction}) {
    return (
        <>
            <div className="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b border-gray-300 pb-2 mb-3">
              <div>
                <h1 className="text-2xl font-bold text-gray-900">{title}</h1>
                <div className="flex flex-row">
                    <Back back={back} />
                    <Path crums={crums} />
                </div>
              </div>
              <div className="mt-4 flex space-x-3 md:mt-0">
                {buttons}
              </div>
            </div>
        </>
    );
}
