import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

export default ({ column,row,updateBlocksFieldAction }) => {

    const dispatch = useDispatch();
    const defaultValue = row[column];
    const handleBlur = (column,row,updateBlocksFieldAction,e) => {
        const value = e.target.value;
        dispatch(updateBlocksFieldAction({ column, row, value }));
        setShowSaveLabel(false);
    }

    const handleKeyDown = (column,row,updateBlocksFieldAction,e) => {
        if(e.key == "Enter") {
            // handleBlur(column,row,updateBlocksFieldAction,e);
            e.currentTarget.blur();
        }
    }

    const [inputValue, setInputValue] = useState(defaultValue);
    const [showSaveLabel, setShowSaveLabel] = useState(false);

    return (
        <>
            <div className="flex">
                <input key={row.id+column} type="text"
                    className="bg-white rounded-sm flex items-center w-full px-3 py-2 text-sm shadow-sm border border-gray-200 flex-grow"
                    defaultValue={defaultValue} onKeyDown={(e) => handleKeyDown(column,row,updateBlocksFieldAction,e)} onBlur={(e) => handleBlur(column,row,updateBlocksFieldAction,e)} onFocus={() => { setShowSaveLabel(true) }} onChange={setInputValue}
                />
                <button tabIndex="-1" onClick={(e) => handleBlur(column,row,updateBlocksFieldAction,e)} className={!showSaveLabel ? "text-green-500 ml-3 hidden" : "text-green-500 ml-3"}>
                    <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </button>
             </div>
    </>
    )
}
