import React,{useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";

import { SvgAdd,SvgBack,SvgHuman,SvgEye,SvgOk,SvgEdit } from '../../../parts/blocks/Svg';
import { addProject,updateProject } from '../../../../../actions/projects/clientsProjectAction'
import history from '../../../../../history';
import moment from 'moment';

import filtersOptions from '../../../../../env/apis/projects/data/filtersOptions';
const filtersChoose = [...filtersOptions];

if(filtersChoose[0].value == "") {
    if(filtersChoose[0].value == "") {
        filtersChoose.shift();
    }
    filtersChoose.push({"value":"","label":"Неразпределени"});
    filtersChoose.push({"value":"archived","label":"Архивирани"});
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default () => {

    const dispatch = useDispatch();

    const { clientInfo } = useSelector(state => state.clients);

    const createProject = async () => {
        await dispatch(addProject({clientInfo}));
    }

    const handleBlur = (card,e) => {
        e.stopPropagation();
        const value = e.target.value;
        dispatch(updateProject({
            "project":card,
            clientInfo,
            update:{ "title":value }
        }));
        setShowSaveLabel("");
        setStartEdit("");
    }

    const handleKeyDown = (card,e) => {
        e.stopPropagation();
        if(e.key == "Enter") {
            e.currentTarget.blur();
        }
    }

    const startEditInput = (card,e) => {
        e.stopPropagation();
        setStartEdit(card._id);
    }

    const startFocus = (card,e) => {
        e.stopPropagation();
        setShowSaveLabel(card._id);
    }

    const startClick = (card,e) => {
        e.stopPropagation();
    }

    const goTo = (url) => {
        history.push(url);
    }

    const [inputValue, setInputValue] = useState("");
    const [startEdit, setStartEdit] = useState("");
    const [showSaveLabel, setShowSaveLabel] = useState("");

    let filterdProjects = [];
    if(clientInfo && clientInfo.projects) {
        filterdProjects = filtersChoose.map(item => {
            return {...item,"projects":clientInfo.projects.filter(card => {
                if(item.value == "archived" && !card.isActive) {
                    return card;
                }
                else if(!item.value && card.isActive && (!card.currentFilter || !card.currentFilter.label)) {
                    return card;
                }
                else if(item.value && card.isActive && card.currentFilter && card.currentFilter.value == item.value) {
                    return card;
                }
                else if(!item.value && card.isActive &&  (!card.currentFilter || !card.currentFilter.value)) {
                    return card;
                }
                else {
                    return false;
                }
            })}
        });
    }

    return (
        <>
            <div className="flex flex-col flex-wrap mt-5 px-0">
                {!clientInfo.projects && <div className="bg-gray-100 py-5 text-center w-full font-semibold"> Няма създадени проекти за: {clientInfo.name} </div>}

                {filterdProjects.map((flt,ind) => {
                    if(flt.projects && flt.projects.length > 0) {
                        return (
                            <div key={`${ind}t`} className={(flt.value == "archived" || flt.value == "") ? "overflow-hidden sm:rounded-lg sm:shadow mb-10  bg-gray-200 opacity-30" : "overflow-hidden sm:rounded-lg sm:shadow mb-10  bg-gray-200"}>
                                <div className="bg-gray-800 px-4 py-4 border-b border-gray-200 sm:px-3">
                                    <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                                      <div className="ml-4 mt-2">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            <div
                                                className={(flt.value == "archived" || flt.value == "") ? "w-full pl-5 text-2xl text-gray-400 font-light" : "w-full pl-5 text-2xl text-gray-300 uppercase tracking-wider font-semibold"}
                                            >
                                                {flt.label.toUpperCase()}
                                            </div>
                                        </h3>
                                      </div>
                                      <div className="ml-4 mt-2 flex-shrink-0">
                                          {/* BUTTON */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className={flt.value != "archived" ? "pb-5 px-5 pt-5" : "pb-5 px-5 pt-5 "}>
                                      <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2">
                                          {flt.projects.map(card => {
                                              var clientLink = "/clients/"+clientInfo.id+"/projects/"+card._id;
                                              return (
                                                  <li key={card._id} onClick={() => goTo(clientLink)} className="col-span-1 bg-white hover:bg-yellow-50 rounded-lg shadow cursor-pointer">
                                                      <div className={flt.value != "archived" ? "w-full divide-y divide-gray-200" : "w-full divide-y divide-gray-200"}>
                                                           <div className="w-full flex items-center justify-between p-6 space-x-6">
                                                               <div className="flex-1 truncate">
                                                                  <div className="flex items-center space-x-3">
                                                                    <h3 className="text-gray-900 text-sm font-medium truncate">
                                                                        {startEdit != card._id &&
                                                                            <div className="flex">

                                                                                <div className="flex-grow font-medium text-xl">
                                                                                    {card.title}
                                                                                </div>

                                                                                <div className="flex ml-1">
                                                                                    <button  onClick={(e) => { startEditInput(card,e) }}>
                                                                                        <SvgEdit classes="w-6 h-6 text-gray-200" />
                                                                                    </button>
                                                                                </div>

                                                                            </div>
                                                                        }

                                                                        {startEdit && startEdit == card._id &&
                                                                            <div onClick={(e) => startClick(card,e)} className="flex">
                                                                                <input type="text"
                                                                                    className="bg-white rounded-sm flex items-center w-full px-3 py-2 shadow-sm border border-gray-200 flex-grow"
                                                                                    defaultValue={card.title}
                                                                                    onKeyDown={(e) => handleKeyDown(card,e)}
                                                                                    onBlur={(e) => handleBlur(card,e)}
                                                                                    onFocus={(e) => startFocus(card,e)}
                                                                                    onChange={setInputValue}
                                                                                    />
                                                                                <button tabindex="-1" onClick={(e) => handleBlur(e)} className={showSaveLabel && showSaveLabel == card._id ? "text-green-500 ml-3" : "text-green-500 ml-3 hidden"}>
                                                                                    <SvgOk classes="w-6 h-6" />
                                                                                </button>
                                                                            </div>
                                                                        }
                                                                    </h3>
                                                                    {card.currentFilter.value &&

                                                                        <span className={classNames( flt.value != "archived" ? 'bg-green-100' : 'bg-orange-100', 'flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium  rounded-full' )}>
                                                                            {flt.value != "archived" ? card.currentFilter.label : "архивиран"}
                                                                        </span>
                                                                    }

                                                                  </div>
                                                                  {card.domain &&
                                                                      <a href="http://{card.domain}" target="_blank" className="mt-1 text-gray-500 text-sm truncate">{card.domain}</a>
                                                                  }
                                                                </div>
                                                                <img className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0" src="/img/logo-rose.svg" alt="" />
                                                           </div>


                                                          <div className="flex items-center justify-center text-gray-500 mt-1 divide-x divide-gray-200">
                                                              {(card.currentMonth && card.currentMonth.deal) ?
                                                                  <span className="flex-1 text-xs  text-gray-400 pr-5 pl-5 mt-1 mb-1">
                                                                      договорени: <br />
                                                                      {card.currentMonth.deal} час{card.currentMonth.deal == 1 ? "" : "а"}
                                                                  </span> : ""
                                                              }

                                                              {(!card.currentMonth && card.deal) ?
                                                                  <span className="flex-1 text-xs  text-gray-400 pr-5 pl-5 mt-1 mb-1">
                                                                      договорени: <br />
                                                                      {card.deal} час{card.deal == 1 ? "" : "а"}
                                                                  </span> : ""
                                                              }

                                                              {(card.currentMonth && card.currentMonth.income && 1==2) ?
                                                                  <span className="flex-1 text-xs  text-gray-400 pr-5 pl-5 mt-1 mb-1">
                                                                      на час: <br />
                                                                  {card.currentMonth.income} лв.
                                                                  </span> : ""
                                                              }

                                                              {(card.currentMonth && card.currentMonth.workedH) ?
                                                                  <span className="flex-1 text-xs  text-gray-400 pr-5 pl-5 mt-1 mb-1">
                                                                      работил: <br />
                                                                      {card.currentMonth.workedH} час{card.currentMonth.workedH == 1 ? "" : "а"}
                                                                  </span> : ""
                                                              }

                                                              {(card.currentMonth && card.currentMonth.accountedH) ?
                                                                  <span className="flex-1 text-xs  text-gray-400  pr-5 pl-5 mt-1 mb-1">
                                                                      отчел: <br />
                                                                      {card.currentMonth.accountedH} ч
                                                                  </span> : ""
                                                              }

                                                              {(card.currentMonth && card.currentMonth.ending) ?
                                                                  <span className={card.currentMonth.ending < 1 ? "text-green-300 text-xs mb-1 pr-5 pl-5 mt-1 flex-1" : "text-red-200 text-xs mb-1 pr-5 pl-5 mt-1 flex-1"}>
                                                                      {card.currentMonth.ending < 1 ? "отгоре с" : "още"}:<br /> {card.currentMonth.ending < 0 ? card.currentMonth.ending*-1 : card.currentMonth.ending} час{card.currentMonth.ending == 1 || card.currentMonth.ending == -1 ? "" : "а"}
                                                                  </span> : ""
                                                              }

                                                              {card.currentFilter.value && 1==2 &&
                                                                  <div className="text-xs text-gray-400 mb-1">
                                                                      етап: <br />
                                                                  <span className="text-gray-300">{card.currentFilter.label}</span>
                                                                  </div>
                                                              }

                                                              {(!card.timerTask && !card.currentMonth) &&
                                                                  <div className="flex flex-row items-center pr-5 pl-5 py-3">
                                                                      <SvgEye classes="w-4 h-4 mr-1 text-gray-500" />
                                                                      <span className="text-sm text-gray-500">Не е работено този месец</span>
                                                                  </div>
                                                              }

                                                              {card.timerTask &&
                                                                  <div className="flex flex-row-reverse flex-row px-5 items-center animate-pulse">
                                                                      <span className="text-sm text-red-400">Работещ таймер</span>
                                                                      <SvgEye classes="w-5 h-5 mr-1 text-red-300" />
                                                                  </div>
                                                              }
                                                          </div>
                                                      </div>
                                                  </li>
                                              );
                                          })}
                                      </ul>
                                  </div>

                            </div>
                        )
                    }
                })}



            </div>
        </>
    );
};
