export const PAGES_SHORT_SIDEBAR  = 'PAGES_SHORT_SIDEBAR';

export const ADD_ROW              = 'ADD_ROW';
export const DELETE_ROW           = 'DELETE_ROW';
export const UPDATE_ROW           = 'UPDATE_ROW';
export const UPDATE_FIELD         = 'UPDATE_FIELD';
export const GET_ROW              = 'GET_ROW';
export const GET_ROWS             = 'GET_ROWS';
export const MODAL                = 'MODAL';
export const APPS_LIST            = 'APPS_LIST';
export const UPDATE_APPINFO       = 'UPDATE_APPINFO';

export const ADD_ROW_LIST          = 'ADD_ROW_LIST';
export const DELETE_ROW_LIST       = 'DELETE_ROW_LIST';
export const UPDATE_ROW_LIST       = 'UPDATE_ROW_LIST';
export const UPDATE_FIELD_LIST     = 'UPDATE_FIELD_LIST';
export const GET_ROW_LIST          = 'GET_ROW_LIST';
export const GET_ROWS_LIST         = 'GET_ROWS_LIST';
export const DRAW_FIELDS_SELECT    = 'DRAW_FIELDS_SELECT';

export const CLIENTS_ADD_ROW      = 'CLIENTS_ADD_ROW';
export const CLIENTS_DELETE_ROW   = 'CLIENTS_DELETE_ROW';
export const CLIENTS_UPDATE_ROW   = 'CLIENTS_UPDATE_ROW';
export const CLIENTS_UPDATE_FIELD = 'CLIENTS_UPDATE_FIELD';
export const CLIENTS_GET_ROW      = 'CLIENTS_GET_ROW';
export const CLIENTS_GET_ROWS     = 'CLIENTS_GET_ROWS';
export const CLIENTS_CLIENTINFO   = 'CLIENTS_CLIENTINFO';

export const CLIENTS_SETTINGS_ADD_ROW      = 'CLIENTS_SETTINGS_ADD_ROW';
export const CLIENTS_SETTINGS_DELETE_ROW   = 'CLIENTS_SETTINGS_DELETE_ROW';
export const CLIENTS_SETTINGS_UPDATE_ROW   = 'CLIENTS_SETTINGS_UPDATE_ROW';
export const CLIENTS_SETTINGS_UPDATE_FIELD = 'CLIENTS_SETTINGS_UPDATE_FIELD';
export const CLIENTS_SETTINGS_GET_ROW      = 'CLIENTS_SETTINGS_GET_ROW';
export const CLIENTS_SETTINGS_GET_ROWS     = 'CLIENTS_SETTINGS_GET_ROWS';
export const CLIENTS_SETTINGS_MODAL        = 'CLIENTS_SETTINGS_MODAL';
export const CLIENTS_APPS_LIST             = 'CLIENTS_APPS_LIST';
export const CLIENTS_SETTINGS_CLIENTINFO   = 'CLIENTS_SETTINGS_CLIENTINFO';
export const CLIENTS_SETTINGS_TASKS_MODAL  = 'CLIENTS_SETTINGS_TASKS_MODAL';
export const CLIENTS_SETTINGS_FILTERS      = 'CLIENTS_SETTINGS_FILTERS';

export const CLIENTS_TASKS_ADD_ROW      = 'CLIENTS_TASKS_ADD_ROW';
export const CLIENTS_TASKS_DELETE_ROW   = 'CLIENTS_TASKS_DELETE_ROW';
export const CLIENTS_TASKS_START_EDIT   = 'CLIENTS_TASKS_START_EDIT';

export const CLIENTS_PROJECT_ADD_ROW     = 'CLIENTS_PROJECT_ADD_ROW';
export const CLIENTS_PROJECT_DELETE_ROW  = 'CLIENTS_PROJECT_DELETE_ROW';
export const CLIENTS_PROJECT_UPDATE_ROW  = 'CLIENTS_PROJECT_UPDATE_ROW';
export const CLIENTS_PROJECTS            = 'CLIENTS_PROJECTS';
export const CLIENTS_PROJECTINFO         = 'CLIENTS_PROJECTINFO';
export const CLIENTS_TASKS_ACCOUNTED     = 'CLIENTS_TASKS_ACCOUNTED';

export const CLIENTS_SETTINGS_ADD_RESEARCH          = 'CLIENTS_SETTINGS_ADD_RESEARCH';
export const CLIENTS_SETTINGS_RESEARCH_DELETE       = 'CLIENTS_SETTINGS_RESEARCH_DELETE';
export const CLIENTS_SETTINGS_MODAL_RESEARCH        = 'CLIENTS_SETTINGS_MODAL_RESEARCH';
export const CLIENTS_SETTINGS_GET_RESEARCH          = 'CLIENTS_SETTINGS_GET_RESEARCH';
export const CLIENTS_SETTINGS_UPDATE_RESEARCH       = 'CLIENTS_SETTINGS_UPDATE_RESEARCH';

export const CLIENTS_SETTINGS_ADD_PR          = 'CLIENTS_SETTINGS_ADD_PR';
export const CLIENTS_SETTINGS_PR_DELETE       = 'CLIENTS_SETTINGS_PR_DELETE';
export const CLIENTS_SETTINGS_MODAL_PR        = 'CLIENTS_SETTINGS_MODAL_PR';
export const CLIENTS_SETTINGS_GET_PR          = 'CLIENTS_SETTINGS_GET_PR';
export const CLIENTS_SETTINGS_UPDATE_PR       = 'CLIENTS_SETTINGS_UPDATE_PR';
export const CLIENTS_LATEST_TASKS             = 'CLIENTS_LATEST_TASKS';
