import React, { useState, useRef, useEffect } from "react";
import Switch from "react-switch";
import ReactSelect from 'react-select'
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import bg from 'date-fns/locale/bg';
import { toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';
import history from '../../../../../history';
import moment from 'moment';
import _max from 'lodash.max';
import _find from 'lodash.find';

//Actions
import { getProject,updateProject } from '../../../../../actions/projects/clientsProjectAction'
import { editAccounts,deleteAccount,getAccountedByDate } from '../../../../../actions/projects/clientsTasksAction'

//Styles
import { SvgPlus,SvgSpin,SvgMinus,SvgImage,SvgClock,SvgOk } from '../../../parts/blocks/Svg';

registerLocale('bg-bg', bg);

const Accounted = (row) => {

    const dispatch = useDispatch();
    const { clientInfo } = useSelector(state => state.clients);
    const { project  } = useSelector(state => state.clientsProject);
    const { accounted } = useSelector(state => state.clientsTasks);
    const [saveAnimationAdd, setSaveAnimationAdd] = useState(false);
    const [saveAnimationRemove, setSaveAnimationRemove] = useState(false);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(moment().format("MM-YYYY"));

    const editorRefResourse = useRef();

    project.accounted = accounted;

    const { register, control, reset, getValues, handleSubmit } = useForm({
        defaultValues: project
    });

    project.income = project.accounted && project.accounted[0] && project.accounted[0].income ? project.accounted[0].income : project.income;
    project.deal = project.accounted && project.accounted[0] && project.accounted[0].deal_project ? project.accounted[0].deal_project : project.deal;
    if(!project.deal) {
        project.deal = 0;
    }

    let total = 0;
    let worked = 0;

    project.accounted.map((item)=> {
        total += parseInt(item.accounted);
        worked += parseInt(item.time);
    });

    let ending = parseInt(project.deal)-total;
    if(!ending) { ending = 0; }
    let workedH = moment.utc(worked*1000).format('H');
    let earning = parseInt(project.deal)*parseInt(project.income);
    if(!earning) { earning = 0; }
    let monthlyEarning = total*parseInt(project.income);
    if(!monthlyEarning) { monthlyEarning = 0; }
    let inProfit = monthlyEarning-earning;
    if(!inProfit) { inProfit = 0; }

    const {
      fields: accountedFields,
      append: accountedAppend,
      remove: accountedRemove,
      move  : accountedMove
   } = useFieldArray({ control, name: "accounted" });

    const handleEditorChange = (value) => {
    };

    const handleKeyDown = (e) => {
        if(e.key == "Enter") {
            e.currentTarget.blur();
        }
    }

    let timeOutId = null;

    const handleKeyUp = (e) => {
        // clearTimeout(timeOutId)
        // timeOutId = setTimeout(() => saveData(), 100);
    }

    const saveData = (e) => {
        let update = true;
        const values = getValues();
        // if(project.accounted.length != values.length) {
        //     update = true;
        // }
        // else {
        //     project.accounted.map((item,index) => {
        //         if(!_find(values,item)) {
        //             update = true;
        //         }
        //     });
        // }
        if(update) {
            onSubmit(values);
        }
    }

    const toastMe = () => {
        toast.success("Данните са запазени!")
    };

    const onSubmit = async (data) => {
        if(!data.accounted) {
            data.accounted = [];
        }
        if(project && project._id && data && data.accounted) {
            toastMe();

            data.deal_description = "";
            if(editorRefResourse && editorRefResourse.current && editorRefResourse.current.currentContent) {
                data.deal_description = editorRefResourse.current.currentContent;
            }

            const accounted = data.accounted.map((item,index) => {
                if(item.date) {
                    return {...item,"date":moment(item.date,"MM-YYYY").format("MM-YYYY"),"time":parseInt(item.time),"deal_project":data.deal,"income":data.income};
                }
            });

            if(data.deal_description != project.deal_description || project.deal != data.deal || project.income != data.income) {
                project.deal = data.deal;
                project.income = data.income;
                project.deal_description = data.deal_description;
                await dispatch(updateProject({
                    newState:{...project,"deal":data.deal,"income":data.income,"deal_description":data.deal_description},
                    update:data,
                    project,
                    clientInfo
                }));
            }

            await dispatch(editAccounts({update:accounted,project:project}));

            project.accounted = await dispatch(getAccountedByDate({"project":project,"params":{"date":date}}));
            reset(project);

            await dispatch(getProject({"project":project._id}));
        }
    };

    const saveRemove = async (item) => {

        if(!saveAnimationRemove) {
            setSaveAnimationRemove(true);
            await dispatch(deleteAccount({row:item}));
            project.accounted = await dispatch(getAccountedByDate({"project":project,"params":{"date":date}}));
            reset(project);
            await dispatch(getProject({"project":project._id}));
        }

        setSaveAnimationRemove(false);
    };

    const addRow = async (date) => {
        if(!saveAnimationAdd) {
            setSaveAnimationAdd(true);
            await accountedAppend({ title: "","manual":1, date: date, time:0, accounted:0 });
            await saveData();
            setSaveAnimationAdd(false);
        }
    }

    const changeDate = async (date) => {
        if(date) {
            setLoading(true);
            setDate(moment(date,"MM-YYYY").format("MM-YYYY"));
            project.accounted = await dispatch(getAccountedByDate({"project":project,"params":{"date":moment(date,"MM-YYYY").format("MM-YYYY")}}));
            reset(project);
            setLoading(false);
        }

    }

    useEffect(async () => {
        project.accounted = await dispatch(getAccountedByDate({"project":project,"params":{"date":date}}));
        project.income = project.accounted && project.accounted[0] && project.accounted[0].income ? project.accounted[0].income : project.income;
        project.deal = project.accounted && project.accounted[0] && project.accounted[0].deal_project ? project.accounted[0].deal_project : project.deal;

        reset(project);
    }, []);


    return (
        <>
        {loading && <div className="flex items-center justify-center p-10 bg-gray-400"><SvgSpin classes="h-14 w-14 text-gray-100" /></div>}
        {!loading &&
            <>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="flex mt-4 flex-inline items-center bg-gray-50 px-4 pъ-5 sm:p-6 sm:pb-4">
                        <div className="text-xl leading-6 font-medium text-gray-900">
                            Отчет&nbsp;
                            <small className="text-base text-red-600">
                                (за месец: {moment(date,"MM-YYYY").format("MM/YYYY")})
                            </small>
                            <small className="text-sm text-gray-600 ml-5">
                                (проект: {project.title})
                            </small>
                        </div>
                        <div className="flex flex-grow flex-row-reverse z-50">
                            <div className="-mt-2">
                                <label className="framed flex-grow">
                                    <ReactDatePicker
                                        onChange={date => changeDate(date)}
                                        selected={new Date(moment(date,"MM-YYYY").format())}
                                        placeholderText="Месец"
                                        popperPlacement="top-bottom"
                                        dateFormat="MM/yyyy"
                                        showMonthYearPicker
                                        className="normal-input w-full"
                                        locale="bg-bg"
                                        placeholder=" "
                                        />
                                    <span>Отчет за дата</span>
                                </label>
                            </div>
                            <div>
                                <label className="framed flex-grow -mt-2 mr-10">
                                    <input placeholder=" " autoComplete="off" {...register(`deal`)}  defaultValue={project.deal} name={`deal`} onBlur={(e) => saveData(e)} onKeyDown={(e) => handleKeyDown(e)} />
                                    <span>Месечни часове</span>
                                </label>
                            </div>

                            <div>
                                <label className="framed flex-grow -mt-2 mr-10">
                                    <input placeholder=" " autoComplete="off" {...register(`income`)}  defaultValue={project.income} name={`income`} onBlur={(e) => saveData(e)} onKeyDown={(e) => handleKeyDown(e)} />
                                    <span>Цена на час</span>
                                </label>
                            </div>

                        </div>
                    </div>


                    <div className="bg-gray-100 py-3 px-6 pt-1 ">
                        <div className="w-full mt-5 z-10 relative">

                            <ul className="w-full">
                                {accountedFields.map((item, index) => {
                                    return (
                                        <li key={item.id} className="flex items-center pb-2">

                                            <div className="flex mr-5 hidden">
                                                <label className="framed flex-grow">
                                                    <Controller
                                                        name={`accounted[${index}].date`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <ReactDatePicker
                                                                placeholderText="Дата"
                                                                selected={(field.value && field.value != 'Invalid date') ? new Date(moment(field.value,"MM-YYYY").format()) : new Date(moment(date,"MM-YYYY").format())}
                                                                name={`accounted[${index}].date`}
                                                                popperPlacement="top-start"
                                                                dateFormat="MM/yyyy"
                                                                disabled
                                                                showMonthYearPicker
                                                                className="normal-input w-full"
                                                                locale="bg-bg"
                                                                placeholder=" "
                                                                />
                                                        )}
                                                        />
                                                    <span>Дата</span>
                                                </label>

                                                <input type="hidden" {...register(`accounted[${index}].manual`)}  name={`accounted[${index}].manual`} defaultValue={item.manual} />
                                                <input type="hidden" {...register(`accounted[${index}]._id`)}  name={`accounted[${index}]._id`} defaultValue={item._id} />
                                            </div>

                                            <div className="flex-grow flex mr-5">
                                                {item.task &&
                                                    <div className="flex w-full">
                                                        <div className="flex flex-grow">
                                                            <label className="framed w-full">
                                                                <input placeholder=" " disabled defaultValue={item.task.title} name={`accounted[${index}].title`} />
                                                                <span>Задача</span>
                                                            </label>
                                                        </div>
                                                        {item.task.deal &&
                                                            <label className="framed ml-5">
                                                                <input placeholder=" " disabled defaultValue={item.task.deal} name={`accounted[${index}].deal`} />
                                                                <span>Договорени</span>
                                                            </label>
                                                        }
                                                    </div>
                                                }
                                                {!item.task &&
                                                    <div className="flex w-full">
                                                        <div className="flex flex-grow">
                                                            <label className="framed flex-grow">
                                                                <input placeholder=" " autoComplete="off" {...register(`accounted[${index}].title`)}  defaultValue={item.title} name={`accounted[${index}].title`} onBlur={(e) => saveData(e)} onKeyDown={(e) => handleKeyDown(e)} onKeyUp={(e) => handleKeyUp(e)} />
                                                                <span>Задача</span>
                                                            </label>
                                                        </div>
                                                        <label className="framed ml-5">
                                                            <input placeholder=" " autoComplete="off" {...register(`accounted[${index}].deal`)}  defaultValue={item.deal} name={`accounted[${index}].deal`} onBlur={(e) => saveData(e)} onKeyDown={(e) => handleKeyDown(e)} onKeyUp={(e) => handleKeyUp(e)} />
                                                            <span>Договорени</span>
                                                        </label>
                                                    </div>
                                                }
                                            </div>

                                            <div className="flex mr-5">
                                                <label className="framed flex-grow">
                                                    <input placeholder=" " disabled defaultValue={moment.utc(item.time*1000).format('HH:mm')} />
                                                    <input type="hidden" {...register(`accounted[${index}].time`)}  name={`accounted[${index}].time`} defaultValue={item.time} />
                                                    <span>Работил</span>
                                                </label>
                                            </div>

                                            <div className="flex mr-5">
                                                <label className="framed flex-grow">
                                                    <input placeholder=" " autoComplete="off" {...register(`accounted[${index}].accounted`)} name={`accounted[${index}].accounted`} defaultValue={item.accounted} onBlur={(e) => saveData(e)} onKeyDown={(e) => handleKeyDown(e)} onKeyUp={(e) => handleKeyUp(e)} />
                                                    <span>Отчетен</span>
                                                </label>
                                            </div>

                                            {item.manual == 1 || (item.accounted == 0 && item.time == 0) ?
                                                <div className="flex-2">
                                                    <button type="button" className="flex items-center justify-center h-9 w-9 rounded bg-red-200 focus:outline-none"
                                                        onClick={() => saveRemove(item)}
                                                        >
                                                        {!saveAnimationRemove && <SvgMinus classes="w-4 h-4 text-red-600" />}
                                                        {saveAnimationRemove && <SvgSpin classes="h-4 w-4 text-gray-600" />}
                                                    </button>
                                                </div>
                                                :
                                                <div className="flex-2">
                                                    <button type="button" className="flex h-9 w-9 bg-gray-100 cursor-default">
                                                        &nbsp;
                                                    </button>
                                                </div>
                                            }

                                        </li>
                                    );
                                })}
                            </ul>

                            <div className="flex items-center pb-2 mt-3">
                                <button type="button" className="flex items-center text-green-600 justify-center py-2 px-6 shadow rounded bg-green-200 focus:outline-none"
                                    onClick={() => addRow(date)}
                                    >
                                    Добави задача
                                    {!saveAnimationAdd && <SvgPlus classes="w-5 h-5" />}
                                    {saveAnimationAdd && <SvgSpin classes="h-5 w-5 ml-2 text-gray-600" />}
                                </button>
                                <div className="flex flex-grow items-center text-sm flex-row-reverse">
                                    <div className="bold font-light mr-7">
                                        Месечна печалба: <br /> {monthlyEarning} лев{monthlyEarning == 1 ? "" : "а"}
                                    </div>
                                    <div className={inProfit > 0 ? "bold text-green-500 font-light mr-7" : "bold text-red-500 font-light mr-7"}>
                                        {inProfit < 1 ? "Минус" : "Плюс"}: <br /> {inProfit} лев{inProfit == 1 ? "" : "а"}
                                    </div>
                                    <div className="bold font-light mr-7">
                                        Договорена Сума: <br /> {earning} лев{earning == 1 ? "" : "а"}
                                    </div>
                                    <div className={ending < 1 ? "bold text-green-500 font-medium mr-7" : "bold text-red-500 font-medium mr-7"}>
                                        {ending < 1 ? "Отгоре с" : "Още"}:<br /> {ending < 0 ? ending*-1 : ending} час{ending == 1 || ending == -1 ? "" : "а"}
                                    </div>
                                    <div className="bold font-medium mr-7">
                                        Отчел: <br /> {total} час{total == 1 ? "" : "а"}
                                    </div>
                                    <div className="bold font-light mr-7">
                                        Работил:<br /> {workedH} час{workedH == 1 ? "" : "а"}
                                    </div>
                                    <div className="bold font-light mr-7">
                                        Договорени:<br /> {project.deal} час{project.deal == 1 ? "" : "а"}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </form>

                <div className="flex flex-inline flex-col bg-gray-50 px-10 py-9">
                    <div className="flex pb-2 font-medium text-base">
                        Записки
                    </div>

                    <div className="w-full z-40 pb-5">
                        <Editor
                            initialValue={project.deal_description}
                            ref={editorRefResourse}
                            init={{
                                height: 350,
                                menubar: false,
                                relative_urls : false,
                                mode: "textareas",
                                paste_as_text: true,
                                browser_spellcheck: true,
                                contextmenu: false,
                                object_resizing : true,
                                valid_elements: "*[*]",
                                fontsize_formats: "16px 18px 20px 22px 24px 30px 36px 48px",
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor textcolor pagebreak tabfocus directionality autosave',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code wordcount'
                                ],
                                toolbar:
                                'undo redo | bold italic forecolor backcolor fontsizeselect | \
                                alignleft aligncenter alignright alignjustify blockquote | \
                                bullist numlist removeformat outdent indent | link insert media imageButton | code'
                            }}
                            onEditorChange={(value) => handleEditorChange(value)}
                            />
                    </div>

                    <div className="px-0 bg-gray-50 mb-10 py-3 sm:px-0 sm:flex sm:flex-row-reverse">
                        <button
                            type="button"
                            onClick={(e) => saveData(e)}
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-200 text-green-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
                            Запази
                        </button>
                    </div>
                </div>
            </>
        }

        </>
);
};

export default Accounted;
