import React from 'react';
import { useDispatch } from "react-redux";

export default ({ row, column, deleteBlock }) => {
    const dispatch = useDispatch();

    const deleteRow = async (row,deleteBlock) => {
        await dispatch(deleteBlock({ row, "id" : row.id }));
    }

    return (
        <>
            <button key={row.id+column} onClick={() => deleteRow(row,deleteBlock)} >
                <svg className="w-5 h-5 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </button>
        </>
    )
}
