import React, { useState,useEffect,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Draggable } from "react-smooth-dnd";
import Modal from '../modals/ModalTask'
import { Editor } from '@tinymce/tinymce-react';
import _find from 'lodash.find';
import _filter from 'lodash.filter';
import _findIndex from 'lodash.findindex';
import { usePopper } from 'react-popper';
import { TaskDropDown } from './TaskDropDown';
import { Timer } from './Timer';
import moment from 'moment';

//Actions
import { getProject,updateProject,openCloseTasksModalAction } from '../../../../../actions/projects/clientsProjectAction'
import { addTask,startEditTask,timer } from '../../../../../actions/projects/clientsTasksAction'

//Styles
import { SvgAdd,SvgPlus,SvgDots,SvgEye,SvgOk,SvgClock } from '../../../parts/blocks/Svg';

const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

const clearChildren = (tabs) => {
    return tabs.map((tab) => {
        if(tab.children) {
            return {...tab,"children":tab.children.map((card) => {
                if(card._id) {
                    return {"_id":card._id}
                }
                return false;
            })};
        }

        return {...tab,"children":[]};

    });
}

const mapTasksToTabs = (tabs, tasks) => {


    const results =  tabs.map((tab) => {

        if(tab.children && tasks && tasks.length > 0) {
            const tasksRemap = {...tab,"children":tab.children.map((card) => {
                let temp = _find(tasks,{"_id":card._id});
                if(temp && temp._id) {
                    tasks = tasks.filter((item) => temp._id !== item._id);
                    return temp;
                }
                else {
                    return false;
                }
            })};

            if(tasksRemap && tasksRemap.children && tasksRemap.children.length > 0) {
                return {...tasksRemap,"children":tasksRemap.children.filter((item) => item && item._id)};

            }

            return tasksRemap;
        }

        return {...tab,"children":[]}

    });

    if(tasks && tasks.length > 0 && results && results[0]) {

        let index = 0;
        tasks.forEach((task) => {
            index = 0;
            if(task.grid) {
                index = _findIndex(tabs,{"id":parseInt(task.grid)});
                if(index < 0) {
                    index = 0;
                }
            }
            results[index].children.push(task);
        });
    }

    return results;

}

export const TaskBoard = () => {

    const dispatch = useDispatch();

    const { clientInfo } = useSelector(state => state.clients);
    const { openedModalTasks, project } = useSelector(state => state.clientsProject);

    const filters = project.currentFilter ? project.currentFilter : {};

    const tasks = project.tasks ? project.tasks : [];
    const tabs = project.tabs ? project.tabs : [];
    const board =  {type: "container"};

    const [inputValue, setInputValue] = useState();
    const [editTitle, setEditTitle] = useState("");
    const [dropResults, setDropResults] = useState("");

    board.children = mapTasksToTabs(tabs,tasks);

    const addTab = () => {
        var maxTab = 0;
        if(tabs) {
            tabs.map((tab) => {
                var checkTab = parseInt(tab.id);
                if(checkTab > maxTab) {
                    maxTab = checkTab;
                }
            });
            maxTab++;
        }

        const newTabs = {...project};
        newTabs.tabs.push({"id":maxTab,"title":"Таб "+maxTab});

        const changedTabs = clearChildren(newTabs.tabs);
        const newState = {...project,"tabs":changedTabs};

        dispatch(updateProject({
            project,
            clientInfo,
            newState,
            update:{ "tabs":changedTabs }
        }));

    }

    const removeTab = (column) => {
        const changedTabs = tabs.filter((item, index) => item.id !== column.id);

        const newTabs = clearChildren(changedTabs);
        const newState = {...project,tabs: newTabs};

        dispatch(updateProject({
            project,
            clientInfo,
            newState,
            update:{ "tabs":newTabs }
        }));

    }

    const tabRename = (tab) => {
        if(inputValue && inputValue.length> 0) {
            const newTabs = board.children.map((t) => {
                return t.id == tab.id ? {...t,title:inputValue} : t;
            });

            const newNewTabs = clearChildren(newTabs);

            const newState = {...project,tabs: newNewTabs};

            dispatch(updateProject({
                project,
                clientInfo,
                newState,
                update:{ "tabs":newNewTabs }
            }));

        }

        setEditTitle("");
    }

    const handleKeyDown = (e,tab) => {
        if(e.key == "Enter") {
            tabRename(tab);
        }
        else if(e.key == "Escape") {
            e.currentTarget.blur();
        }
    }

    const focusInput = (e,tab) => {
        setInputValue(e.target.value);
    }

    const openModal = () => {
        dispatch(openCloseTasksModalAction({ "openClose":true }));
    }

    const addCard = async (tab) => {
        if(!tab.id) return false;
        await dispatch(addTask({clientInfo,project,tab}));
        openModal();
        dispatch(getProject({"project":project._id}));
    }

    const editCard = async (task) => {
        await dispatch(startEditTask({task,clientInfo}));
        openModal(true);
    }

    const getCardPayload = (columnId, index) => {
      return board.children.filter(p => p.id === columnId)[0].children[
        index
      ];
    }

    const onColumnDrop = (dropResult) => {
      const scene = Object.assign({}, board);
      scene.children = applyDrag(scene.children, dropResult);

      const newTabs = clearChildren(scene.children);
      const newState = {...project,tabs: newTabs};

      dispatch(updateProject({
          project,
          clientInfo,
          newState,
          update:{ "tabs":newTabs }
      }));

    }

    const onCardDrop = (columnId, dropResult) => {
      if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
        let scene = {};
        if(dropResults && dropResults.children) {
            scene = Object.assign({}, dropResults);

        }
        else {
            scene = Object.assign({}, board);
        }

        const column = scene.children.filter(p => p.id === columnId)[0];
        const columnIndex = scene.children.indexOf(column);

        const newColumn = Object.assign({}, column);
        newColumn.children = applyDrag(newColumn.children, dropResult);
        scene.children.splice(columnIndex, 1, newColumn);

        const newTabs = clearChildren(scene.children);
        const newState = {...project,tabs: newTabs};

        const newBoard = {"children":newTabs};

        if((dropResults && dropResults.children) || (dropResult.removedIndex !== null && dropResult.addedIndex !== null)) {
            setDropResults({});

            dispatch(updateProject({
                project,
                clientInfo,
                newState,
                update:{ "tabs":newTabs }
            }));

        }
        else {
            setDropResults(newBoard);
        }

      }
    }

    return (
        <>
            {openedModalTasks && <Modal />}
            <div className="mt-5 pb-16" >

                <Container
                  orientation="horizontal"
                  onDrop={onColumnDrop}
                  dropPlaceholder={{
                    animationDuration: 10,
                    showOnTop: true,
                    className: 'bg-gray-400 rounded mr-5'
                  }}
                >
                  {board.children.map((column,is) => {
                    return (
                        <Draggable key={column.id}>
                            <div className="flex flex-col w-80 bg-white border border-gray-200 rounded mr-5 p-2">
                                <div className="w-full text-sm pb-2 bg-white font-medium focus:py-2 focus:bg-white focus:outline-none">
                                    {editTitle != column.id &&
                                        <div className="flex w-full pl-3">
                                            <div
                                                onClick={() => {setEditTitle(column.id)}}
                                                className="w-full text-sm py-1 bg-white text-gray-700 font-medium focus:py-2 focus:bg-white focus:outline-none">
                                                {column.title}
                                            </div>

                                            <TaskDropDown remove={() => removeTab(column)} add={() => addCard(column)}  />

                                        </div>
                                    }
                                    {editTitle == column.id &&
                                        <div className="flex w-full">
                                            <div className="flex w-full">
                                                <input autofocus="true" className="w-full text-sm py-2 px-2 font-medium focus:py-2 focus:bg-white focus:outline-none" defaultValue={column.title} onKeyDown={(e) => handleKeyDown(e,column)}  onBlur={(e) => tabRename(column)} onFocus={(e) => { focusInput(e); }} onChange={(e) => { setInputValue(e.target.value) }} />

                                                <button onClick={(e) => tabRename(column)} className="text-green-500 pl-2 pr-2 bg-white focus:outline-none">
                                                    <SvgOk classes="w-6 h-6" />
                                                </button>
                                            </div>

                                        </div>
                                    }
                                </div>
                                <Container
                                    orientation="vertical"
                                    groupName="col"
                                    onDrop={e => onCardDrop(column.id, e)}
                                    getChildPayload={index =>
                                        getCardPayload(column.id, index)
                                    }
                                    dragClass="bg-gray-300"
                                    dropClass="bg-gray-300 text-white"
                                    dropPlaceholder={{
                                        animationDuration: 10,
                                        showOnTop: true,
                                        className: 'bg-gray-400 rounded mb-2'
                                    }}
                                    dropPlaceholderAnimationDuration={10}
                                    >
                                    {column.children.map(card => {
                                        return (
                                            <Draggable key={card._id}>
                                                <div  onClick={() => editCard(card)} className={(filters && filters.value && filters.value != card.kind.value) ? "hidden flex flex-col bg-gray-100 border border-gray-200 rounded py-2 px-4 mb-2 cursor-pointer text-base break-words select-none" : "flex flex-col bg-gray-100 border border-gray-200 rounded py-2 px-4 mb-2 cursor-pointer text-base break-words select-none"}>
                                                    <div className="flex">
                                                        <div className="flex flex-grow text-gray-800 text-base break-words">
                                                            {card.title ? card.title : "задача"}
                                                        </div>
                                                        <div>
                                                            {(!card.important && card.emergency) ?
                                                                <div className="text-xs text-gray-400  ml-3 mb-1 w-4 h-4 bg-yellow-600 rounded">&nbsp;</div>
                                                                : ""
                                                            }
                                                            {(card.important && card.emergency) ?
                                                                <div className="text-xs text-gray-400  ml-3 mb-1 w-4 h-4 bg-red-600 rounded">&nbsp;</div>
                                                                : ""
                                                            }
                                                            {(card.important && !card.emergency) ?
                                                                <div className="text-xs text-gray-400  ml-3 mb-1 w-4 h-4 bg-yellow-300 rounded">&nbsp;</div>
                                                                : ""
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="flex text-gray-500 mt-1">
                                                        <div className="flex flex-grow items-center flex-wrap">
                                                            {(card.timerTotal && (card.deal || card.due_date)) ?
                                                                <span className="text-xs text-gray-400 mb-1">
                                                                    работил:<br />
                                                                    {moment.utc(card.timerTotal*1000).format("HH:mm")}
                                                                </span> : ""
                                                            }

                                                            {(!card.deal && !card.due_date && card.timerTotal) ?
                                                                <span className="text-xs text-gray-400 mr-2">
                                                                    работил: {moment.utc(card.timerTotal*1000).format("HH:mm")} ч.
                                                                </span> : ""
                                                            }

                                                            {!card.timerTotal && <SvgEye classes="w-5 h-5 mr-1 text-gray-400" />}

                                                            {card.deal &&
                                                                <span className="text-xs text-gray-400 ml-3 mr-3 mb-1">
                                                                    договорени: <br />
                                                                    {card.deal} часа
                                                                </span>
                                                            }

                                                            {card.due_date &&
                                                                <span className="text-xs text-gray-400 mb-1">
                                                                    крайна дата: <br />
                                                                    {moment(card.due_date).format("DD/MM/yyyy")}
                                                                </span>
                                                            }

                                                            {card.kind &&
                                                                <span className="text-xs text-indigo-400 mr-3">
                                                                    {card.kind.label}
                                                                </span>
                                                            }


                                                        </div>
                                                        <Timer task={card} />
                                                    </div>
                                                </div>
                                            </Draggable>
                                        );
                                    })}


                                </Container>
                                <button onClick={() => addCard(column)} className="flex flex-inline bg-white rounded text-gray-500 hover:text-gray-800 w-full text-center font-normal text-sm pl-2 py-2 focus:outline-none" type="button" >
                                    <SvgPlus classes="w-5 h-5 mr-2" />
                                    Добави карта
                                </button>
                            </div>
                        </Draggable>
                    );
                  })}

                  <div className="text-center rounded w-64 mr-20">
                      <button onClick={addTab} className="flex flex-inline bg-white hover:bg-gray-500 text-gray-500 hover:text-gray-200 w-full text-center font-normal text-sm pl-7 pr-16 py-2 rounded focus:outline-none" type="button">
                          <SvgPlus classes="w-5 h-5 mr-2" />
                          Добави колона
                      </button>
                  </div>

                </Container>



                <div className="hidden">
                    <Editor
                      init={{
                        height: 100,
                        menubar: false,
                        toolbar: false,
                        relative_urls : false,
                        statusbar: false,
                        paste_as_text: false,
                        browser_spellcheck: false,
                        contextmenu: false,
                        object_resizing : false,
                        plugins: [
                            'autolink lists link  anchor table paste autoresize media textcolor'
                        ]
                      }}
                    />
                </div>

            </div>
        </>
    );
}
