import {
    ADD_ROW,
    UPDATE_ROW,
    UPDATE_FIELD,
    DELETE_ROW,
    GET_ROW,
    GET_ROWS,
    MODAL,
    GET_ROWS_LIST,
    DRAW_FIELDS_SELECT,
    UPDATE_APPINFO,
    APPS_LIST
} from '../types';

const INTIAL_STATE = {
  "isLoaded": false,
  "openedModal": false,
  "clearNext": false,
  "rows": [],
  "appOptions": [],
  "appsList": [],
  "appsInfo": [],
  "row": [],
  "updatedParent":false
};

const appsReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case GET_ROWS:
            if(action && action.payload && action.payload.row) {
                return { ...state, rows: action.payload.fields, appsInfo: action.payload.row, isLoaded: true, "updatedParent":false};
            }
            return { ...state, rows: action.payload.fields, isLoaded: true, "updatedParent":false};
        case ADD_ROW:
            return { ...state, rows: [...state.rows,action.payload], "updatedParent":true};
        case UPDATE_ROW:
            return {...state,rows: state.rows.map(row => row.id === action.payload.id ? action.payload : row), "updatedParent":false};
        case UPDATE_APPINFO:
            return {...state,appsInfo: action.payload.appInfo, "updatedParent":false};
        case UPDATE_FIELD:
            action.payload.row[action.payload.column] = action.payload.value;
            return { ...state, rows: state.rows.map(row => row.id === action.id ? action.payload.row : row), "updatedParent":false};
        case DELETE_ROW:
            return { ...state, rows: state.rows.filter((item, index) => item.id !== action.payload), "updatedParent":true};
        case MODAL:
            return { ...state, openedModal: action.payload.openClose, row: action.payload.row, "updatedParent":false };
        case DRAW_FIELDS_SELECT:
            return { ...state, appOptions: action.payload.options, clearNext: action.payload.clearNext, "updatedParent":false };
        case APPS_LIST:
            return { ...state, appsList: action.payload.options, clearNext: action.payload.clearNext, "updatedParent":false };
        default:
            return state;
    }
}

/*
UPDATE SINGLE COLUMNG

*/

export default appsReducer;
