import React, { useState, useRef, useEffect } from "react";
import { Modal } from 'react-responsive-modal';
import Switch from "react-switch";
import ReactSelect from 'react-select'
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';
import AsyncSelect, { makeAsyncSelect } from 'react-select/async';
import appsApi from '../../../../../env/apis/projects/appsApi';

//Actions
import { openCloseModalResearchAction, updateResearchAction } from '../../../../../actions/projects/clientsProjectAction';

//Styles
import { SvgOk } from '../../../parts/blocks/Svg';

const optionsLinks = [
  { value: 'link', label: 'Линк'},
  { value: 'courses', label: 'Курс'},
  { value: 'npmjs', label: 'Npmjs'},
  { value: 'packagist', label: 'Packagist'}
]

export default () => {

    const dispatch = useDispatch();
    const { openedModalResearch, rowResearch, options } = useSelector(state => state.clientsProject);

    const resources = [];
    for(var i in rowResearch.resources)  resources.push({label:rowResearch.resources[i].label,"description":rowResearch.resources[i].description,"kind":rowResearch.resources[i].kind});
    rowResearch.resources = resources;

    const competition = [];
    for(var i in rowResearch.competition)  competition.push({url4:rowResearch.competition[i].url4,url3:rowResearch.competition[i].url3,url2:rowResearch.competition[i].url2,url:rowResearch.competition[i].url,"name":rowResearch.competition[i].name});
    rowResearch.competition = competition;

    const apps = [];
    for(var i in rowResearch.apps)  apps.push({app:rowResearch.apps[i].app,"name":rowResearch.apps[i].name});
    rowResearch.apps = apps;

    const editorRefResourse = useRef();

    const { register, control, reset, getValues, handleSubmit } = useForm({
        defaultValues: rowResearch.settings
    });

    const {
      fields: bookmarkFields,
      append: bookmarkAppend,
      remove: bookmarkRemove,
      move  : bookmarkMove
    } = useFieldArray({ control, name: "resources" });

    const {
      fields: competitionFields,
      append: competitionAppend,
      remove: competitionRemove,
      move  : competitionMove

    } = useFieldArray({ control, name: "competition" });

    const {
      fields: appsFields,
      append: appsAppend,
      remove: appsRemove,
      move  : appsMove

  } = useFieldArray({ control, name: "apps" });

    const handleEditorChange = (value) => {
    };

    const toastMe = () => {
        toast.success("Данните са запазени!")
    };

    const keyDown = (e) => {
        if(e.key == "Enter") {
            e.currentTarget.blur();
        }
    };

    const saveData = (e) => {
        onSubmit(getValues());
    }

    const onSubmit = async (data) => {
        if(rowResearch && rowResearch.id && data) {
            const bookmarksLength = data.resources && data.resources.length > 0 ? true : false;
            const competitionLength = data.competition && data.competition.length > 0 ? true : false;
            const appsLength = data.apps && data.apps.length > 0 ? true : false;

            data.resources = data.resources;
            data.competition = data.competition;
            data.apps = data.apps;

            data.resources_description = "";
            if(editorRefResourse && editorRefResourse.current && editorRefResourse.current.currentContent) {
                data.resources_description = editorRefResourse.current.currentContent;
            }


            await dispatch(updateResearchAction({
                "row" : rowResearch,
                "column":"settings",
                "value":data
            }));

            if(data.resources && bookmarksLength > 0) {
                await bookmarkMove(0,0);
            }

            if(data.competition && competitionLength>0) {
                await competitionMove(0,0);
            }

            if(data.apps && appsLength>0) {
                await appsMove(0,0);
            }

            toastMe();
        }
    };

    const closeModal = (rowResearch) => {
        dispatch(openCloseModalResearchAction({ "openClose":false, rowResearch }));
    }

    const loadOptions = async (inputValue) => {
        const data = [];
        const response = await appsApi.get('/blocks/search?q=' + inputValue);
        response.data.data.forEach((v) => {
            data.push({"label":v.app_name,"value":v.id});
        })
        return data;
    }

    const option = [];

    if(options) {
        options.forEach((v) => {
            option.push({"label":v.app_name,"value":v.id});
        })
    }

    return (
        <>
        <Modal
            open={openedModalResearch}
            onClose={() => closeModal(rowResearch)}
            center
            classNames={{
                modal: 'text-center inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl bg-gray-900 sm:1/3 lg:w-2/3',
            }}
            >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex items-center bg-gray-50 px-4 pt-5 pb-4 flex pr-16">
                    <h3
                        className="text-xl leading-6 font-medium text-gray-900 flex-grow"
                        id="modal-headline">
                        <small className="text-sm text-gray-600">
                            Задание:
                        </small>
                        &nbsp; {rowResearch.title}
                    </h3>

                    <button
                        type="button"
                        onClick={() => saveData()}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-1 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                        <SvgOk />
                    </button>

                </div>

                <div className="bg-gray-100 py-3 px-6 pt-1 ">
                    <div className="w-full mt-5 z-50 relative bg-gray-50 rounded p-5">
                        <div className="flex items-center pb-2 font-medium text-base">
                            Bookmarks
                        </div>

                        <ul className="w-full">
                            {bookmarkFields.map((item, index) => {
                                return (
                                    <li key={item.id} className="flex items-center pb-2">
                                        <div className="mr-5 w-48 pt-0.5 mt-1.5">
                                            <Controller
                                              render={({ field }) => (<ReactSelect {...field} options={optionsLinks} className="w-full text-sm" placeholder="Тип ресурс..." />)}
                                              name={`resources[${index}].kind`}
                                              control={control}
                                            />
                                        </div>

                                        <div className="flex-1 mr-5">
                                            <label className="framed w-full mt-1.5">
                                                <input placeholder=" " {...register(`resources[${index}].description`)} name={`resources[${index}].description`} defaultValue={item.description} onBlur={(e) => saveData(e)} onKeyDown={(e) => keyDown(e)} />
                                                <span>Описание</span>
                                            </label>
                                        </div>

                                        <div className="flex-grow flex mr-5">
                                            {item.label &&
                                                <a href={item.label} className="flex bg-indigo-200 w-10 rounded-l mt-1.5 items-center justify-center " target="_blank">
                                                    <svg className="w-5 h-5 text-gray-500 font-extrabold" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                                                    </svg>
                                                </a>
                                            }
                                            <label className="framed flex-grow">
                                                <input placeholder=" " {...register(`resources[${index}].label`)} name={`resources[${index}].label`} defaultValue={item.label} onBlur={(e) => saveData(e)} onKeyDown={(e) => keyDown(e)} />
                                                <span>Url</span>
                                            </label>
                                        </div>



                                        <div className="flex-2">
                                            <button type="button" className="flex items-center justify-center h-9 w-9 rounded bg-red-200 focus:outline-none"
                                                onClick={async() => {
                                                    await bookmarkRemove(index);
                                                    await onSubmit(getValues());
                                                }}
                                                >
                                                <svg className="w-5 h-5 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
                                                </svg>
                                            </button>
                                        </div>

                                    </li>
                                );
                            })}
                        </ul>

                        <div className="flex items-center pb-2">
                            <button type="button" className="flex items-center text-green-600 justify-center py-2 px-6 shadow rounded bg-green-200 focus:outline-none"
                                onClick={async() => {
                                    await bookmarkAppend({ label: "", value: "" });
                                    await onSubmit(getValues());
                                }}
                                >
                                Добави bookmark
                                <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div className="flex items-center pb-2 font-medium mt-7 text-base">
                        Записки
                    </div>

                    <div className="w-full z-40">
                        <Editor
                          initialValue={rowResearch.settings.resources_description}
                          ref={editorRefResourse}
                          init={{
                            height: 550,
                            menubar: false,
                            relative_urls : false,
                            mode: "textareas",
                            paste_as_text: true,
                            browser_spellcheck: true,
                            contextmenu: false,
                            object_resizing : true,
                            valid_elements: "*[*]",
                            fontsize_formats: "16px 18px 20px 22px 24px 30px 36px 48px",
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor textcolor pagebreak tabfocus directionality autosave',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code wordcount'
                            ],
                            toolbar:
                              'undo redo | bold italic forecolor backcolor fontsizeselect | \
                              alignleft aligncenter alignright alignjustify blockquote | \
                              bullist numlist removeformat outdent indent | link insert media imageButton | code'
                          }}
                          onEditorChange={(value) => handleEditorChange(value)}
                        />
                    </div>

                    <div className="flex">
                        <div className="flex-1 mt-5 mr-5 z-40 relative bg-gray-50 p-5 rounded">
                            <div className="flex items-center pb-2 font-medium text-base">
                                Подобни
                            </div>

                            <ul className="w-full">
                                {competitionFields.map((item, index) => {
                                    return (
                                        <li key={item.id} className="flex items-center pb-2">
                                            <div className="mr-5 pt-0.5">
                                                <label className="framed flex-grow">
                                                    <input placeholder=" " {...register(`competition[${index}].name`)} name={`competition[${index}].name`} defaultValue={item.name} onBlur={(e) => saveData(e)} onKeyDown={(e) => keyDown(e)} />
                                                    <span>Име</span>
                                                </label>
                                            </div>
                                            <div className="flex-grow flex mr-5">
                                                {item.url &&
                                                    <a href={item.url} className="flex bg-indigo-200 w-10 rounded-l mt-1.5 items-center justify-center " target="_blank">
                                                        <svg className="w-5 h-5 text-gray-500 font-extrabold" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                                                        </svg>
                                                    </a>
                                                }
                                                <label className="framed flex-grow">
                                                    <input placeholder=" " {...register(`competition[${index}].url`)} name={`competition[${index}].url`} defaultValue={item.url} onBlur={(e) => saveData(e)} onKeyDown={(e) => keyDown(e)} />
                                                    <span>Url</span>
                                                </label>
                                            </div>

                                            <div className="flex-2">
                                                <button type="button" className="flex items-center justify-center h-9 w-9 rounded bg-red-200 focus:outline-none"
                                                    onClick={async () => {
                                                        await competitionRemove(index);
                                                        await onSubmit(getValues());
                                                    }}
                                                    >
                                                    <svg className="w-5 h-5 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
                                                    </svg>
                                                </button>
                                            </div>

                                        </li>
                                    );
                                })}
                            </ul>

                            <div className="flex items-center pb-2">
                                <button type="button" className="flex items-center text-yellow-600 justify-center py-2 px-6 shadow rounded bg-yellow-200 focus:outline-none"
                                    onClick={async() => {
                                        await competitionAppend({ label: "", value: "" });
                                        await onSubmit(getValues());
                                    }}
                                    >
                                    Добави подобен
                                    <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="flex-1 mt-5 z-40 relative bg-gray-50 p-5 rounded">
                            <div className="flex items-center pb-2 font-medium text-base">
                                Apps
                            </div>

                            <ul className="w-full">
                                {appsFields.map((item, index) => {
                                    return (
                                        <li key={item.id} className="flex items-center pb-2">
                                            <div className="pt-0.5 mr-5">
                                                <label className="framed flex-grow">
                                                    <input placeholder=" " {...register(`apps[${index}].name`)} name={`apps[${index}].name`} defaultValue={item.name} onBlur={(e) => saveData(e)} onKeyDown={(e) => keyDown(e)} />
                                                    <span>Име</span>
                                                </label>
                                            </div>
                                            <div className="flex-grow flex mr-3 mt-2">

                                                    <Controller
                                                      render={({ field }) => (<AsyncSelect {...field} defaultOptions={option} defaultValue={item.app} loadOptions={loadOptions} cacheOptions className="w-full text-left" />)}
                                                      name={`apps[${index}].app`}
                                                      control={control}
                                                    />
                                            </div>

                                            <div className="flex-2 mt-2">
                                                <button type="button" className="flex items-center justify-center h-9 w-9 rounded bg-red-200 focus:outline-none"
                                                    onClick={async () => {
                                                        await appsRemove(index);
                                                        await onSubmit(getValues());
                                                    }}
                                                    >
                                                    <svg className="w-5 h-5 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
                                                    </svg>
                                                </button>
                                            </div>

                                        </li>
                                    );
                                })}
                            </ul>

                            <div className="flex items-center pb-2">
                                <button type="button" className="flex items-center text-yellow-600 justify-center py-2 px-6 shadow rounded bg-yellow-200 focus:outline-none"
                                    onClick={async() => {
                                        await appsAppend({ label: "", value: "" });
                                        await onSubmit(getValues());
                                    }}
                                    >
                                    Добави app
                                    <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                </button>
                            </div>
                        </div>

                    </div>

                </div>


                <div className="px-4 bg-gray-50 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                        type="button"
                        onClick={() => saveData()}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                        Запази
                    </button>
                    <button
                        type="button"
                        onClick={() => closeModal(rowResearch)}
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                        Отказ
                    </button>
                </div>

            </form>

        </Modal>
        </>
);
};
