import React from 'react'

//Components
import MainSidebar from './../sidebar/MainSidebar';
import SideBarSearchMobile from './../header/SideBarSearchMobile';
import ActionBar from './../content/ActionBar';
import DemoContent from './../content/DemoContent';

export default function Dasboard({ rendering, background }) {

  return (
    <div className="relative h-screen flex overflow-hidden bg-gray-200" style={{background: (background && background.full) ? `url(${background.full}) center center` : 'none'}}>

      <MainSidebar />

      <div className="flex flex-col w-0 flex-1 overflow-hidden">

        <SideBarSearchMobile />

        <main className="flex-1 relative overflow-y-auto focus:outline-none">
          <div className="py-8 xl:py-10">
            <div className="mx-auto px-4 sm:px-12 lg:px-12">
              <div className="xl:col-span-2 xl:pr-8">
                {rendering}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
