import React, { useState,useEffect,useRef,useCallback } from "react";
import { Modal } from 'react-responsive-modal';
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from 'react-textarea-autosize';
import moment from 'moment';
import {useDropzone} from 'react-dropzone'

//Actions
import { getTask,addComment,deleteComment,editComment } from '../../../../../../actions/projects/clientsTasksAction'
import appsApi from '../../../../../../env/apis/projects/appsApi';

//Styles
import { SvgImage,SvgHuman,SvgClose,SvgDelete,SvgSpin } from '../../../../parts/blocks/Svg';


const ModalTaskComments = ( ) => {

    const dispatch = useDispatch();

    const { clientInfo } = useSelector(state => state.clients);
    const { project  } = useSelector(state => state.clientsProject);
    const { task } = useSelector(state => state.clientsTasks);

    const [editDescription, setEditDescription] = useState(task.description ? false : false);
    const [startEditComment, setStartEditComment] = useState("");
    const [editingPostComment, setEditingPostComment] = useState(false);
    const [saveAnimation, setSaveAnimation] = useState(false);
    const [deleteAnimation, setDeleteAnimation] = useState(false);
    const [currentComment, setCurrentComment] = useState("");
    const [uploadProgress, setUploadProgress] = useState(0);

    if(task && !task.comments) {
        task.comments = [];
    }

    const files = [];
    if(task && task.files) {
        files.add = task.files.filter((item,index) => item.type && item.type == "comments" && item.typeId && item.typeId == "add");
    }

    const comments = task.comments.map((item,index) => {
        return {...item,"files": task.files.filter((i) => i.commentId && i.commentId == item._id)};
    });

    const commentsRef = useRef(null);
    const commentsEditRef = useRef(null);

    const postComment = async (e) => {
        setSaveAnimation(true);

        await dispatch(addComment({
            "clientInfo" : clientInfo,
            "project" : project,
            "task" : task,
            "row" : {
                "description" : commentsRef.current.value,
                "task_id"  : task._id
            }
        }));

        commentsRef.current.value = "";

        await dispatch(getTask({task}));

        setEditingPostComment(false);
        setSaveAnimation(false);
    }

    const removeComment = async (row,e) => {
        setDeleteAnimation(true);
        await dispatch(deleteComment({row}));

        dispatch(getTask({task}));
        setDeleteAnimation(false);
    }

    const changeComment = async (е) => {
        if(startEditComment) {
            setSaveAnimation(true);

            await dispatch(editComment({
                "update":{"description":commentsEditRef.current.value},
                "row":{"_id":startEditComment}
            }));
            await dispatch(getTask({task}));
            commentsEditRef.current.value = "";
            setStartEditComment("");
            setSaveAnimation(false);
        }

    }

    const onUploadProgress = event => {
        const percentCompleted = Math.round((event.loaded * 100) / event.total);
        if(percentCompleted > 99.9) {
            setUploadProgress(0);
        }
        else {
            setUploadProgress(percentCompleted);
        }
    };

    const startUploadFiles = () => {
        open();
    }

    const uploadFiles = async (files) => {
        const formData = new FormData();
        for(const [index, file] of files.entries()){
            formData.append("files", file);
        }
        await appsApi.post('/tasks/'+task._id+"/upload/comments/add", formData, {
            onUploadProgress:onUploadProgress,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });

        dispatch(getTask({task}));
    }

    const onDrop = useCallback(acceptedFiles => {
        uploadFiles(acceptedFiles);
    }, []);

    const {getRootProps, getInputProps, open, isDragActive} = useDropzone({
        onDrop,
        noClick: true,
        noKeyboard: true
    });

    const removeImage = async (file,e) => {
        e.stopPropagation();
        await appsApi.delete("/upload/"+file._id);
        dispatch(getTask({task}));
    }

    return (
        <>
            <div className="antialiased w-full mt-5 pb-10">
                <h3 className="mb-4 text-lg font-semibold text-gray-900">Коментари</h3>

                <div {...getRootProps()} className="focus:outline-none">
                       <input {...getInputProps()} />
                       {uploadProgress > 0 &&
                           <div className="flex flex-wrap content-center w-full h-full bg-white absolute z-50 opacity-80">
                               <div className="w-full text-gray-900 font-medium text-3xl items-center justify-center flex ">
                                   Файла се качва ({uploadProgress}%...)
                                   <span class="animate-ping -mt-4 w-2 h-2 rounded-full bg-blue-800 opacity-75"></span>
                               </div>
                           </div>
                       }
                 </div>

                <div className="space-y-4">

                    <div className="flex">
                        <div className="flex pr-3">
                            <SvgHuman classes="mt-1 rounded-full w-8 h-8 sm:w-10 sm:h-10 text-gray-300" />
                        </div>
                        <div className={editingPostComment ? `flex-grow flex-col rounded border border-gray-300 bg-white ring-gray-300 ring-1 ring-offset-2` : `flex-grow flex-col bg-white rounded border border-gray-300 shadow-sm`}>
                            {!editingPostComment &&
                                <button type="button" onClick={() => setEditingPostComment(true)} className="w-full text-left text-gray-400 resize-none text-base rounded-t px-4 py-2 leading-relaxed focus:outline-none">
                                    коментар...
                                </button>
                            }

                            {editingPostComment &&
                                <div>
                                    <TextareaAutosize
                                        onFocus={(e) => {setStartEditComment("");setEditDescription(false);e.target.selectionStart = e.target.value.length;e.target.selectionEnd = e.target.value.length;}}
                                        className={`w-full resize-none text-base rounded-t px-4 py-2 leading-relaxed focus:outline-none`}
                                        minRows={1}
                                        maxRows={16}
                                        name="comment"
                                        autoFocus
                                        ref={commentsRef}
                                        defaultValue=""
                                        />

                                    <div className="px-3 w-full">
                                        <div className="border-t flex py-1">
                                            <div className="flex flex-grow">
                                                <button onClick={(e) => postComment(e)} type="button" className="inline-flex bg-blue-700 text-gray-200 font-bold uppercase text-sm px-6 py-2 rounded shadow-sm hover:shadow-lg focus:outline-none focus:ring-blue-700 focus:ring-1 focus:ring-offset-2" >
                                                    {!saveAnimation && "Запази"}
                                                    {saveAnimation &&
                                                    <span className="inline-flex">
                                                        <SvgSpin />
                                                        <span>Запазвам</span>
                                                    </span>
                                                    }
                                                </button>
                                                <button type="button" className="ml-4" onClick={(e) => setEditingPostComment(false)}>
                                                    <SvgClose classes="w-7 h-7 text-gray-400" />
                                                </button>
                                            </div>

                                            <div className="flex">
                                                {files && files.add &&
                                                    <div className="flex flex-row flex-wrap mr-5">
                                                            {files.add.map((item, index) => {
                                                                if(item.type && item.type == "comments" && item.typeId && item.typeId == "add") {
                                                                    return (
                                                                        <div key={`file${index}`}  className="flex flex-row bg-gray-50 border border-gray-200 rounded py-1 pl-1 ml-1">
                                                                            <div className=" flex w-5 overflow-hidden">
                                                                                <a href={item.url}   target="_blank" className="flex items-center">
                                                                                    <img src={item.url} style={{}} alt=""/>
                                                                                </a>
                                                                            </div>
                                                                            <div className="flex flex-col flex-grow justify-start ml-1 mr-1 mt-1">
                                                                                <button type="button" onClick={(e) => removeImage(item,e)} className="flex flex-row text-xs text-gray-400 font-normal underline focus:outline-none">
                                                                                    <SvgDelete classes="w-4 h-4" />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            })}
                                                    </div>
                                                }
                                                <button type="button" className="focus:outline-none" onClick={() => startUploadFiles()}>
                                                    <SvgImage classes="rounded-full w-5 h-5 text-gray-500" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    {comments.map((item, index) => {
                        let newDate = moment(item.createdAt).format("MMM DD, YYYY, dddd в HH:mm","bg");

                        return (
                            <div key={item._id} className="flex">
                                <div className="flex-shrink-0 mr-3">
                                    <SvgHuman classes="mt-2 rounded-full w-8 h-8 sm:w-10 sm:h-10 text-gray-300" />
                                </div>
                                {startEditComment && startEditComment == item._id &&
                                    <div className="flex-grow flex-col rounded border border-gray-300 bg-white ring-gray-300 ring-1 ring-offset-2">
                                        <TextareaAutosize
                                            onFocus={(e) => {e.target.selectionStart = e.target.value.length;e.target.selectionEnd = e.target.value.length;setEditingPostComment(false);}}
                                            className={`w-full resize-none text-base rounded-t px-4 py-2 leading-relaxed focus:outline-none`}
                                            minRows={1}
                                            maxRows={16}
                                            name="comment"
                                            placeholder="коментар..."
                                            autoFocus
                                            ref={commentsEditRef}
                                            defaultValue={item.description} />

                                        <div className="px-3 w-full">
                                            <div className="border-t flex py-1">
                                                <div className="flex flex-grow">
                                                    <button onClick={(e) => changeComment(e)} type="button" className="inline-flex bg-blue-700 text-gray-200 font-bold uppercase text-sm px-6 py-2 rounded shadow-sm hover:shadow-lg focus:outline-none focus:ring-blue-700 focus:ring-1 focus:ring-offset-2" >
                                                        {!saveAnimation && "Запази"}
                                                        {saveAnimation &&
                                                        <span className="inline-flex">
                                                            <SvgSpin />
                                                            <span>Запазвам</span>
                                                        </span>
                                                        }
                                                    </button>
                                                    <button type="button" className="ml-4" onClick={(e) => setStartEditComment("")}>
                                                        <SvgClose classes="w-7 h-7 text-gray-400" />
                                                    </button>
                                                </div>

                                                <div className="flex">
                                                    {((files && files.add) || item.files) &&
                                                        <div className="flex flex-row flex-wrap mr-5">
                                                                {files.add.map((item, index) => {
                                                                    if(item.type && item.type == "comments" && item.typeId && item.typeId == "add") {
                                                                        return (
                                                                            <div key={`file${index}`}  className="flex flex-row bg-gray-50 border border-gray-200 rounded py-1 pl-1 ml-1">
                                                                                <div className=" flex w-5 overflow-hidden">
                                                                                    <a href={item.url}   target="_blank" className="flex items-center">
                                                                                        <img src={item.url} style={{}} alt=""/>
                                                                                    </a>
                                                                                </div>
                                                                                <div className="flex flex-col flex-grow justify-start ml-1 mr-1 mt-1">
                                                                                    <button type="button" onClick={(e) => removeImage(item,e)} className="flex flex-row text-xs text-gray-400 font-normal underline focus:outline-none">
                                                                                        <SvgDelete classes="w-4 h-4" />
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                })}

                                                                {item.files.map((item, index) => {
                                                                    return (
                                                                        <div key={`filee${index}`}  className="flex flex-row bg-gray-50 border border-gray-200 rounded py-1 pl-1 ml-1">
                                                                            <div className=" flex w-5 overflow-hidden">
                                                                                <a href={item.url}   target="_blank" className="flex items-center">
                                                                                    <img src={item.url} style={{}} alt=""/>
                                                                                </a>
                                                                            </div>
                                                                            <div className="flex flex-col flex-grow justify-start ml-1 mr-1 mt-1">
                                                                                <button type="button" onClick={(e) => removeImage(item,e)} className="flex flex-row text-xs text-gray-400 font-normal underline focus:outline-none">
                                                                                    <SvgDelete classes="w-4 h-4" />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}

                                                        </div>
                                                    }
                                                    <button type="button" className="focus:outline-none" onClick={() => startUploadFiles()}>
                                                        <SvgImage classes="rounded-full w-5 h-5 text-gray-500" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {startEditComment != item._id &&
                                    <div>
                                        <strong>Me</strong>
                                        <span className="text-xs text-gray-500 ml-5">
                                            {newDate}
                                        </span>
                                        <div className="text-sm border rounded bg-white px-4 py-2 shadow-sm">
                                            {item.description}

                                            {item && item.files && item.files.length>0 &&
                                                <div className="flex flex-row flex-wrap w-full mt-3">
                                                        {item.files.map((item, index) => {
                                                            return (
                                                                <div key={`file${index}`}  className="flex flex-row bg-gray-50 border relative border-gray-200 rounded p-2 mr-3 mb-1 mt-1">
                                                                    <div className=" flex w-16 overflow-hidden">
                                                                        <a href={item.url}   target="_blank" className="flex items-center">
                                                                            <img src={item.url} style={{}} alt=""/>
                                                                        </a>
                                                                    </div>
                                                                    <div className="absolute right-1 top-2 bg-gray-100 rounded justify-start">
                                                                        <button type="button" onClick={(e) => removeImage(item,e)} className="flex flex-row text-xs text-gray-400 font-normal underline focus:outline-none">
                                                                            <SvgDelete classes="w-4 h-4" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            }

                                        </div>

                                        <div className="mt-1 flex">
                                            <button type="button" onClick={(e) => setStartEditComment(item._id)} className="text-xs text-left flex-grow text-gray-400 font-normal underline focus:outline-none">
                                                Редактирай
                                            </button>

                                            {deleteAnimation ?
                                                <div className="flex text-xs font-normal pl-2">
                                                    <SvgSpin classes="h-4 w-4 text-gray-600"/>
                                                </div>
                                                :
                                                <button type="button" onClick={(e) => removeComment(item,e)} className="flex text-xs text-gray-400 pl-2 font-normal focus:outline-none">
                                                    <SvgDelete classes="h-4 w-4 text-gray-400" />
                                                </button>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        );
                    })}

                </div>
            </div>
        </>
    );
}

export default ModalTaskComments;
