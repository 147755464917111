import loginEndPoint from '../env/apis/authApi';
import history from '../history';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

import {
  SIGN_OUT,
  PASSWORD,
  LOGGIN,
  WRONG_LOGIN
} from './types';

const saveTokenInCookie = (data,dispatch) => {
    var domain = (window.location.host.match(/([^.]+)\.\w{2,3}(?:\.\w{2})?$/) || [])[0];
    document.cookie = "token="+data.token+"; expires="+data.expires+"; path=/; domain=." + domain;

    dispatch({ type: LOGGIN, payload: data });

    history.push('/');
}


export const loginApi = formValues => async (dispatch, getState) => {
    try {
        const response = await loginEndPoint.post('/login', { ...formValues }); //, { withCredentials: true }
        if(response.data.token) {
            saveTokenInCookie(response.data,dispatch);
        }
    }
    catch {
        dispatch({ type: WRONG_LOGIN });
    }
};


export const registerApi = formValues => async (dispatch, getState) => {
  const response = await loginEndPoint.post('/register', { ...formValues });

  if(response.data.token) {
      saveTokenInCookie(response.data,dispatch);
  }
  else {
      dispatch({ type: WRONG_LOGIN });
      history.push('/');
  }
};

export const passwordApi = formValues => async (dispatch, getState) => {
  const response = await loginEndPoint.post('/password', { ...formValues });
  dispatch({ type: PASSWORD, payload: response.data });
  toast.success("Линкът е изпратен успешно на посочения имейл!");
  history.push('/');
};

export const resetApi = formValues => async (dispatch, getState) => {
  const response = await loginEndPoint.post('/reset', { ...formValues });
  if(response.data.token) {
      toast.success("Паролата е променена успешно");
      saveTokenInCookie(response.data,dispatch);
  }
  else {
      dispatch({ type: PASSWORD });
      history.push('/');
  }
};

export const loggedIn = () => async (dispatch, getState) => {
  const jwt = Cookies.get('token');
  if(jwt) {
      dispatch({ type: LOGGIN, payload: jwt });
      // history.push('/');
  }
  else {
      dispatch({ type: SIGN_OUT  });
  }
};

export const logOut = formValues => async (dispatch, getState) => {
  const jwt = Cookies.get('token');
  if(jwt) {
      const response = await loginEndPoint.post('/logout');

      var domain = (window.location.host.match(/([^.]+)\.\w{2,3}(?:\.\w{2})?$/) || [])[0];

      Cookies.remove('token', { path: '/', domain: '.'+domain });

      dispatch({ type: SIGN_OUT });
      history.push('/');
  }
};
