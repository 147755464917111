//Apis
import appsApi from '../../env/apis/projects/appsApi';

//Types
import {
    CLIENTS_SETTINGS_ADD_ROW,
    CLIENTS_SETTINGS_UPDATE_FIELD,
    CLIENTS_SETTINGS_DELETE_ROW,
    CLIENTS_SETTINGS_GET_ROWS,
    CLIENTS_SETTINGS_MODAL,
    CLIENTS_APPS_LIST,
    CLIENTS_CLIENTINFO,
    CLIENTS_SETTINGS_TASKS_MODAL,
    CLIENTS_PROJECTINFO,
    CLIENTS_SETTINGS_FILTERS,

    CLIENTS_SETTINGS_ADD_RESEARCH,
    CLIENTS_SETTINGS_RESEARCH_DELETE,
    CLIENTS_SETTINGS_MODAL_RESEARCH,
    CLIENTS_SETTINGS_GET_RESEARCH,
    CLIENTS_SETTINGS_UPDATE_RESEARCH,

    CLIENTS_SETTINGS_ADD_PR,
    CLIENTS_SETTINGS_PR_DELETE,
    CLIENTS_SETTINGS_MODAL_PR,
    CLIENTS_SETTINGS_GET_PR,
    CLIENTS_SETTINGS_UPDATE_PR

} from './types';

import generateUUID from '../../hooks/generateUUID';

export const filterProjectTasks = (payload) => async (dispatch,getState) => {
    dispatch({ type: CLIENTS_SETTINGS_FILTERS, payload});
};

export const openCloseModalAction = (payload) => async (dispatch,getState) => {
    dispatch({ type: CLIENTS_SETTINGS_MODAL, payload});
};

export const openCloseModalResearchAction = (payload) => async (dispatch,getState) => {
    dispatch({ type: CLIENTS_SETTINGS_MODAL_RESEARCH, payload});
};

export const openCloseTasksModalAction = (payload) => async (dispatch,getState) => {
    dispatch({ type: CLIENTS_SETTINGS_TASKS_MODAL, payload});
};

export const getAppsOptions = (payload) => async (dispatch,getState) => {
    const response = await appsApi.get('/blocks?limit=10');
    dispatch({ type: CLIENTS_APPS_LIST, payload: response.data });
};

export const addProject = (payload) => async (dispatch,getState) => {
    const row = {
        "weight"                : 0,
        "client"                : payload.clientInfo._id,
        "title"                 : "",
        "description"           : "",
        "apps"                  : [],
        "research"              : [],
        "pr" : [ { id: '0kdv0dpw', weight: 8, client_id: '6089dbf9a5018514ae9e8e70', project_id: '6089dc00a5018514ae9e8e71', title: 'Giganot', settings: {} }, { id: '005h0als', weight: 0, client_id: '6089dbf9a5018514ae9e8e70', project_id: '6089dc00a5018514ae9e8e71', title: 'Google Ads', settings: {} }, { id: '00zm0fvy', weight: 1, client_id: '6089dbf9a5018514ae9e8e70', project_id: '6089dc00a5018514ae9e8e71', title: 'Facebook Ads', settings: {} }, { id: '0vyz0bsg', weight: 2, client_id: '6089dbf9a5018514ae9e8e70', project_id: '6089dc00a5018514ae9e8e71', title: 'Facebook pages', settings: {} }, { id: '0lca0h0r', weight: 3, client_id: '6089dbf9a5018514ae9e8e70', project_id: '6089dc00a5018514ae9e8e71', title: 'Youtube', settings: {} }, { id: '0dgc00ze', weight: 4, client_id: '6089dbf9a5018514ae9e8e70', project_id: '6089dc00a5018514ae9e8e71', title: 'Email', settings: {} }, { id: '0s9b0wwy', weight: 5, client_id: '6089dbf9a5018514ae9e8e70', project_id: '6089dc00a5018514ae9e8e71', title: 'Notifications', settings: {} }, { id: '0i3r04kc', weight: 6, client_id: '6089dbf9a5018514ae9e8e70', project_id: '6089dc00a5018514ae9e8e71', title: 'Twitter', settings: {} } ],
        "deal"                  : "",
        "deal_description"      : "",
        "timerTask"             : "",
        "wallet"                : [],
        "stats"                 : [],
        "rights"                : [],
        "currentFilter"         : {value: 'support',label: 'Съпорт'},
        "isActive"              : 1,
        "tabs"        : [
            {"id":1,"title":"Нови задачи",children:[]},
            {"id":2,"title":"Чакащи",children:[]},
            {"id":3,"title":"Работи се",children:[]},
            {"id":4,"title":"За одобрени",children:[]},
            {"id":5,"title":"Архив",children:[]},
            {"id":6,"title":"Застой",children:[]}
        ]
    };
    await appsApi.post('/projects',row);

    const response =  await appsApi.get('/clients/'+payload.clientInfo.id);
    dispatch({ type: CLIENTS_CLIENTINFO, payload: response.data.data });
};

export const updateProject = (payload) => async (dispatch,getState) => {

    if(payload.newState) {
        dispatch({ type: CLIENTS_PROJECTINFO, payload: payload.newState });
    }

    await appsApi.patch('/projects/'+payload.project._id,payload.update);

    if(!payload.newState) {
        const response =  await appsApi.get('/clients/'+payload.clientInfo.id);
        dispatch({ type: CLIENTS_CLIENTINFO, payload: response.data.data });
    }
};

export const getProject = (payload) => async (dispatch,getState) => {
    const response = await appsApi.get('/projects/'+payload.project);
    dispatch({ type: CLIENTS_PROJECTINFO, payload: response.data.data});
    return response.data.data;
};

export const changeProjectState = (payload) => async (dispatch,getState) => {
    dispatch({ type: CLIENTS_PROJECTINFO, payload: payload.state});
    return payload.state;
};

export const addApp = (payload) => async (dispatch,getState) => {
    const rowDefault = {
        "id"          : generateUUID(),
        "weight"      : 0,
        "client_id"   : payload.client,
        "project_id"  : payload.project,
        "app_name"    : "",
        "settings"    : {}
    };
    dispatch({ type: CLIENTS_SETTINGS_ADD_ROW, payload: rowDefault });
    await appsApi.patch('/projects/'+payload.project,{ "add" : rowDefault });
};


export const deleteApp = (payload) => async (dispatch,getState) => {
    dispatch({ type: CLIENTS_SETTINGS_DELETE_ROW, payload: payload.id });
    await appsApi.patch('/projects/'+payload.row.project_id,{ "delete" : payload.id });
};

export const getApps = (payload) => async (dispatch,getState) => {
    const response = await appsApi.get('/projects/'+payload.project);
    dispatch({ type: CLIENTS_SETTINGS_GET_ROWS, payload: {"fields":response.data.data.apps,"research":response.data.data.research,"row":response.data.data}});
};

export const updateAppsAction = (payload) => async (dispatch,getState) => {
    let update = [];
    update[payload.column] = payload.value;
    update = Object.assign({}, update);
    dispatch({ type: CLIENTS_SETTINGS_UPDATE_FIELD, payload: payload });
    appsApi.patch('/projects/'+payload.row.project_id, {
        "id" : payload.row.id,
        "update" : update
    });
};

export const reorderApps = (payload) => async (dispatch,getState) => {
    payload.forEach((v,i) => {
        payload[i]["weight"] = i;
    });
    appsApi.post('/projects/weights/'+payload[0].project_id,payload);
    dispatch({ type: CLIENTS_SETTINGS_GET_ROWS, payload: {"fields":payload} });
};

export const addResearch = (payload) => async (dispatch,getState) => {
    const rowDefault = {
        "id"             : generateUUID(),
        "weight"         : 0,
        "client_id"      : payload.client,
        "project_id"     : payload.project,
        "research_title" : "нов рисърч",
        "settings"       : {}
    };
    dispatch({ type: CLIENTS_SETTINGS_ADD_RESEARCH, payload: rowDefault });
    await appsApi.patch('/projects/research/'+payload.project,{ "add" : rowDefault });
};

export const deleteResearch = (payload) => async (dispatch,getState) => {
    dispatch({ type: CLIENTS_SETTINGS_RESEARCH_DELETE, payload: payload.id });
    await appsApi.patch('/projects/research/'+payload.row.project_id,{ "delete" : payload.id });
};

export const reorderResearch = (payload) => async (dispatch,getState) => {
    payload.forEach((v,i) => {
        payload[i]["weight"] = i;
    });
    appsApi.post('/projects/research/weights/'+payload[0].project_id,payload);
    dispatch({ type: CLIENTS_SETTINGS_GET_RESEARCH, payload: {"fields":payload} });
};

export const updateResearchAction = (payload) => async (dispatch,getState) => {
    let update = [];
    update[payload.column] = payload.value;
    update = Object.assign({}, update);
    dispatch({ type: CLIENTS_SETTINGS_UPDATE_RESEARCH, payload: payload });
    appsApi.patch('/projects/research/'+payload.row.project_id, {
        "id" : payload.row.id,
        "update" : update
    });
};

//PR
export const openCloseModalPrAction = (payload) => async (dispatch,getState) => {
    dispatch({ type: CLIENTS_SETTINGS_MODAL_PR, payload});
};

export const addPr = (payload) => async (dispatch,getState) => {
    const rowDefault = {
        "id"             : generateUUID(),
        "weight"         : 0,
        "client_id"      : payload.client,
        "project_id"     : payload.project,
        "title"          : "канал за популяризиране",
        "settings"       : {}
    };
    dispatch({ type: CLIENTS_SETTINGS_ADD_PR, payload: rowDefault });
    await appsApi.patch('/projects/pr/'+payload.project,{ "add" : rowDefault });
};

export const deletePr = (payload) => async (dispatch,getState) => {
    dispatch({ type: CLIENTS_SETTINGS_PR_DELETE, payload: payload.id });
    await appsApi.patch('/projects/pr/'+payload.row.project_id,{ "delete" : payload.id });
};

export const reorderPr = (payload) => async (dispatch,getState) => {
    payload.forEach((v,i) => {
        payload[i]["weight"] = i;
    });
    appsApi.post('/projects/pr/weights/'+payload[0].project_id,payload);
    dispatch({ type: CLIENTS_SETTINGS_GET_PR, payload: {"fields":payload} });
};

export const updatePrAction = (payload) => async (dispatch,getState) => {
    let update = [];
    update[payload.column] = payload.value;
    update = Object.assign({}, update);
    dispatch({ type: CLIENTS_SETTINGS_UPDATE_PR, payload: payload });
    appsApi.patch('/projects/pr/'+payload.row.project_id, {
        "id" : payload.row.id,
        "update" : update
    });
};
