import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import history from '../../../../history';
import { useLocation } from 'react-router-dom';

//Actions
import { updateBlocksFieldAction } from '../../../../actions/projects/appsListAction'
import { updateAppInfo } from '../../../../actions/projects/appsSettingsAction'

//Styles
import { SvgAdd } from '../../parts/blocks/Svg';

const reorder = (list, startIndex, endIndex, id) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    let ret = [];
    ret[id] = result;

    return ret;
};

// Move item from one list to other
const move = (source, destination, droppableSource, droppableDestination) => {

    if(droppableSource.droppableId == droppableDestination.droppableId) {
        return reorder(source,droppableSource.index,droppableDestination.index,droppableSource.droppableId);
    }

    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    color: isDragging ? '#F3F4F6' : '#374151',
    background: isDragging ? '#1D4ED8' : '#D1D5DB',
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#DBEAFE' : '#F3F4F6'
});

const grids = [
    {"name":"1"},
    {"name":"2"},
    {"name":"3"},
    {"name":"4"}
];

export const reformTabGrid = ({appsInfo,data}) => {
    const tabs = (appsInfo && appsInfo.tabs && appsInfo.tabs.length>0) ? appsInfo.tabs : [{"name":"1"}];

    const items = [];

    const mapListStart = [];
    grids.map((grid) => {
        tabs.map((tab) => {
            var id = tab.name+grid.name+"t";
            if(!mapListStart[tab.name]) mapListStart[tab.name] = [];
            mapListStart[tab.name].push({id,"tab":tab.name,"title":tab.title});
            items[id] = [];
        });
    });
    const mapList = mapListStart.filter(function (item) { return item != undefined });

    if(!items["11t"]) {
        items["11t"] = [];
    }

    if(!appsInfo.grid || appsInfo.grid.length == 0) {
        data.map((row) => {
            items["11t"].push(row);
        });
    }
    else {
        const addedTo = [];
        mapList.map((tab) => {
            tab.map((grid) => {
                if(appsInfo.grid[grid.id]) {
                    for (var y in appsInfo.grid[grid.id]) {
                        data.map((row,i) => {
                            if(row.title_in_db == y && !addedTo[row.title_in_db]) {
                                items[grid.id].push(row);
                                addedTo[row.title_in_db] = 1;
                            }
                        });
                    }
                }
            });
        });

        data.map((row) => {
            if(!addedTo[row.title_in_db]) {
                items["11t"].push(row);
            }
        });

    }

    return {items,mapList,tabs};
}

export const TabLayout = ({data}) => {

    const dispatch = useDispatch();

    const { appsInfo } = useSelector(state => state.appsSettings);
    const [showSaveLabel, setShowSaveLabel] = useState(false);
    const [inputValue, setInputValue] = useState();


    const {items,mapList,tabs} = reformTabGrid({appsInfo,data});



    // mapList.map((key) => {
    //     if(appsInfo.grid[x]) {
    //         for (var y in appsInfo.grid[x]) {
    //             data.map((row) => {
    //                 if(row.id == y) items[x].push(row);
    //             });
    //         }
    //     }
    // });

    const location = useLocation();

    const addTab = () => {
        var maxTab = 0;
        if(appsInfo.tabs) {
            appsInfo.tabs.map((tab) => {
                var checkTab = parseInt(tab.name);
                if(checkTab > maxTab) {
                    maxTab = checkTab;
                }
            });
            maxTab++;
        }

        const newTabs = {...appsInfo};
        newTabs.tabs.push({"name":maxTab,"title":"Таб "+maxTab});

        dispatch(updateBlocksFieldAction({
            "row":appsInfo,
            "column":"tabs",
            "value":newTabs.tabs,
            "stopRedraw" : 1
        }));

        dispatch(updateAppInfo({
            "appInfo":newTabs
        }));
    }

    const removeTab = (tab) => {
        const newTabs = appsInfo.tabs.filter((item, index) => item.name !== tab[0].tab);

        var newGrid = {};
        var id = tab[0].tab;
        for (var x in appsInfo.grid) {

            if(x.substring(0, 1) == id) {
                if(!newGrid["11t"]) newGrid["11t"] = {};
                for (var y in appsInfo.grid[x]) {
                    newGrid["11t"][y] = appsInfo.grid[x][y];
                }
            }
            else {
                if(!newGrid[x]) newGrid[x] = {};
                for (var y in appsInfo.grid[x]) {
                    newGrid[x][y] = appsInfo.grid[x][y];
                }
            }
        }

        dispatch(updateBlocksFieldAction({
            "row":appsInfo,
            "multi":{"tabs":newTabs,"grid":newGrid},
            "stopRedraw" : 1
        }));

        const newState = {...appsInfo};
        newState.tabs = newTabs;
        newState.grid = newGrid;

        dispatch(updateAppInfo({
            "appInfo":newState
        }));
    }

    const onDragEnd = result => {
        const { source, destination } = result;

        if (!destination) return;

        const moved = move(
            items[source.droppableId],
            items[destination.droppableId],
            source,
            destination
        );

        let ordered = [];
        mapList.map((tab) => {
            tab.map((row) => {
                if(moved[row.id]) {
                    ordered[row.id] = moved[row.id];
                }
                else if(items[row.id]) {
                    ordered[row.id] = items[row.id];
                }
            });
        });

        let saveGrid = {};

        mapList.map((tab) => {
            tab.map((order) => {
                ordered[order.id].map((v,is) => {
                    data.map((row,i) => {
                        if(row.id == v.id && row.location.grid != order.id) data[i].location.grid = order.id;
                        if(!saveGrid[order.id]) saveGrid[order.id] = {};
                        saveGrid[order.id][v.title_in_db] = is;
                    });
                });
            });
        });

        dispatch(updateBlocksFieldAction({
            "row":appsInfo,
            "column":"grid",
            "value":saveGrid,
            "stopRedraw" : 1
        }));

        const newState = {...appsInfo};
        newState.grid = saveGrid;
        dispatch(updateAppInfo({
            "appInfo":newState
        }));
    };

    const tabRename = (tab) => {
        if(inputValue && inputValue.length> 0) {
            const newTabs = tabs.map((t) => {
                return t.name == tab.tab ? {...t,title:inputValue} : t;
            });
            dispatch(updateBlocksFieldAction({
                "row":appsInfo,
                "column":"tabs",
                "value":newTabs,
                "stopRedraw" : 1
            }));
            setShowSaveLabel(false);

            const newState = {...appsInfo};
            newState.tabs = newTabs;
            dispatch(updateAppInfo({
                "appInfo":newState
            }));
        }
    }

    const handleKeyDown = (e,tab) => {
        if(e.key == "Enter") {
            tabRename(tab);
        }
        else if(e.key == "Escape") {
            e.currentTarget.blur();
        }
        else {
            setShowSaveLabel(true);
        }
    }

    const focusInput = (e,tab) => {
        setInputValue(e.target.value);
        setShowSaveLabel(true)
    }


    return (
        <>
            <div className="w-full mt-5">
                <DragDropContext onDragEnd={onDragEnd}>
                    <div className="flex w-full">
                        {mapList.map((tab,is) => (
                            <div key={`tbs`+is} className="flex flex-col w-1/4 bg-white pb-5 border-r-8">
                                {tab[0] && tab[0].title &&
                                <div className="flex px-2">
                                    <div className="flex pt-5 pb-4 w-full mr-1">
                                        <label className="framed w-full text-xl">
                                            <input placeholder=" " defaultValue={tab[0].title} onKeyDown={(e) => handleKeyDown(e,tab[0])}  onBlur={(e) => tabRename(tab[0])} onFocus={(e) => { focusInput(e); }} onChange={(e) => { setInputValue(e.target.value) }} />
                                            <span>Име на таб</span>
                                        </label>

                                        <button onClick={(e) => tabRename(tab[0])} className={!showSaveLabel ? "text-green-500 ml-3 hidden" : "text-green-500 ml-3"}>
                                            <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                        </button>
                                    </div>
                                    {is > 0 &&
                                    <button onClick={() => removeTab(tab)} className="focus:outline-none">
                                        <svg className="w-5 h-5 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </button>
                                    }
                                </div>
                                }
                                {tab.map((key,value) => (
                                    <Droppable key={`vsa`+value} droppableId={key.id}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                className="rounded mt-2 p-2 mx-2 border"
                                                style={getListStyle(snapshot.isDraggingOver)}>
                                                <div className="rounded-sm mb-4 p-2 border border-gray-100 bg-white text-gray-500 text-xs">
                                                    колона: {value+1} - {key.title}
                                                </div>
                                                {items[key.id] && items[key.id].map((item, index) => (
                                                    <Draggable
                                                        key={item.id+tab.id+"t"}
                                                        draggableId={item.id+"-"+tab.id}
                                                        index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className="w-full rounded py-2 px-4 mb-2 w-full text-gray-700 select-none text-xs border border-gray-100"
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}>
                                                                <span class="font-bold">{item.label}</span> <br /> {item.title_in_db}
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                ))}
                            </div>
                        ))}
                        <div className="flex bg-gray-50 text-center rounded w-40">
                            <button onClick={addTab} className="bg-gray-300 text-gray-600 w-full text-center font-bold uppercase text-xs px-3 py-2 rounded shadow hover:shadow-lg focus:outline-none focus:ring-gray-900 focus:ring-2 focus:ring-offset-2"
                                type="button"
                                >
                                Добави таб  <SvgAdd classes="w-5 h-5 float-right ml-2" />
                            </button>
                        </div>

                    </div>

                </DragDropContext>
            </div>
        </>
    );
}
