import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import _get from "query-string";
import _filter from "@mcabreradev/filter";
import _find from 'lodash.find';
import history from '../../../history';
import moment from 'moment';
import ReactSelect from 'react-select'
import { ChevronLeftIcon } from '@heroicons/react/solid'

//Styles
import { SvgAdd,SvgBack,SvgGrid,SvgInbox,SvgUpdate,SvgEye,SvgSpin  } from '../parts/blocks/Svg';

//Custom hooks
import useDocumentTitle from './../../../hooks/useDocumentTitle'
import useDimension from "./../../../hooks/useDimension";


//Components
import Dashboard from '../../board/Dashboard';
import Table from '../parts/table/Table'
import ModalAppSettings from './parts/modals/ModalAppSettings'
import ModalResearch from './parts/modals/ModalResearch'
import ModalPr from './parts/modals/ModalPr'
import {TaskBoard} from './parts/projects/TaskBoard'
import Resourses from './parts/projects/Resourses'
import Accounted from './parts/projects/Accounted'
import MainInfo from './parts/projects/MainInfo'
import Menus from './parts/projects/Menus'
import ActionBar from '../../board/panels/content/ActionBar';

//Actions
import { getClient } from '../../../actions/projects/clientsListAction'
import { getProject,updateProject } from '../../../actions/projects/clientsProjectAction'

import { addApp, addResearch, addPr, deleteApp, deleteResearch, deletePr, getApps, updateAppsAction, updateResearchAction, updatePrAction, openCloseModalAction, openCloseModalResearchAction, openCloseModalPrAction, getAppsOptions, reorderApps, reorderResearch, reorderPr } from '../../../actions/projects/clientsProjectAction'

//Data
import columns from '../../../env/apis/projects/data/clientsAppsColumns';
import researchColumns from '../../../env/apis/projects/data/clientsResearchColumns';
import prColumns from '../../../env/apis/projects/data/clientsPrColumns';
import filtersOptions from '../../../env/apis/projects/data/filtersOptions';

let defaultOptions = {};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ClientsEditProject = (props) => {

  const id = props.match.params.id;
  const projectId = props.match.params.project;

  const dispatch = useDispatch();
  const { clientInfo } = useSelector(state => state.clients);
  const { apps, research, pr, project, options, openedModal, openedModalResearch, openedModalPr }  = useSelector(state => state.clientsProject);

  defaultOptions = options;

  let pageTitle = "👻";
  if(project && project.title) {
      pageTitle += " ## " + project.title;
  }
  useDocumentTitle(pageTitle);

  const handleKind = async (value) => {
      const newState = {...project,"currentFilter":value};

      await dispatch(updateProject({
          newState,
          "project":project,
          clientInfo,
          update:{ "currentFilter":value }
      }));
  };

  const addRow = async () => {
      await dispatch(addApp({"client":clientInfo._id,"project":project._id}));
  }

  const addResearchRow = async () => {
      await dispatch(addResearch({"client":clientInfo._id,"project":project._id}));
  }

  const addPrRow = async () => {
      await dispatch(addPr({"client":clientInfo._id,"project":project._id}));
  }

  const search = _get.parse(window.location.search, {parseBooleans: true});
  const [selectedTab, setSelectedTab] = useState(history.location.hash ? parseInt(history.location.hash.substr(3)) : 1);

  if(search && search.q && selectedTab != 1) {
      setSelectedTab(1);
  }

  const changeTab = (tab) => {
      setSelectedTab(tab);
      history.push("#tb"+tab)
  }

  const onFocus = (е) => {
      dispatch(getProject({"project":projectId}));
  }

  const topPartRef = useRef();

  useEffect(() => {
      dispatch(getProject({"project":projectId}));
      dispatch(getClient({"client":id}));
      dispatch(getAppsOptions());
     window.addEventListener("focus", onFocus());

      return () => {
        window.removeEventListener('focus', () => {});
      }
  }, []);

  let { heightTopBar, widthNoSidebar } = useDimension(topPartRef);


  let items = apps;

  if(search.q && apps.length > 0) {
      items = _filter(apps, search.q);
  }

  const diffDays = moment().endOf('month').diff(moment(), 'days');

  let colorPillWork = "bg-gray-50 text-gray-500";
  if(project && project.currentMonth && project.currentMonth.ending <= 0) {
      colorPillWork = "bg-green-100 text-green-600";
  }
  else if(diffDays < 7 && project && project.currentMonth && project.currentMonth.ending > 0) {
      colorPillWork = "bg-red-200 text-red-700";
  }
  else if(diffDays < 13 && project && project.currentMonth && project.currentMonth.ending > 0) {
      colorPillWork = "bg-red-50 text-red-500";
  }

  const goTo = (url) => {
      history.push(url);
  }

  /*
  <ActionBar
      crums={[{"title":"Клиенти","link":"/clients"},{"title":`${clientInfo.name}`,"link":`/clients/${clientInfo.id}`},{"title":`${project.title}`}]}
      title={project.title}
      searchIn={{"title":"На тази страница","id":`clients/${id}`}}
  />
  */

  if(!project || projectId != project._id) {
      return (
          <Dashboard layoutRender="TopSidebar" name="ClientsProjects">
              <ActionBar
                  title=<div className="px-10 py-3 bg-gray-100 text-base font-semibold flex flex-row items-center rounded shadow">
                        <SvgSpin classes="text-gray-700 w-6 h-6 mr-3" />
                        <span>Зареждам проекта</span>
                   </div>
               />
          </Dashboard>
      );
  }

  return (
      <Dashboard layoutRender="TopSidebar" hideTopBar={false} name="ClientsEditProject" background={clientInfo.background ? clientInfo.background  : "none"}>
          {openedModal && <ModalAppSettings />}
          {openedModalResearch && <ModalResearch />}
          {openedModalPr && <ModalPr />}

          <div className="flex-grow">

              <div ref={topPartRef} className="flex-grow">
                  <div className="flex flex-row items-center">
                      <div className="flex flex-row items-center">
                          <div className="flex bg-gray-400 rounded px-2 py-1 flex-row items-center mr-4 justify-center items-center">
                              <ChevronLeftIcon className="h-5 w-5 -ml-1 text-black" aria-hidden="true" />
                              <Link to={`/clients/${clientInfo.id}`} className="text-sm text-gray-700 uppercase font-medium tracking-wider">
                                  НАЗАД
                              </Link>
                          </div>
                          {project && project._id &&
                          <div className="flex flex-row bg-gray-900 text-gray-50 rounded px-2 py-1 items-center text-xl font-bold mr-3 justify-center">
                                {project.title}
                          </div>
                          }
                          <div className=" relative min-w-0 w-48 flex-1 px-4 text-sm font-medium text-center">
                              {project && project._id &&
                                  <ReactSelect
                                    className="w-full text-sm black-select rounded focus:outline-none"
                                    options={filtersOptions}
                                    isSearchable={false}
                                    onChange={handleKind}
                                    defaultValue={project.currentFilter}
                                  />
                              }
                          </div>
                      </div>
                      <div className="flex-1 ml-1">
                          <nav className="relative z-0 rounded-lg shadow flex divide-x divide-gray-700 ml-3" aria-label="Tabs">
                              <button className={"rounded-l group py-2 relative min-w-0 flex-1 overflow-hidden px-4 text-sm font-medium text-center hover:bg-gray-900 focus:z-10 " + (selectedTab == 1 ? 'text-gray-300  bg-gray-900' : 'text-gray-400 hover:text-gray-300 bg-gray-800')} onClick={() => changeTab(1)}>
                                  Задачи
                                   <span aria-hidden="true" className={classNames( selectedTab == 1 ? 'bg-red-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5' )} />
                              </button>
                              <button className={"group relative min-w-0 flex-1 overflow-hidden px-4 text-sm font-medium text-center hover:bg-gray-900 focus:z-10 " + (selectedTab == 3 ? 'text-gray-300  bg-gray-900' : 'text-gray-400 hover:text-gray-300 bg-gray-800')} onClick={() => changeTab(3)}>
                                  Отчет
                                   <span aria-hidden="true" className={classNames( selectedTab == 3 ? 'bg-red-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5' )} />
                              </button>
                              <button className={"group relative min-w-0 flex-1 overflow-hidden px-4 text-sm font-medium text-center hover:bg-gray-900 focus:z-10 " + (selectedTab == 2 ? 'text-gray-300  bg-gray-900' : 'text-gray-400 hover:text-gray-300 bg-gray-800')} onClick={() => changeTab(2)}>
                                  Приложения
                                   <span aria-hidden="true" className={classNames( selectedTab == 2 ? 'bg-red-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5' )} />
                              </button>
                              <button className={"group relative min-w-0 overflow-hidden px-4 text-sm font-medium text-center hover:bg-gray-900 focus:z-10 " + (selectedTab == 7 ? 'text-gray-300  bg-gray-900' : 'text-gray-400 hover:text-gray-300 bg-gray-800')} onClick={() => changeTab(7)}>
                                 &nbsp;&nbsp; Меню &nbsp;&nbsp;
                                  <span aria-hidden="true" className={classNames( selectedTab == 7 ? 'bg-red-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5' )} />
                              </button>
                              <button className={"group py-2 relative min-w-0 overflow-hidden px-4 text-sm font-medium text-center hover:bg-gray-900 focus:z-10 " + (selectedTab == 4 ? 'text-gray-300  bg-gray-900' : 'text-gray-400 hover:text-gray-300 bg-gray-800')} onClick={() => changeTab(4)}>
                                  &nbsp; Задания &nbsp;
                                   <span aria-hidden="true" className={classNames( selectedTab == 4 ? 'bg-red-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5' )} />
                              </button>
                              <button className={"group relative min-w-0 overflow-hidden px-4 text-sm font-medium text-center hover:bg-gray-900 focus:z-10 " + (selectedTab == 5 ? 'text-gray-300  bg-gray-900' : 'text-gray-400 hover:text-gray-300 bg-gray-800')} onClick={() => changeTab(5)}>
                                  &nbsp;&nbsp; PR &nbsp;&nbsp;
                                   <span aria-hidden="true" className={classNames( selectedTab == 5 ? 'bg-red-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5' )} />
                              </button>
                              <button className={"group relative min-w-0 flex-1 overflow-hidden px-4 text-sm font-medium text-center hover:bg-gray-900 focus:z-10 " + (selectedTab == 6 ? 'text-gray-300  bg-gray-900' : 'text-gray-400 hover:text-gray-300 bg-gray-800')} onClick={() => changeTab(6)}>
                                  За проекта
                                   <span aria-hidden="true" className={classNames( selectedTab == 6 ? 'bg-red-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5' )} />
                              </button>
                              {/*
                                  <Link to={`/clients/${clientInfo.id}`} className={"rounded-r group flex items-center justify-center relative min-w-0 flex-1 overflow-hidden px-4 text-sm font-medium text-center hover:bg-gray-900 focus:z-10 " + (selectedTab == 16 ? 'text-gray-300  bg-gray-900' : 'text-gray-400 hover:text-gray-300 bg-gray-800')}>
                                      За клиента
                                       <span aria-hidden="true" className={classNames( selectedTab == 7 ? 'bg-red-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5' )} />
                                  </Link>
                                  */}
                          </nav>
                      </div>

                  </div>


                  {!project && selectedTab === 2 && <div className="bg-white py-5 text-center w-full">Зареждам данните...</div>}
                  {(!items || items.length === 0) && selectedTab === 2 && <div className="bg-white mt-4 py-5 text-center w-full font-semibold"> Празен лист </div>}

                  {project && project._id && selectedTab === 2 &&
                      <>
                          <div className="flex mt-4 flex-inline items-center bg-gray-50 px-4 pъ-5 sm:p-6 sm:pb-4">
                              <div className="text-xl leading-6 font-medium text-gray-900">
                                  Приложения&nbsp;
                                  <small className="text-sm text-gray-600">
                                      (Име: {clientInfo.name})
                                  </small>
                              </div>
                              <div className="flex flex-grow flex-row-reverse">
                                  <button onClick={addRow} className="bg-blue-700 text-gray-200 order-last font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg focus:outline-none ml-1 focus:ring-gray-900 focus:ring-2 focus:ring-offset-2"
                                          type="button"
                                          >
                                          Добави приложение <SvgAdd classes="w-5 h-5 float-right ml-2" />
                                  </button>
                              </div>
                          </div>
                          <div className="mt-5">
                              <Table columns={columns} data={items} defaultOptions={defaultOptions} deleteAction={deleteApp} updateAction={updateAppsAction} openCloseModalAction={openCloseModalAction} reorderBlocks={reorderApps} />
                          </div>
                     </>
                  }

                  {project && project._id && selectedTab === 3 &&
                      <Accounted />
                  }

                  {project && project._id && selectedTab === 4 &&
                      <>
                          <div className="flex mt-4 flex-inline items-center bg-gray-50 px-4 pъ-5 sm:p-6 sm:pb-4">
                              <div className="text-xl leading-6 font-medium text-gray-900">
                                  Задания&nbsp;
                              </div>
                              <div className="flex flex-grow flex-row-reverse">
                                  <button onClick={addResearchRow} className="bg-blue-700 text-gray-200 order-last font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg focus:outline-none ml-1 focus:ring-gray-900 focus:ring-2 focus:ring-offset-2"
                                          type="button"
                                          >
                                          Ново задание <SvgAdd classes="w-5 h-5 float-right ml-2" />
                                  </button>
                              </div>
                          </div>
                          <div className="mt-5">
                              <Table columns={researchColumns} data={research} defaultOptions={defaultOptions} deleteAction={deleteResearch} updateAction={updateResearchAction} openCloseModalAction={openCloseModalResearchAction} reorderBlocks={reorderResearch} />
                          </div>
                      </>
                  }

                  {project && project._id && selectedTab === 5 &&
                      <>
                          <div className="flex mt-4 flex-inline items-center bg-gray-50 px-4 pъ-5 sm:p-6 sm:pb-4">
                              <div className="text-xl leading-6 font-medium text-gray-900">
                                  Популяризиане&nbsp;
                              </div>
                              <div className="flex flex-grow flex-row-reverse">
                                  <button onClick={addPrRow} className="bg-blue-700 text-gray-200 order-last font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg focus:outline-none ml-1 focus:ring-gray-900 focus:ring-2 focus:ring-offset-2"
                                          type="button"
                                          >
                                          Нов канал <SvgAdd classes="w-5 h-5 float-right ml-2" />
                                  </button>
                              </div>
                          </div>

                          <div className="mt-5">
                              <Table columns={prColumns} data={pr} defaultOptions={defaultOptions} deleteAction={deletePr} updateAction={updatePrAction} openCloseModalAction={openCloseModalPrAction} reorderBlocks={reorderPr} />
                          </div>
                      </>
                  }

                  {project && project._id && selectedTab === 7 &&
                      <>
                          <div className="flex mt-4 flex-inline items-center bg-gray-50 px-4 pъ-5 sm:p-6 sm:pb-4">
                              <div className="text-xl leading-6 font-medium text-gray-900">
                                  Меню&nbsp;
                              </div>
                              <div className="flex flex-grow flex-row-reverse">
                                  <a href="https://fontawesome.com/icons?d=gallery&p=2&m=free" className="btn flex justify-center bg-purple-300 text-purple-700 p-2 rounded text-sm" target="_blank">
                                      <SvgInbox />
                                      <div className="ml-1 mr-2">
                                          Иконки
                                      </div>
                                  </a>
                              </div>
                          </div>

                          <Menus />
                      </>
                  }

              </div>

              {project && project._id && selectedTab === 1 &&
                  <div className="w-full overflow-x-hidden">
                      <div style={{"height":heightTopBar}} className="overflow-auto task-board overflow-x-scroll flex-nowrap">
                          <TaskBoard />
                      </div>
                  </div>
              }

              {clientInfo && clientInfo.id && selectedTab === 6 &&
                  <MainInfo />
              }

          </div>
     </Dashboard>
  );
};

export default ClientsEditProject;
