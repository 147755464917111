import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import bg from 'date-fns/locale/bg';
import moment from 'moment';
import _max from 'lodash.max';
import _find from 'lodash.find';

//Actions
import { getProject } from '../../../../../../actions/projects/clientsProjectAction'
import { getTask,addAccount,editAccount,editAccounts,editTask,deleteAccount } from '../../../../../../actions/projects/clientsTasksAction'
import { useForm, useFieldArray, Controller } from "react-hook-form";

//Styles
import { SvgPlus,SvgSpin,SvgMinus,SvgImage,SvgClock } from '../../../../parts/blocks/Svg';

registerLocale('bg-bg', bg);


const ModalTaskDueDate = ( { resetMe } ) => {

    const dispatch = useDispatch();

    const { clientInfo } = useSelector(state => state.clients);
    const { project  } = useSelector(state => state.clientsProject);
    const { task } = useSelector(state => state.clientsTasks);

    if(task.due_date) {
        task.due_date = moment(task.due_date).toDate();
    }

    const { register, control, reset, getValues, handleSubmit } = useForm({
        defaultValues: task
    });

    const {
      fields : monthFields
    } = useFieldArray({ control, name: "accounted" });


    const [saveAnimationAdd, setSaveAnimationAdd] = useState(false);
    const [saveAnimationRemove, setSaveAnimationRemove] = useState(false);

    const onEnter = (e) => {
        if(e.key == "Enter") {
            e.currentTarget.blur();
        }
    }


    const saveData = () => {
        onSubmit(getValues());
    }

    const removeMonth = async (data) => {
        if(!saveAnimationRemove) {
            setSaveAnimationRemove(true);

            await dispatch(deleteAccount({
                "task" : task,
                "row" : data
            }));

            await dispatch(getTask({task}));
            await dispatch(getProject({"project":project._id}));
        }

        setSaveAnimationRemove(false);
    };

    const addMonth = async (data) => {
        if(!saveAnimationAdd) {
            setSaveAnimationAdd(true);


            let newMonth = moment().format("MM-YYYY");
            if(task.accounted && task.accounted.length > 0) {

                const dates = [];
                task.accounted.forEach((item, i) => {
                    dates.push(new Date(moment(item.date,"MM-YYYY").format()));
                });
                const maxMonth = _max(dates);

                if(maxMonth) {
                    newMonth = moment(maxMonth).add(1, 'month').format("MM-YYYY");
                }
            }

            await dispatch(addAccount({
                "clientInfo" : clientInfo,
                "project" : project,
                "task" : task,
                "row" : {
                    "date" : newMonth,
                    "exported" : 0,
                    "accounted" : 0,
                    "time"  : 0
                }
            }));

            await dispatch(getTask({task}));
            await dispatch(getProject({"project":project._id}));
        }

        setSaveAnimationAdd(false);
    };

    const onSubmit = async (data) => {
        if(data && data.accounted) {
            const accounted = data.accounted.map((item,index) => {
                if(item.date) {
                    return {...item,"date":moment(item.date,"MM-YYYY").format("MM-YYYY")}
                }
            });

            await dispatch(editAccounts({
                "project" : project,
                "update" : accounted
            }));

            await dispatch(editTask({
                "task" : task,
                "update" : {"deal":data.deal,"due_date":data.due_date}
            }));

            await dispatch(getTask({task}));
            await dispatch(getProject({"project":project._id}));
        }
    };

    useEffect(() => {
        if(task.accounted.length != monthFields.length) {
            reset(task);
        }
        else {
            task.accounted.map((item,index) => {
                if(!_find(monthFields,item)) {
                    reset(task);
                }
            });
        }
    });

    return (
        <>
            <div className="flex flex-col w-full">
                <div className="flex flex-row">
                    <div className="flex flex-col w-24">
                        <label className="flex font-medium text-sm">Крайна дата</label>
                        <Controller
                            name={`due_date`}
                            control={control}
                            render={({ field }) => (

                                <ReactDatePicker
                                    placeholderText="Дата"
                                    onChange={(e) => {field.onChange(e);saveData(e);}}
                                    selected={field.value ? new Date(moment(field.value,"MM-YYYY").format()) : new Date()}
                                    popperPlacement="top-start"
                                    dateFormat="dd/MM/yyyy"
                                    className="small-input w-full"
                                    locale="bg-bg"
                                    />


                            )} />
                    </div>
                    <div className="flex flex-1 flex-grow flex-col">
                        <label className="flex font-normal text-bold text-xs -mt-1 ml-3 text-gray-500">пример: 12+5+2</label>
                        <label className="framed small-framed flex-grow ml-2">
                            <input placeholder=" " {...register('deal')} name="deal" onBlur={(e) => saveData(e)} onKeyDown={(e) => onEnter(e)} />
                            <span>Договорени часове</span>
                        </label>
                    </div>
                </div>

                <div className="flex flex-grow flex-col mt-3">
                    <div className="flex items-center font-medium text-sm mb-1">Работа по месец <small className="text-xs ml-1 font-normal">(часове)</small></div>
                    {!monthFields &&
                            <div className="text-xs text-gray-400">
                                Нямате запеметена работа
                            </div>
                    }
                    {monthFields &&
                    <ul className="flex flex-col w-full">
                        {monthFields.map((item, index) => {
                            return (
                                <li key={item.id} className="w-full flex flex-row mb-5">

                                    <div className="flex text-xs text-center text-gray-500 font-medium mt-2.5">
                                        <Controller
                                            name={`accounted[${index}].date`}
                                            control={control}
                                            render={({ field }) => (
                                                <ReactDatePicker
                                                    placeholderText="Дата"
                                                    onChange={(e) => {field.onChange(e);saveData(e);}}
                                                    onBlur={(e) => saveData(e)}
                                                    selected={(field.value && field.value != 'Invalid date') ? new Date(moment(field.value,"MM-YYYY").format()) : new Date()}
                                                    popperPlacement="top-start"
                                                    dateFormat="MM/yyyy"
                                                    showMonthYearPicker
                                                    className="small-input w-full"
                                                    locale="bg-bg"
                                                    />
                                            )}
                                        />
                                    </div>

                                    <div className="flex text-xs text-center text-gray-500 font-medium ml-1">
                                        <label className="framed small-framed flex-grow">
                                            <input placeholder=" " disabled defaultValue={moment.utc(item.time*1000).format('HH:mm')} />
                                            <input type="hidden" {...register(`accounted[${index}].time`)}  name={`accounted[${index}].time`} defaultValue={item.time} />
                                            <input type="hidden" {...register(`accounted[${index}]._id`)}  name={`accounted[${index}]._id`} defaultValue={item._id} />
                                            <span>Работил</span>
                                        </label>
                                    </div>

                                    <div className="flex text-xs text-center text-gray-500 font-medium ml-1">
                                        <label className="framed small-framed flex-grow">
                                            <input placeholder=" " {...register(`accounted[${index}].accounted`)}  defaultValue={item.accounted} name={`accounted[${index}].accounted`} onBlur={(e) => saveData(e)} onKeyDown={(e) => onEnter(e)} />
                                            <span>Отчетен</span>
                                        </label>
                                    </div>

                                    <div className="flex flex-grow flex-row-reverse text-xs text-center text-gray-500 font-medium ml-1 mt-2">
                                        <button type="button" className="flex pt-1 pb-1 items-center justify-center h-5 w-5 rounded bg-gray-300 focus:outline-none"
                                            onClick={() => removeMonth(item)}
                                            >
                                            {!saveAnimationRemove && <SvgMinus classes="w-4 h-4 text-gray-600" />}
                                            {saveAnimationRemove && <SvgSpin classes="h-4 w-4 text-gray-600" />}
                                        </button>
                                    </div>

                                </li>
                            );
                        })}
                    </ul>
                    }

                    <div className="flex mt-2">
                        <button type="button" className="flex items-center text-gray-600 text-sm justify-center py-1.5 px-2.5  rounded bg-gray-200 focus:outline-none"
                            onClick={() => addMonth()}
                            >
                                Добави отчет
                                {!saveAnimationAdd && <SvgPlus classes="w-5 h-5" />}
                                {saveAnimationAdd && <SvgSpin classes="h-5 w-5 ml-2 text-gray-600" />}
                        </button>
                    </div>
                </div>

            </div>
        </>
    );
}

export default ModalTaskDueDate;
