import React, { useState,useEffect,useRef,useCallback } from "react";
import { Modal } from 'react-responsive-modal';
import Switch from "react-switch";
import ReactSelect from 'react-select'
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Editor } from '@tinymce/tinymce-react';
import TextareaAutosize from 'react-textarea-autosize';
import moment from 'moment';
import { Timer } from '../projects/Timer';
import ModalTaskTimers from './tasks/ModalTaskTimers';
import ModalTaskComments from './tasks/ModalTaskComments';
import ModalTaskSubTasks from './tasks/ModalTaskSubTasks';
import ModalTaskSettings from './tasks/ModalTaskSettings';
import {useDropzone} from 'react-dropzone'

//Actions
import { getProject,openCloseTasksModalAction } from '../../../../../actions/projects/clientsProjectAction'
import { getTask,editTask,getLatestTasks,taskAddFile,taskFile,deleteTask,addComment,deleteComment,editComment } from '../../../../../actions/projects/clientsTasksAction'
import { getClient } from '../../../../../actions/projects/clientsListAction'
import appsApi from '../../../../../env/apis/projects/appsApi';

//Styles
import { SvgSpin,SvgBounce,SvgImage,SvgHuman,SvgClose,SvgPlus,SvgAdd,SvgDelete } from '../../../parts/blocks/Svg';

export default () => {

    const dispatch = useDispatch();
    const { clientInfo } = useSelector(state => state.clients);
    const { openedModalTasks, project  } = useSelector(state => state.clientsProject);
    const { task } = useSelector(state => state.clientsTasks);

    const [showSaveLabel, setShowSaveLabel] = useState(false);
    const [whiteBox, setWhiteBox] = useState(0);
    const [editDescription, setEditDescription] = useState(task.description ? false : false);
    const [editTitle, setEditTitle] = useState((!task.title || task.added) ? true : false);
    // const [startEditComment, setStartEditComment] = useState("");
    // const [editingPostComment, setEditingPostComment] = useState(false);
    const [saveAnimation, setSaveAnimation] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [fromLatest, setFromLatest] = useState(task.fromLatest ? true : false);

    if(!task) {
        return false;
    }

    const editorRef = useRef();
    const titleRef = useRef(null);

    const handleEditorChange = (value) => {
    };

    const showEditor = (e) => {
        setEditDescription(true);
        setEditTitle(false);
        // setStartEditComment("");
        // setEditingPostComment(false);
    };

    const showTitleEditor = (e) => {
        setEditTitle(true);
    };


    const { register, control, handleSubmit,getValues } = useForm({
        defaultValues: task
    });

    const saveDataEditor = (e) => {
        onSubmit(getValues(),true);
    }

    const saveData = (e) => {
        let allowSubmit = e._reactName == "onKeyDown" ? false : true;
        if(e.key == "Enter") {
            //onSubmit(getValues(),true);
            e.target.blur();
        }
        else if(allowSubmit) {
            onSubmit(getValues(),true);
        }
    }

    const saveClick = () => {
        setSaveAnimation(true);
        onSubmit(getValues(),true);
    }

    const onSubmit = async (data,dontNotify) => {
        data.description = editorRef.current.currentContent;

        if(titleRef && titleRef.current) {
            data.title = titleRef.current.value;
        }

        setEditDescription(false);
        if(!data.title && editTitle) {
            setEditTitle(false);
        }
        setSaveAnimation(false);

        const newState = {...task,
            description : data.description,
            title : data.title,
        };

        await dispatch(editTask({
            "task" : newState,
            "update" : data
        }));


        if(clientInfo.id) {
            dispatch(getProject({"project":project._id}));
        }
        dispatch(getTask({task}));
        if(fromLatest) {
            dispatch(getLatestTasks({"client":clientInfo._id}));
        }
    };

    useEffect(() => {
        if(editorRef.current && editDescription) {
            editorRef.current.editor.focus();
            editorRef.current.editor.selection.select(editorRef.current.editor.getBody(), true);
            editorRef.current.editor.selection.collapse(false);
            // editorRef.current.editor.execCommand('mceInsertContent',false, " ");
        }
    });

    useEffect(() => {
        dispatch(getTask({task}));
    }, []);


    const closeModal = () => {
        dispatch(openCloseTasksModalAction({ "openClose":false }));
        if(clientInfo.id) {
            dispatch(getProject({"project":project._id}));
        }
    }

    const removeTask = async () => {
        await dispatch(deleteTask({task}));
        await dispatch(getProject({"project":project._id}));
        if(fromLatest) {
            dispatch(getLatestTasks({"client":clientInfo._id}));
        }
        closeModal();
    }

    const onUploadProgress = event => {
        const percentCompleted = Math.round((event.loaded * 100) / event.total);
        if(percentCompleted > 99.9) {
            setUploadProgress(0);
        }
        else {
            setUploadProgress(percentCompleted);
        }
    };

    const onDrop = useCallback(acceptedFiles => {
        uploadFiles(acceptedFiles);
    }, []);

    const startUploadFiles = () => {
        open();
    }

    const uploadFiles = async (files) => {
        const formData = new FormData();
        for(const [index, file] of files.entries()){
            formData.append("files", file);
        }
        await appsApi.post('/tasks/'+task._id+"/upload", formData, {
            onUploadProgress:onUploadProgress,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });

        dispatch(getTask({task}));
    }

    const removeImage = async (file) => {
        await appsApi.delete("/upload/"+file._id);
        dispatch(getTask({task}));
    }

    const {getRootProps, getInputProps, open, isDragActive} = useDropzone({
        onDrop,
        noClick: true,
        noKeyboard: true
    })

    return (
        <>
        <Modal
            open={openedModalTasks}
            onClose={() => closeModal()}
            center
            classNames={{
                modal: 'text-center inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl bg-gray-900 sm:w-10/12 xl:w-8/12 2xl:w-7/12',
            }}
            >

            <div {...getRootProps()} className="focus:outline-none">
                   <input {...getInputProps()} />

                   {isDragActive &&
                       <div className="flex flex-wrap content-center w-full h-full bg-white absolute z-50 opacity-80">
                           <div className="w-full text-center text-gray-900 font-medium text-3xl">Пусни файла за да го качиш</div>
                       </div>
                   }

                   {uploadProgress > 0 &&
                       <div className="flex flex-wrap content-center w-full h-full bg-white absolute z-50 opacity-80">
                           <div className="w-full text-gray-900 font-medium text-3xl items-center justify-center flex ">
                               Файла се качва ({uploadProgress}%...)
                               <span class="animate-ping -mt-4 w-2 h-2 rounded-full bg-blue-800 opacity-75"></span>
                           </div>
                       </div>
                   }

                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="bg-gray-100 pt-3 pb-2 w-full">
                                <div className="flex mr-5 relative">
                                    {!editTitle && !task.title &&
                                    <div className="mb-2 ml-5 mt-2">
                                        <button
                                            type="button"
                                            onClick={(е) => showTitleEditor(е)}
                                            className="text-base text-gray-600 font-normal focus:outline-none flex flex-inline">
                                            <SvgAdd classes="w-6 h-6 text-gray-600 mr-2" /> Добави заглавие
                                        </button>
                                    </div>
                                    }

                                    {!editTitle && task.title &&
                                        <div className="w-full text-xl pl-3 ml-3 py-2 bg-gray-100 mr-10 font-medium" onClick={(e) => showTitleEditor(e)} >
                                            {task.title}
                                        </div>
                                    }

                                    {editTitle &&
                                        <TextareaAutosize
                                            className="w-full text-xl pl-3 ml-3 py-2 bg-gray-100 mr-10 font-medium focus:bg-white focus:outline-none resize-none remove-border"
                                            ref={titleRef}
                                            name="title"
                                            defaultValue={task.title}
                                            onFocus={(e) => {e.target.selectionStart = e.target.value.length;e.target.selectionEnd = e.target.value.length;}}
                                            onBlur={(e) => saveData(e)}
                                            onKeyDown={(e) => saveData(e)}
                                            autoFocus
                                            />
                                    }


                                </div>
                            </div>




                            <div className="bg-gray-100 pt-1 px-4 sm:px-6 pb-3 pb-7">

                                <div className="w-full">
                                    <div className="w-full rounded ">


                                        {!editDescription && task.description &&
                                            <div className="html-display" onClick={(e) => showEditor(e)} dangerouslySetInnerHTML={{ __html: task.description }} />
                                        }

                                        {!editDescription && !task.description &&
                                            <div className="pb-2 html-display">
                                                <button
                                                    type="button"
                                                    onClick={(e) => showEditor(e)}
                                                    className="text-base text-gray-400 font-normal focus:outline-none flex flex-inline">
                                                    <SvgPlus classes="w-6 h-6 -ml-1 mr-0.5 text-gray-400" /> Добави описание
                                                </button>
                                            </div>
                                        }

                                        <div className={editDescription ? `flex flex-col` : `flex flex-col hidden`}>
                                            <Editor
                                              initialValue={task.description}
                                              ref={editorRef}
                                              init={{
                                                height: 100,
                                                menubar: false,
                                                toolbar: false,
                                                relative_urls : false,
                                                statusbar: false,
                                                paste_as_text: true,
                                                browser_spellcheck: true,
                                                contextmenu: false,
                                                object_resizing : true,
                                                valid_elements: "*[*]",
                                                fontsize_formats: "16px 18px 20px 22px 24px 30px 36px 48px",
                                                autoresize_bottom_margin: 20,
                                                plugins: [
                                                    'autolink lists link  anchor table paste autoresize media textcolor'
                                                ],
                                                toolbar:
                                                  'bold italic forecolor backcolor | \
                                                  alignleft aligncenter alignright alignjustify blockquote | \
                                                  bullist numlist removeformat outdent indent | link media table'
                                              }}
                                              onEditorChange={(value) => handleEditorChange(value)}
                                            />

                                            <div className="border-t flex pt-3">
                                                <button
                                                    type="button"
                                                    onClick={(e) => saveClick(e)}
                                                    className="inline-flex bg-blue-700 text-gray-200 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg focus:outline-none focus:ring-blue-700 focus:ring-1 focus:ring-offset-2">
                                                    {!saveAnimation && "Запази"}
                                                    {saveAnimation &&
                                                    <span className="inline-flex">
                                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"> <circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" stroke-width={4} /> <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" /> </svg>
                                                        <span>Запазвам</span>
                                                    </span>
                                                    }
                                                </button>

                                                <button type="button" className="ml-4" onClick={(e) => setEditDescription(false)}>
                                                    <SvgClose classes="w-7 h-7 text-gray-500" />
                                                </button>
                                            </div>

                                        </div>
                                    </div>

                                    {task && task.files &&
                                        <div className="flex flex-row flex-wrap w-full">
                                                {task.files.map((item, index) => {
                                                    if(!item.type || item.type == "task") {
                                                        return (
                                                            <div key={`file${index}`}  className="flex flex-row bg-gray-50 border border-gray-200 rounded p-2 mr-3 mt-5 ">
                                                                <div className=" flex w-28 overflow-hidden">
                                                                    <a href={item.url}   target="_blank" className="flex items-center">
                                                                        <img src={item.url} style={{}} alt=""/>
                                                                    </a>
                                                                </div>
                                                                <div className="flex flex-col flex-grow justify-start pl-3 pt-3 pr-5">
                                                                    <div className="text-gray-600 text-sm mb-2">
                                                                        {item.originalName}
                                                                    </div>
                                                                    <button type="button" onClick={() => removeImage(item)} className="flex flex-row text-xs text-gray-400 font-normal underline focus:outline-none">
                                                                        <SvgDelete classes="w-4 h-4" /> изтрий
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                })}
                                        </div>
                                    }

                                    {task && <ModalTaskSubTasks />}

                                    {task &&
                                        <ModalTaskSettings>
                                            <div className="pr-5 flex items-center bg-gray-50 border border-gray-200 mt-2.5 rounded h-10">
                                                <div className="mr-5">
                                                    <Timer task={task}  />
                                                </div>
                                                <button type="button" onClick={() => startUploadFiles()} className="text-xs mr-5 text-gray-400 hover:text-blue-500 font-normal underline focus:outline-none">
                                                    <SvgImage classes="w-6 h-6" />
                                                </button>
                                                <button type="button" onClick={() => removeTask()} className="text-xs text-gray-300 hover:text-red-500 font-normal underline focus:outline-none">
                                                    <SvgDelete />
                                                </button>
                                            </div>
                                        </ModalTaskSettings>
                                    }

                                    {task && <ModalTaskComments />}

                                    {task && task.timers && <ModalTaskTimers />}


                                </div>

                            </div>

                        </form>
                    </div>
            </div>
        </Modal>
        </>
);
};
