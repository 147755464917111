import React from "react";
import {
  HomeIcon
} from '@heroicons/react/outline'
import { Link } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/solid'

export default ({ back }) => {

    if(back && back.length > 1) {
        return (
            <div className="flex mr-10">
                <nav className="text-black font-bold" aria-label="Breadcrumb">
                    <ol className="list-none p-0 inline-flex">
                        <li className="flex flex-row items-center justify-center items-center">
                            <ChevronLeftIcon className="h-5 w-5 -ml-1 text-red-400" aria-hidden="true" />
                            <Link to={back} className="text-sm text-gray-700 uppercase font-medium tracking-wider">
                                НАЗАД
                            </Link>
                        </li>
                    </ol>
                </nav>
            </div>
        );
    }

    return (<></>);

}
