import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import _get from "query-string";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import _filter from "@mcabreradev/filter";
import history from '../../../history';

//Styles
import { SvgAdd } from '../parts/blocks/Svg';

//Custom hooks
import useDocumentTitle from './../../../hooks/useDocumentTitle'

//Components
import Dashboard from '../../board/Dashboard';
import Table from '../parts/table/Table'
import Modal from './parts/Modal'
import Query from './parts/Query'
import {TabLayout as Layout,reformTabGrid} from './parts/TabsLayout'
import ActionBar from '../../board/panels/content/ActionBar';

//Actions
import { addBlock, deleteBlock, getBlocks, updateBlocksFieldAction, openCloseModalAction, reorderBlocks } from '../../../actions/projects/appsSettingsAction'
import { updateBlocksFieldAction as updateBlocksFieldListAction } from '../../../actions/projects/appsListAction'

//Data
import columns from '../../../env/apis/projects/data/appsEditColumns';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const AppsSettings = (props) => {

  useDocumentTitle('👻');

  const id = props.match.params.id;

  const dispatch = useDispatch();
  const { rows, isLoaded, openedModal, appsInfo,updatedParent } = useSelector(state => state.appsSettings);

  const addRow = async () => {
      await dispatch(addBlock({"app":id}));
  }

  const search = _get.parse(window.location.search, {parseBooleans: true});
  const [selectedTab, setSelectedTab] = useState(history.location.hash ? parseInt(history.location.hash.substr(3)) : 1);

  if(search && search.q && selectedTab != 1) {
      setSelectedTab(1);
  }

  const changeTab = (tab) => {
      setSelectedTab(tab);
      history.push("#tb"+tab)
  }

  useEffect(async () => {
      dispatch(getBlocks({"app":id}));
  }, []);

  let items = rows;
  if(search.q && rows.length > 0) {
      items = _filter(rows, search.q);
  }

  if(isLoaded && updatedParent) {
      const gridFormat = reformTabGrid({appsInfo,data:items});
      const newGrid = {};
      for(var x in gridFormat.items) {
          if(gridFormat.items[x].length > 0) {
              newGrid[x] = {};
              for(var y in gridFormat.items[x]) {
                  newGrid[x][gridFormat.items[x][y].id] = 1;
              }
          }
      }

      dispatch(updateBlocksFieldListAction({
          "row":appsInfo,
          "column":"grid",
          "value":newGrid,
          "stopRedraw" : 1
      }));
  }

  return (
      <Dashboard layoutRender="TopSidebar"  name="AppsSettings">
          {openedModal && <Modal />}

          <ActionBar
              crums={[{"title":"Приложения","link":"/apps"},{"title":"Редакция"}]}
              back={`/apps`}
              title={<h2 className="text-xl font-semibold leading-10">
                  Редакция на приложение:
                  <span className="text-purple-800 text-xl ml-2">
                      {appsInfo.app_name}
                  </span>
                  <small className="text-sm text-gray-600 ml-2">
                      (ID: {appsInfo.id})
                  </small>
              </h2>}
              buttons={selectedTab === 1 &&
                <button onClick={addRow} className="bg-blue-700 text-gray-200 order-last mt-1 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg focus:outline-none ml-1 mb-1 focus:ring-gray-900 focus:ring-2 focus:ring-offset-2"
                    type="button"
                    >
                    Добави поле <SvgAdd classes="w-5 h-5 float-right ml-2" />
                </button>
              }
              searchIn={{"title":"На тази страница","id":`apps/${id}`}}
          />

          <div className="flex-grow mb-2">

              <nav className="relative z-0 rounded-lg shadow flex divide-x divide-gray-900" aria-label="Tabs">
                  <button className={"rounded-l-lg group relative min-w-0 flex-1 overflow-hidden bg-gray-700 py-4 px-4 text-sm font-medium text-center hover:bg-gray-900 focus:z-10 " + (selectedTab == 1 ? 'text-gray-200' : 'text-gray-300 hover:text-gray-200')} onClick={() => changeTab(1)}>
                      Полета
                      <span aria-hidden="true" className={classNames( selectedTab == 1 ? 'bg-red-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5' )} />
                  </button>
                  <button className={"group relative min-w-0 flex-1 overflow-hidden bg-gray-700 py-4 px-4 text-sm font-medium text-center hover:bg-gray-900 focus:z-10 " + (selectedTab == 2 ? 'text-gray-200' : 'text-gray-300 hover:text-gray-200')} onClick={() => changeTab(2)}>
                      Настройки
                      <span aria-hidden="true" className={classNames( selectedTab == 2 ? 'bg-red-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5' )} />
                  </button>
                  <button className={"rounded-r-lg group relative min-w-0 flex-1 overflow-hidden bg-gray-700 py-4 px-4 text-sm font-medium text-center hover:bg-gray-900 focus:z-10 " + (selectedTab == 3 ? 'text-gray-200' : 'text-gray-300 hover:text-gray-200')} onClick={() => changeTab(3)}>
                      Разположение
                      <span aria-hidden="true" className={classNames( selectedTab == 3 ? 'bg-red-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5' )} />
                  </button>
              </nav>



              {!isLoaded && selectedTab === 1 && <div className="bg-white py-5 text-center w-full">Зареждам данните...</div>}
              {(!items || items.length === 0) && selectedTab === 1 && <div className="bg-white py-5 text-center w-full font-semibold"> Празен лист </div>}

              {isLoaded && selectedTab === 1 &&
                  <div className="mt-5"><Table columns={columns} data={items} deleteAction={deleteBlock} updateAction={updateBlocksFieldAction} openCloseModalAction={openCloseModalAction} reorderBlocks={reorderBlocks} /></div>
              }

              {isLoaded && selectedTab === 2 &&
                  <Query />
              }

              {isLoaded && selectedTab === 3 &&
                  <Layout data={items} />
              }

          </div>
      </Dashboard>
  );
};

export default AppsSettings;
