import React from 'react';
import { useDispatch, useSelector } from "react-redux";

export default ({ column,row,updateBlocksFieldAction }) => {

    const dispatch = useDispatch();
    const checked = row[column];
    const handleChange = (column,row,updateBlocksFieldAction,e) => {
        const value = e.target.checked ? 1 : 0;
        dispatch(updateBlocksFieldAction({ column, row, value }));
    }

    return (
        <>
            <input key={row.id+column} type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" defaultChecked={checked} onChange={(e) => handleChange(column,row,updateBlocksFieldAction,e)} />
        </>
    )
}
