const columns = [
  {
    "key": "sort",
    "title": "",
    "dataKey": "sort",
    "align": "center",
    "width": 50,
  },
  // {
  //   "key": "id",
  //   "title": "ID",
  //   "dataKey": "id",
  //   "sortable": false,
  //   "width": 70,
  // },
  {
    "key": "title_in_db_input",
    "title": "Заглавие в базата",
    "dataKey": "title_in_db",
    "sortable": false,
    "width": 250,
  },
  {
    "key": "type_select",
    "title": "Тип",
    "dataKey": "type",
    "sortable": false,
    "width": 300,
  },
  {
    "key": "label_input",
    "title": "Етикет",
    "dataKey": "label",
    "sortable": false,
    "width": 250,
  },
  {
    "key": "show_in_lsting_check",
    "title": "На листинга",
    "dataKey": "show_in_lsting",
    "align": "center",
    "sortable": false,
    "width": 110,
  },
  {
    "key": "can_edit_listing_check",
    "title": "Редакция в листинга",
    "dataKey": "can_edit_listing",
    "sortable": false,
    "align": "center",
    "width": 110,
  },
  {
    "key": "in_search_check",
    "title": "В търсачка",
    "dataKey": "in_search",
    "sortable": false,
    "align": "center",
    "width": 110,
  },
  {
    "key": "filter_check",
    "title": "Филтър",
    "dataKey": "filter",
    "sortable": false,
    "align": "center",
    "width": 100,
  },
  {
    "key": "is_active_check",
    "title": "Видимо в редакция",
    "dataKey": "is_active",
    "sortable": false,
    "align": "center",
    "width": 100,
  },
  {
    "key": "can_edit_check",
    "title": "Позволи редакция",
    "dataKey": "can_edit",
    "sortable": false,
    "align": "center",
    "width": 100,
  },
  // {
  //   "key": "show_label_check",
  //   "title": "Покажи етикет",
  //   "dataKey": "show_label",
  //   "align": "center",
  //   "sortable": false,
  //   "width": 110,
  // },
  // {
  //   "key": "store_in_db_check",
  //   "title": "Пази данните",
  //   "dataKey": "store_in_db",
  //   "sortable": false,
  //   "align": "center",
  //   "width": 100,
  // },
  {
    "key": "settings",
    "title": "",
    "dataKey": "settings",
    "sortable": false,
    "align": "center",
    "width": 100,
  },
  {
    "key": "action",
    "title": "",
    "align": "center",
    "width": 50
  }
];

export default columns;
