import React from 'react';
import {
  SearchIcon
} from '@heroicons/react/outline'

export default function Search() {
    return (
        <>
            {/* Sidebar Search */}
             <div className="hidden">
               <label htmlFor="search" className="sr-only">
                 Search
               </label>
               <div className="mt-1 mb-2 relative rounded-md shadow-sm">
                 <div
                   className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                   aria-hidden="true"
                 >
                   <SearchIcon className="mr-3 h-4 w-4 text-gray-400" aria-hidden="true" />
                 </div>
                 <input
                   type="text"
                   name="search"
                   id="search"
                   className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-9 sm:text-sm border-gray-700 bg-gray-600 text-gray-100 rounded-md"
                   placeholder="Search"
                 />
               </div>
             </div>
        </>
    );
}
