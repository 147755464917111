import React from 'react';

import ActionBar from './ActionBar';
import Dashboard from '../../../board/Dashboard';
import {
  BellIcon,
  PencilIcon,
} from '@heroicons/react/solid'

export default function Path() {
    return (
        <Dashboard name="DemoContent">

            <ActionBar
                crums={[{title:"Демо",link:"/apps"},{title:"Демо"}]}
                title="Демо страница"
                buttons={<div>
                    <button className="bg-blue-700 text-gray-200 order-last mt-1 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg focus:outline-none ml-1 mb-1 focus:ring-gray-900 focus:ring-2 focus:ring-offset-2"
                        type="button"
                        >

                        Бутон
                        <PencilIcon className="w-5 h-5 float-right ml-2" aria-hidden="true" />
                    </button>
                </div>}
                />

            <div className="py-3 xl:pt-6 xl:pb-0">
              <div className="prose max-w-none">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita, hic? Commodi cumque
                  similique id tempora molestiae deserunt at suscipit, dolor voluptatem, numquam, harum
                  consequatur laboriosam voluptas tempore aut voluptatum alias?
                </p>
                <ul role="list">
                  <li>
                    Tempor ultrices proin nunc fames nunc ut auctor vitae sed. Eget massa parturient vulputate
                    fermentum id facilisis nam pharetra. Aliquet leo tellus.
                  </li>
                  <li>Turpis ac nunc adipiscing adipiscing metus tincidunt senectus tellus.</li>
                  <li>
                    Semper interdum porta sit tincidunt. Dui suspendisse scelerisque amet metus eget sed. Ut
                    tellus in sed dignissim.
                  </li>
                </ul>
              </div>
            </div>
        </Dashboard>
    );
}
