import React, { useState,useEffect,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'react-sortable-tree/style.css';
import SortableTree, { addNodeUnderParent, removeNodeAtPath, changeNodeAtPath } from 'react-sortable-tree';
import { SvgAdd,SvgDelete } from '../../../parts/blocks/Svg';
import AsyncSelect, { makeAsyncSelect } from 'react-select/async';
import ReactSelect from 'react-select'
import appsApi from '../../../../../env/apis/projects/appsApi';

//Actions
import { updateProject } from '../../../../../actions/projects/clientsProjectAction'

const active = [
  { value: '1', label: 'Да'},
  { value: '0', label: 'Не'},
];

const access = [
  { value: '1', label: 'Всички'},
  { value: '2', label: 'ДЕВ'},
  { value: '3', label: 'Определен'},
];

const position = [
  { value: '1', label: 'Странично'},
  { value: '2', label: 'Горно'}
];

const Menus = () => {

    const dispatch = useDispatch();
    const { clientInfo } = useSelector(state => state.clients);
    const { project, options } = useSelector(state => state.clientsProject);

    const [treeData, setTreeData] = useState(project.menu && Array.isArray(project.menu) ? project.menu : [{
        title: 'Табло',
        isActive: { value: '1', label: 'Да'},
        inSearch: { value: '1', label: 'Да'},
        access: { value: '1', label: 'Всички' },
        position: { value: '1', label: 'Странично меню'}
    }]);

    const loadOptions = async (inputValue) => {
        const data = [];
        const response = await appsApi.get('/blocks/search?q=' + inputValue);
        response.data.data.forEach((v) => {
            data.push({...v,"label":v.app_name,"value":v.id});
        })
        return data;
    }

    const saveTree = async (data) => {
        await setTreeData(data);
        const newState = {...project,"menu":data};
        dispatch(updateProject({newState:newState,update:{"menu":data},project,clientInfo}));
    }

    const addAsFirstChild = false;
    const getNodeKey = ({ treeIndex }) => treeIndex;

    const options2 = [];
    if(project.apps) {
        project.apps.forEach((v) => {
            if(v.app_id && v.app_id.app_name) {
                options2.push({"label":v.app_id.app_name,"value":v.app_id.id,"filename":v.app_id.app_filename});
            }
        })
    }

    //<AsyncSelect defaultOptions={options2} defaultValue={node.app} loadOptions={loadOptions} cacheOptions className="w-full text-left text-sm" />

    return (
        <>
        <div>
           <div className="mt-5">
             <SortableTree
               treeData={treeData}
               isVirtualized={false}
               onChange={theData => saveTree(theData)}
               generateNodeProps={({ node, path }) => ({
                   title: (
                       <div className="flex">
                           <label className="framed small-framed -mt-1">
                               <input
                                 value={node.title}
                                 onChange={event => {
                                   const title = event.target.value;

                                   saveTree(changeNodeAtPath({
                                     treeData: treeData,
                                     path,
                                     getNodeKey,
                                     newNode: { ...node, title },
                                   }));
                                 }}
                               />
                              <span>Име</span>
                           </label>

                           <div className="text-sm w-44 ml-5">
                               <ReactSelect
                                   options={options2}
                                   className="w-full text-sm select-small"
                                   placeholder="Приложение..."
                                   isClearable
                                   onChange={value => {
                                     saveTree(changeNodeAtPath({
                                       treeData: treeData,
                                       path,
                                       getNodeKey,
                                       newNode: { ...node, app:value },
                                     }));
                                   }}

                                   defaultValue={node.app} />
                           </div>

                           <label className="framed small-framed w-32 -mt-1 ml-5">
                               <input
                                 value={node.url}
                                 onChange={event => {
                                   const url = event.target.value;
                                   saveTree(changeNodeAtPath({
                                     treeData: treeData,
                                     path,
                                     getNodeKey,
                                     newNode: { ...node, url },
                                   }));
                                 }}
                               />
                               <span>URL</span>
                           </label>



                           <label className="framed small-framed w-32 -mt-1 ml-5">
                               <input
                                 value={node.icon}
                                 onChange={event => {
                                   const icon = event.target.value;

                                   saveTree(changeNodeAtPath({
                                     treeData: treeData,
                                     path,
                                     getNodeKey,
                                     newNode: { ...node, icon },
                                   }));
                                 }}
                               />
                               <span>Клас на иконката</span>
                           </label>

                           <label className="framed small-framed w-20 -mt-1 ml-5">
                               <input
                                 value={node.badge}
                                 onChange={event => {
                                   const badge = event.target.value;

                                   saveTree(changeNodeAtPath({
                                     treeData: treeData,
                                     path,
                                     getNodeKey,
                                     newNode: { ...node, badge },
                                   }));
                                 }}
                               />
                           <span>Бадж</span>
                           </label>

                           <div className="ml-5 w-24 text-sm flex flex-col simple-select-menu">
                               <label className="text-xs font-bold">Позиция:</label>
                               <select
                                   value={node.position}
                                   onChange={event => {
                                     const position = event.target.value;

                                     saveTree(changeNodeAtPath({
                                       treeData: treeData,
                                       path,
                                       getNodeKey,
                                       newNode: { ...node, position },
                                     }));
                                   }}
                                   className="text-xs focus:outline-none -ml-1">
                                   {position.map((pos,ind)=> {
                                       return (
                                           <option key={`pos${ind}`} value={pos.value}>{pos.label}</option>
                                       )
                                   })}
                               </select>
                           </div>
                           <div className="ml-5 w-24 text-sm flex flex-col simple-select-menu">
                               <label className="text-xs font-bold">Достъп до:</label>
                               <select
                                   value={node.access}
                                   className="text-xs focus:outline-none -ml-1"
                                   onChange={event => {
                                     const access = event.target.value;

                                     saveTree(changeNodeAtPath({
                                       treeData: treeData,
                                       path,
                                       getNodeKey,
                                       newNode: { ...node, access },
                                     }));
                                   }}
                                   >
                                   {access.map((acc,ind)=> {
                                       return (
                                           <option key={`acc${ind}`} value={acc.value}>{acc.label}</option>
                                       )
                                   })}
                               </select>
                           </div>
                           <div className="ml-5 w-16 mr-2 text-sm flex flex-col simple-select-menu">
                               <label className="text-xs font-bold">Активен:</label>
                                   <select
                                       value={node.isActive}
                                       className="text-xs focus:outline-none -ml-1"
                                       onChange={event => {
                                         const isActive = event.target.value;

                                         saveTree(changeNodeAtPath({
                                           treeData: treeData,
                                           path,
                                           getNodeKey,
                                           newNode: { ...node, isActive },
                                         }));
                                       }}
                                       >
                                   {active.map((opt,ind)=> {
                                       return (
                                           <option key={`act${ind}`} value={opt.value}>{opt.label}</option>
                                       )
                                   })}
                               </select>
                           </div>
                           <div className="ml-5 w-16 mr-2 text-sm flex flex-col simple-select-menu">
                               <label className="text-xs font-bold">В търсачката:</label>
                                   <select
                                       value={node.inSearch}
                                       className="text-xs focus:outline-none -ml-1"
                                       onChange={event => {
                                         const inSearch = event.target.value;

                                         saveTree(changeNodeAtPath({
                                           treeData: treeData,
                                           path,
                                           getNodeKey,
                                           newNode: { ...node, inSearch },
                                         }));
                                       }}
                                       >
                                   {active.map((opt,ind)=> {
                                       return (
                                           <option key={`act${ind}`} value={opt.value}>{opt.label}</option>
                                       )
                                   })}
                               </select>
                           </div>
                       </div>
                   ),
                 buttons: [
                   <button
                     className="inline-flex justify-center text-xs mr-1 rounded-md px-4 py-1 bg-blue-200 text-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                     onClick={() => {
                         saveTree(addNodeUnderParent({
                             treeData: treeData,
                             parentKey: path[path.length - 1],
                             expandParent: true,
                             getNodeKey,
                             newNode: {
                               title: `-`,
                             },
                             addAsFirstChild: addAsFirstChild,
                           }).treeData,
                          );
                     }}
                   >
                     <SvgAdd classes="w-4 h-4" />
                   </button>,
                   <button
                     className="inline-flex justify-center text-xs ml-3 mr-1 mt-1 text-red-300 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                     onClick={() => {
                       saveTree(removeNodeAtPath({
                         treeData: treeData,
                         path,
                         getNodeKey
                       }));
                     }}
                   >
                     <SvgDelete classes="w-4 h-4" />
                   </button>,
                 ],
               })}
             />
           </div>


           <div className="ml-8">
               <button
                 className="w-full mb-10 mt-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-200 text-green-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                 onClick={() =>
                   saveTree(
                     treeData.concat({
                       title: `-`,
                     })
                   )
                 }
               >
                 Добави главно меню
               </button>
           </div>


         </div>
        </>
    );
};

export default Menus;
