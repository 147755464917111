import {
    CLIENTS_SETTINGS_ADD_ROW,
    CLIENTS_SETTINGS_UPDATE_ROW,
    CLIENTS_SETTINGS_UPDATE_FIELD,
    CLIENTS_SETTINGS_DELETE_ROW,
    CLIENTS_SETTINGS_MODAL,
    CLIENTS_APPS_LIST,
    CLIENTS_SETTINGS_TASKS_MODAL,
    CLIENTS_SETTINGS_GET_ROWS,
    CLIENTS_SETTINGS_FILTERS,
    CLIENTS_PROJECTINFO,

    CLIENTS_SETTINGS_ADD_RESEARCH,
    CLIENTS_SETTINGS_RESEARCH_DELETE,
    CLIENTS_SETTINGS_MODAL_RESEARCH,
    CLIENTS_SETTINGS_GET_RESEARCH,
    CLIENTS_SETTINGS_UPDATE_RESEARCH,

    CLIENTS_SETTINGS_ADD_PR,
    CLIENTS_SETTINGS_PR_DELETE,
    CLIENTS_SETTINGS_MODAL_PR,
    CLIENTS_SETTINGS_GET_PR,
    CLIENTS_SETTINGS_UPDATE_PR
} from '../types';

const INTIAL_STATE = {
  "openedModal": false,
  "openedModalTasks": false,
  "openedModalResearch": false,
  "openedModalPr": false,
  "apps": [],
  "research": [],
  "pr": [],
  "filters": [],
  "options": [],
  "project": [],
  "row": [],
  "rowResearch": [],
  "rowPr": []
};

const clientsProjectReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case CLIENTS_PROJECTINFO:
            return { ...state, project: action.payload, apps: action.payload.apps, research: action.payload.research, pr: action.payload.pr};
        //Apps
        case CLIENTS_SETTINGS_FILTERS:
            return { ...state, filters: action.payload.filters};
        case CLIENTS_SETTINGS_GET_ROWS: //md
            return { ...state, apps: action.payload.fields};
        case CLIENTS_SETTINGS_ADD_ROW: //md
            return { ...state, apps: [action.payload,...state.apps]};
        case CLIENTS_SETTINGS_UPDATE_ROW:
            return {...state,apps: state.apps.map(row => row.id === action.payload.id ? action.payload : row)};
        case CLIENTS_SETTINGS_UPDATE_FIELD:
            action.payload.row[action.payload.column] = action.payload.value;
            return { ...state, apps: state.apps.map(row => row.id === action.id ? action.payload.row : row)};
        case CLIENTS_SETTINGS_DELETE_ROW: //md
            return { ...state, apps: state.apps.filter((item) => item.id !== action.payload)};
        case CLIENTS_SETTINGS_MODAL:
            return { ...state, openedModal: action.payload.openClose, row: action.payload.row };
        case CLIENTS_SETTINGS_ADD_RESEARCH:
            return { ...state, research: [action.payload,...state.research]};
        case CLIENTS_SETTINGS_GET_RESEARCH:
            return { ...state, research: action.payload.fields};
        case CLIENTS_SETTINGS_RESEARCH_DELETE:
            return { ...state, research: state.research.filter((item) => item.id !== action.payload)};
        case CLIENTS_SETTINGS_MODAL_RESEARCH:
            return { ...state, openedModalResearch: action.payload.openClose, rowResearch: action.payload.row };
        case CLIENTS_SETTINGS_UPDATE_RESEARCH:
            action.payload.row[action.payload.column] = action.payload.value;
            return { ...state, research: state.research.map(row => row.id === action.id ? action.payload.row : row)};
        case CLIENTS_SETTINGS_TASKS_MODAL:
            return { ...state, openedModalTasks: action.payload.openClose, row: action.payload.row };
        case CLIENTS_APPS_LIST:
            return { ...state, options: action.payload};
        /* PR */
        case CLIENTS_SETTINGS_ADD_PR:
            return { ...state, pr: [action.payload,...state.pr]};
        case CLIENTS_SETTINGS_GET_PR:
            return { ...state, pr: action.payload.fields};
        case CLIENTS_SETTINGS_PR_DELETE:
            return { ...state, pr: state.pr.filter((item) => item.id !== action.payload)};
        case CLIENTS_SETTINGS_MODAL_PR:
            return { ...state, openedModalPr: action.payload.openClose, rowPr: action.payload.row };
        case CLIENTS_SETTINGS_UPDATE_PR:
            action.payload.row[action.payload.column] = action.payload.value;
            return { ...state, pr: state.pr.map(row => row.id === action.id ? action.payload.row : row)};
        /* END PR */
        default:
            return state;
    }
}

/*
UPDATE SINGLE COLUMNG

*/

export default clientsProjectReducer;
