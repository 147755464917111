import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import _get from "query-string";
import history from '../../../history';
import {
  ViewGridIcon,
  ViewListIcon
} from '@heroicons/react/outline'

//Styles
import { SvgAdd } from '../parts/blocks/Svg';

//Custom hooks
import useDocumentTitle from './../../../hooks/useDocumentTitle'

//Components
import Table from '../parts/table/Table'
import Dashboard from '../../board/Dashboard';
import ActionBar from '../../board/panels/content/ActionBar';

//Actions
import { addBlock, deleteBlock, getBlocks, updateBlocksFieldAction, reorderBlocks } from '../../../actions/projects/clientsListAction'

//Data
import columns from '../../../env/apis/projects/data/clientsListColumns';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ListRedux = () => {

  useDocumentTitle('👻');

  const dispatch = useDispatch();
  const { rows, isLoaded } = useSelector(state => state.clients);

  const addRow = async () => {
      await dispatch(addBlock());
      setSelectedTab(2);
  }

  const search = _get.parse(window.location.search, {parseBooleans: true});
  const [selectedTab, setSelectedTab] = useState(1);
  if(search && search.q && selectedTab != 1) {
      setSelectedTab(1);
  }

  const goTo = (url) => {
      history.push(url);
  }

  useEffect(async () => {
       dispatch(getBlocks(search));
  }, []);

  return (
      <Dashboard layoutRender="TopSidebar" name="ClientsList">

          <ActionBar
              crums={[{"title":"Клиенти"}]}
              title="Клиенти"
              buttons={<div className="flex items-center justify-center">
                  <button onClick={addRow} className="bg-blue-700 text-gray-200 order-last mt-1 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg focus:outline-none ml-5 focus:ring-gray-900 focus:ring-2 focus:ring-offset-2"
                      type="button"
                      >
                      Създай  <SvgAdd classes="w-5 h-5 float-right ml-2" />
                  </button>

                  <button onClick={() => {setSelectedTab(2);}} className="bg-gray-200 order-last mt-1 font-bold uppercase px-2 py-2 focus:outline-none ml-5"
                      type="button"
                      >
                      <ViewListIcon className="h-6 w-6 -ml-1 text-gray-700" aria-hidden="true" />
                  </button>

                  <button onClick={() => {setSelectedTab(1);}} className="bg-gray-200 order-last mt-1 font-bold uppercase px-2 py-2 focus:outline-none ml-5"
                      type="button"
                      >
                      <ViewGridIcon className="h-6 w-6 -ml-1 text-gray-700" aria-hidden="true" />
                  </button>
              </div>}
               searchIn={{"title":"На тази страница","id":`clients`}}
               clearSearchAction={() => { dispatch(getBlocks()) }}
               searchAction={() => { dispatch(getBlocks(search)) }}
              />


          <div className="flex-grow mb-2">
              {!isLoaded && <div className="bg-white py-5 text-center w-full">Зареждам данните...</div>}
              {(!rows || rows.length == 0) && <div className="bg-white py-5 text-center w-full font-semibold"> Празен лист </div>}
              {isLoaded && selectedTab == 1 &&
                  <div>
                      <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2">

                          {rows.map(card => {
                              let clientLink = "/clients/"+card.id;
                              if(card.lastProject && card.lastProject.length == 1) {
                                  clientLink = "/clients/"+card.id+"/projects/"+card.lastProject[0]._id;
                              }
                              if(card.isImportant) {
                                  return (
                                      <>
                                          <li key={card._id} onClick={() => goTo(clientLink)} style={{background: (card.background && card.background.regular) ? `url(${card.background.regular})  center center` : '#374151'}} className={classNames( card.isImportant ? 'bg-red-800 hover:bg-red-900' : 'bg-gray-700 hover:bg-gray-900', 'col-span-1  h-40 rounded-lg shadow cursor-pointer' )}>
                                              <div className="w-full divide-y divide-gray-500">
                                                   <div className="w-full flex items-center justify-between p-6 space-x-6">
                                                       <div className="flex-1 truncate">
                                                          <div className="flex items-center space-x-3">
                                                            <h3 className={classNames((card.background && card.background.regular) ? 'black-shadow' : '', 'text-gray-50 text-sm font-bold truncate text-3xl  ' )}>
                                                                <span className="relative z-10">{card.name}</span>
                                                            </h3>
                                                          </div>
                                                          {(card.email || card.phone) &&
                                                              <div className="flex items-center justify-start text-gray-500 mt-1 divide-x divide-gray-200 py-3">
                                                                  {card.email &&
                                                                  <p className={classNames((card.background && card.background.regular) ? 'white-shadow-small text-gray-800' : 'text-gray-400', 'mt-1 text-sm truncate ' )}>
                                                                      <span className="relative z-10">{card.email}</span>
                                                                  </p>
                                                                  }
                                                                  {card.phone &&
                                                                      <p className={classNames((card.background && card.background.regular) ? 'white-shadow-small ml-1 text-gray-800' : 'text-gray-400', 'mt-1 text-sm truncate ' )}>
                                                                          <span className="relative z-10">{card.phone}</span>
                                                                      </p>
                                                                  }
                                                              </div>
                                                          }
                                                        </div>
                                                        {/*
                                                        <img className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0" src="/img/logo-rose.svg" alt="" />
                                                        */}
                                                   </div>


                                              </div>
                                          </li>
                                      </>
                                  );
                              }
                          })}
                          <li className="bg-gray-200 h-5 col-span-2  h-40 rounded-lg  cursor-pointer"></li>
                          {rows.map(card => {
                              var clientLink = "/clients/"+card.id;
                              if(card.lastProject && card.lastProject._id) {
                                  clientLink = "/clients/"+card.id+"/projects/"+card.lastProject._id;
                              }
                              if(!card.isImportant) {
                                  return (
                                      <li key={card._id} onClick={() => goTo(clientLink)} style={{background: (card.background && card.background.regular) ? `url(${card.background.regular})  center center` : '#374151'}} className={classNames( card.isImportant ? 'bg-gray-800' : 'bg-gray-700', 'col-span-1  hover:bg-gray-900 h-40  rounded-lg shadow cursor-pointer' )}>
                                          <div className="w-full divide-y divide-gray-500">
                                               <div className="w-full flex items-center justify-between p-6 space-x-6">
                                                   <div className="flex-1 truncate">
                                                      <div className="flex items-center space-x-3">
                                                          <h3 className={classNames((card.background && card.background.regular) ? 'black-shadow' : '', 'text-gray-50 text-sm font-bold truncate text-2xl  ' )}>
                                                              <span className="relative z-10">{card.name}</span>
                                                          </h3>
                                                      </div>
                                                      {card.email &&
                                                          <p className={classNames((card.background && card.background.regular) ? 'white-shadow-small text-gray-800' : 'text-gray-400', 'mt-1 text-sm truncate ' )}>
                                                              <span className="relative z-10">{card.email}</span>
                                                          </p>
                                                      }

                                                    </div>
                                                    {/*
                                                    <img className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0" src="/img/logo-rose.svg" alt="" />
                                                    */}
                                               </div>
                                               {card.phone &&
                                               <div className="flex items-center justify-center text-gray-500 mt-1 divide-x divide-gray-200 py-3">
                                                   {card.phone &&
                                                       <p className={classNames((card.background && card.background.regular) ? 'white-shadow-small text-gray-800' : 'text-gray-400', 'mt-1 text-sm truncate ' )}>
                                                           <span className="relative z-10">{card.email}</span>
                                                       </p>
                                                   }
                                               </div>
                                                }

                                          </div>
                                      </li>
                                  );
                              }
                          })}
                      </ul>

                  </div>
              }
              {isLoaded && selectedTab == 2 &&
                  <Table columns={columns} data={rows} deleteAction={deleteBlock} reorderBlocks={reorderBlocks} updateAction={updateBlocksFieldAction} actionUrl="/clients/" />
              }
          </div>
      </Dashboard>
  );
};

export default ListRedux;
